import { TypeOf } from 'io-ts';
import { createParseFunction } from './helpers';
import { MetadataType, MetricMetadataType, MetricGroupingType, GeoLevelType } from './Metadata';
import { GeojsonFeatureType, GeojsonFeatureCollectionType } from './Geojson';
import {
    MetricByFipsDataType,
    MetricByFipsDataArrayType,
    MetricByDateDataType,
    MetricByDateDataArrayType,
    AllMetricsDataType,
    AllMetricsDataArrayType,
    GroupedMetricDataType,
    GroupedMetricDataArrayType
} from './MetricData';
import { GeoLevelMetaType, GeoLevelMetaArrayType } from './GeoLevelMetadata';
import { TestLocationType, TestLocationArrayType } from './TestLocation';
import { FilterValuesType } from './InitialState';

export const parseMetadata = createParseFunction(MetadataType);
export const parseGeojsonFeatureCollection = createParseFunction(GeojsonFeatureCollectionType);
export const parseMetricByDateDataArray = createParseFunction(MetricByDateDataArrayType);
export const parseMetricByFipsDataArray = createParseFunction(MetricByFipsDataArrayType);
export const parseGroupedMetricDataArray = createParseFunction(GroupedMetricDataArrayType);
export const parseAllMetricsDataArray = createParseFunction(AllMetricsDataArrayType);
export const parseGeoLevelMetaArray = createParseFunction(GeoLevelMetaArrayType);
export const parseTestLocationArray = createParseFunction(TestLocationArrayType);
export const parseFilterValues = createParseFunction(FilterValuesType);

export type ParsedMetricMetadata = TypeOf<typeof MetricMetadataType>;
export type ParsedMetricCategory = TypeOf<typeof MetricGroupingType>;
export type ParsedMetadata = TypeOf<typeof MetadataType>;
export type ParsedGeojsonFeatureCollection = TypeOf<typeof GeojsonFeatureCollectionType>;
export type ParsedGeojsonFeature = TypeOf<typeof GeojsonFeatureType>;
export type ParsedMetricByFipsData = TypeOf<typeof MetricByFipsDataType>;
export type ParsedGroupedMetricData = TypeOf<typeof GroupedMetricDataType>;
export type ParsedMetricByDateData = TypeOf<typeof MetricByDateDataType>;
export type ParsedGeoLevel = TypeOf<typeof GeoLevelType>;
export type ParsedAllMetricsData = TypeOf<typeof AllMetricsDataType>;
export type ParsedGeoLevelMeta = TypeOf<typeof GeoLevelMetaType>;
export type ParsedTestLocation = TypeOf<typeof TestLocationType>;
export type ParsedFilterValues = TypeOf<typeof FilterValuesType>;
