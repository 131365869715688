var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col h-button w-full justify-end px-1 sm:px-8"},[_c('div',{staticClass:"slider flex flex-col h-full justify-end relative"},[_vm._l((_vm.selectedRegionValues),function(region,i){return _c('div',{key:i,staticClass:"value absolute",style:({ left: _vm.scale(region.value) + '%' })},[_c('div',{staticClass:"value-label inline-block"},[_c('div',{staticClass:"value-title font-franklin text-sm text-gray-700 whitespace-no-wrap m-auto text-center"},[_vm._v(" "+_vm._s(region.name)+" ")]),_c('svg',{staticClass:"mx-auto",attrs:{"width":"8","height":"5","viewBox":"0 0 8 5","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M4 5L0.535899 0.499999L7.4641 0.5L4 5Z","fill":"black"}})])])])}),_c('vue-slider',{attrs:{"value":_vm.range,"min":_vm.filter.extent[0],"max":_vm.filter.extent[1],"interval":_vm.filter.interval,"tooltip":_vm.tooltip,"lazy":true,"marks":_vm.marks,"hide-label":true,"enable-cross":false},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"tooltip",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"flex",class:{
                        'custom-tooltip': true
                    }},[(_vm.showLessThan(value))?_c('span',{staticClass:"inline"},[_vm._v(" ≤ ")]):_vm._e(),(_vm.showGreaterThan(value))?_c('span',{staticClass:"inline"},[_vm._v(" ≥ ")]):_vm._e(),_c('span',{staticClass:"inline"},[_vm._v(_vm._s(_vm.filter.percentage ? (value * 100).toFixed(0) : value))]),(_vm.filter.percentage)?_c('span',[_vm._v("%")]):_vm._e()])]}},{key:"dot",fn:function(ref){
                    var focus = ref.focus;
return [_c('div',{class:['custom-dot', { focus: focus }]})]}},{key:"step",fn:function(ref){
                    var active = ref.active;
return [_c('div',{class:['custom-step', { active: active }]})]}}])}),_c('div',{staticClass:"flex flex-row",class:{
                'opacity-0': !_vm.filter.showLabels
            }},[_c('span',{staticClass:"xAxis"},[_vm._v("VERY LOW")]),_c('span',{staticClass:"xAxis text-center"},[_vm._v("LOW")]),_c('span',{staticClass:"xAxis text-center"},[_vm._v("MODERATE")]),_c('span',{staticClass:"xAxis text-center"},[_vm._v("HIGH")]),_c('span',{staticClass:"xAxis text-right"},[_vm._v("VERY HIGH")])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }