import _ from 'lodash';
import { ParsedGeoLevel } from '@/services/DataService/parsers';
import { computed, observable, ReactiveObject } from '@dha/vue-composition-decorators';
import { Option } from '@/types';
import { DataService, GeoLevelMeta } from '@/services/DataService';
import { Geography, Focus, focusOptionFromMeta, nationalGeoLevelMeta } from './Geography';
import { properCase } from './helpers';

export class CountyGeography extends ReactiveObject implements Geography {
    dataService: DataService;
    geoLevel = 'county' as const;
    @observable.ref metadata: GeoLevelMeta[] = [];
    @observable.ref focus: Focus;

    constructor(dataService: DataService, focus?: Focus) {
        super();
        this.dataService = dataService;
        this.focus = focus ?? nationalGeoLevelMeta;
    }
    async init() {
        await this.fetchMetadata();
        if (this.focus.geoLevel === 'county') {
            const stateMeta = _.find(this.metadata, { stateFIPS: this.focus.stateFIPS });
            if (stateMeta) {
                this.focus = stateMeta;
            }
        }
    }

    async fetchMetadata() {
        const [
            stateMeta,
            countyMeta
        ] = await Promise.all([
            this.dataService.getGeoLevelMeta('state'),
            this.dataService.getGeoLevelMeta('county')
        ]);
        this.metadata = [
            nationalGeoLevelMeta,
            ...stateMeta.map(({ name, ...rest }) => ({ name: properCase(name), ...rest })),
            ...countyMeta
        ];
    }

    @computed get metadataByFipsCode() {
        return _.keyBy(this.metadata, 'fips');
    }

    @computed get filteredMetadata() {
        const focus = this.focus;
        return this.metadata.filter(meta => (
            (meta.geoLevel === 'state' || meta.geoLevel === 'national')
            || focus.geoLevel === 'national'
            || (focus.geoLevel === 'county' && meta.countyFIPS === focus.countyFIPS)
            || (focus.geoLevel === 'state' && meta.stateFIPS === focus.stateFIPS)
        ));
    }

    // eslint-disable-next-line class-methods-use-this
    @computed get geoLevelOptions(): Option<ParsedGeoLevel>[] {
        return [{
            value: 'state',
            displayValue: 'States'
        }, {
            value: 'county',
            displayValue: 'Counties'
        }, {
            value: 'tract',
            displayValue: 'Tracts'
        }];
    }

    @computed get stateFocusOptions(): Option<Focus>[] {
        return [{
            value: nationalGeoLevelMeta,
            displayValue: 'All USA'
        }, ...this.metadata
            .filter(meta => meta.geoLevel === 'state')
            .map(focusOptionFromMeta)
        ];
    }

    @computed get countyFocusOptions(): Option<Focus>[] {
        const focus = this.focus;
        const stateFocusOption = focus ? focusOptionFromMeta(this.metadataByFipsCode[focus.stateFIPS]) : undefined;
        return (focus.geoLevel !== 'national' && stateFocusOption)
            ? [
                {
                    ...stateFocusOption,
                    displayValue: `All ${stateFocusOption?.displayValue} Counties`
                },
                ...this.metadata
                    .filter(meta => meta.geoLevel === 'county'
                        && meta.stateFIPS === focus.stateFIPS)
                    .map(focusOptionFromMeta)]
            : [];
    }

    get stateFocus() {
        if (this.focus?.geoLevel === 'county') {
            return focusOptionFromMeta(this.metadataByFipsCode[this.focus.stateFIPS]).value;
        }
        return this.focus;
    }

    get countyFocus() {
        return this.focus;
    }

    getNextFipsCode(currentFipsMetadata: GeoLevelMeta): string {
        const noChangeResult = _.find(this.filteredMetadata, { geoLevel: 'county', fips: currentFipsMetadata.fips });
        if (noChangeResult) {
            return noChangeResult.fips;
        }
        const matchingFocusResult = _.find(this.filteredMetadata, { fips: this.focus.fips });
        if (matchingFocusResult) {
            return matchingFocusResult.fips;
        }
        if (currentFipsMetadata.countyFIPS) {
            const matchingCountyResult = _.find(this.filteredMetadata, { countyFIPS: currentFipsMetadata.countyFIPS })?.countyFIPS;
            if (matchingCountyResult) {
                return matchingCountyResult;
            }
        }
        const matchingStateResult = _.find(this.filteredMetadata, { geoLevel: 'county', stateFIPS: currentFipsMetadata.stateFIPS })?.fips;
        if (matchingStateResult) {
            return matchingStateResult;
        }

        const anyCountyResult = _.find(this.filteredMetadata, { geoLevel: 'county' })?.fips;
        if (anyCountyResult) {
            return anyCountyResult;
        }
        return '99999';
    }

    getUpLevelMetadata(fipsCode: string): GeoLevelMeta | undefined {
        const meta = this.metadataByFipsCode[fipsCode];
        return this.metadataByFipsCode[meta.stateFIPS];
    }

    async setFocusFipsCode(fips?: string): Promise<void> {
        const meta = _.find(this.metadata, { fips });
        this.focus = meta ? focusOptionFromMeta(meta).value : nationalGeoLevelMeta;
    }
}
