















import Vue, { PropType } from 'vue';
import { AccordionGroup } from '@/types';
import AccordionItem from './AccordionItem.vue';

export default Vue.extend({
    components: {
        AccordionItem
    },
    props: {
        data: {
            type: Array as PropType<AccordionGroup[]>,
            default: () => []
        },
        selectedRegion: {
            type: String,
            default: 'United States'
        },
        selectedGroup: {
            type: String as PropType<string | null>,
            default: null
        },
        selectedMetric: {
            type: String as PropType<string | null>,
            default: null
        }
    },
    computed: {

    },
    methods: {
        onClickGroup(id: string) {
            this.$emit('group', id);
        },
        onClickMetric(id: string) {
            this.$emit('metric', id);
        }
    }
});
