import { GeoLevelMeta } from '@/services/DataService';
import { ParsedGeoLevel } from '@/services/DataService/parsers';
import { Option } from '@/types';

export type Focus = {
    geoLevel: ParsedGeoLevel;
    fips: string;
    name: string;

    countyFIPS?: string;
    stateFIPS: string;
}

export interface Geography {
    geoLevelOptions: Option<ParsedGeoLevel>[];
    stateFocusOptions: Option<Focus>[];
    countyFocusOptions: Option<Focus>[];

    stateFocus?: Focus;
    countyFocus?: Focus;

    focus: Focus;
    metadataByFipsCode: Record<string, GeoLevelMeta>;

    init(): Promise<void>;
    getNextFipsCode(currentFipsMetadata: GeoLevelMeta): string;
    setFocusFipsCode(fips?: string): Promise<void>;
}

export function focusOptionFromMeta(meta: GeoLevelMeta): Option<Focus> {
    return {
        value: meta,
        displayValue: meta.name
    };
}

export const nationalGeoLevelMeta: GeoLevelMeta = {
    countyFIPS: undefined,
    fips: '99999',
    stateFIPS: '99999',
    geoLevel: 'national',
    name: 'USA',
    population: undefined
};
