import { render, staticRenderFns } from "./CheckboxGroup.vue?vue&type=template&id=fed36886&scoped=true&"
import script from "./CheckboxGroup.vue?vue&type=script&lang=ts&"
export * from "./CheckboxGroup.vue?vue&type=script&lang=ts&"
import style0 from "./CheckboxGroup.vue?vue&type=style&index=0&id=fed36886&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fed36886",
  null
  
)

export default component.exports