import _ from 'lodash';
import { Autotext, BarChartDatum } from '@/types';

type AutotextReplacer = (
    data: BarChartDatum[],
    options: AutotextOptions,
    more?: string,
    less?: string
) => string | null;

type AutotextOptions = {
    metric: string;
    location?: string;
    formatter?: (s: number) => string;
}

const matchVariable = /\[\[([^\]]+)\]\]/g;
const removeBrackets = /[[\]]/g;

// returns all variable names present in the autotext string (eg. vhigh, vhighvlowdiff:more|less left side)
function getVariables(autoString: string) {
    const matches = autoString
        .match(matchVariable)
        ?.map(s => s.replace(removeBrackets, '').split(':')[0].toLowerCase());
    return new Set(matches);
}

function stringify(value: number, optFormatter?: (n: number) => string): string {
    const formatter = optFormatter ?? ((n: number) => (n === 0 ? String(n) : Math.abs(n).toFixed(2)));
    return formatter(Math.abs(value));
}

function singleValue(data: BarChartDatum[], key: string): number | undefined | null {
    return data.find(datum => datum.grouping === key)?.value;
}

// pick values from multiple groupings and add them together
function addValues(data: BarChartDatum[], ...keys: string[]): number | undefined {
    const values = keys.map(key => singleValue(data, key));
    if (values.some(value => _.isNil(value))) {
        return undefined;
    }
    return _.sum(values);
}

function vHighvLowDiff(data: BarChartDatum[]): number | null {
    const vHighValue = singleValue(data, 'Very High');
    const vLowValue = singleValue(data, 'Very Low');

    if (_.isNil(vLowValue) || _.isNil(vHighValue)) {
        return null;
    }
    return vHighValue - vLowValue;
}

// if just the value: return the formatted value
// if 'more' and 'less' exist: pick the 'more' text for positive values and the 'less' text for negative values
export function valueText(value: number | undefined | null, formatter?: (s: number) => string, more?: string, less?: string): string | null {
    if (_.isNil(value)) {
        return null;
    }
    if (!more || !less) {
        return stringify(value, formatter);
    }
    return (value > 0 ? more : less);
}

// if just the data: return the formatted difference between 'Very High' and 'Very Low'
// if 'more' and 'less' exist: pick the 'more' text for positive diffs and the 'less' text for negative diffs
function vHighvLowDiffText(data: BarChartDatum[], formatter?: (s: number) => string, more?: string, less?: string): string | null {
    const diff = vHighvLowDiff(data);
    if (_.isNil(diff)) return null;

    if (!more || !less || diff === 0) {
        return stringify(diff, formatter);
    }
    return (diff > 0 ? more : less) || '';
}

export const replaceFunctions: Record<string, AutotextReplacer> = {
    vhigh: (data, opts, more, less) => valueText(singleValue(data, 'Very High'), opts.formatter, more, less),
    high: (data, opts, more, less) => valueText(singleValue(data, 'High'), opts.formatter, more, less),
    moderate: (data, opts, more, less) => valueText(singleValue(data, 'Moderate'), opts.formatter, more, less),
    low: (data, opts, more, less) => valueText(singleValue(data, 'Low'), opts.formatter, more, less),
    vlow: (data, opts, more, less) => valueText(singleValue(data, 'Very Low'), opts.formatter, more, less),
    vhighhigh: (data, opts, more, less) => valueText(addValues(data, 'Very High', 'High'), opts.formatter, more, less),
    vhighvlowdiff: (data, opts, more, less) => vHighvLowDiffText(data, opts.formatter, more, less),
    metric: (data, opts) => opts.metric,
    location: (data, opts) => opts.location ?? 'the selected location'
};

export const matchReplace = (match: string, data: BarChartDatum[], options: AutotextOptions) => {
    const variable = match.replace(removeBrackets, '');
    const [func, args] = variable.split(':');
    if (!replaceFunctions[func]) throw new Error(`${match} is not a valid autotext variable.`);

    let replaced: string | null;
    // variable with no : arguments (eg. vhigh)
    if (!args) {
        replaced = replaceFunctions[func](data, options);
    } else {
        // variable with : arguments (eg. vhigh:more|less)
        replaced = replaceFunctions[func](data, options, ...args.split('|'));
    }
    if (_.isNil(replaced)) throw new Error(`Autotext variable ${match} was given invalid data`);
    return replaced;
};

// detect variables in the autotext string and replace them with the appropriate formatted value from replaceFunctions
export function replaceVariables(autoString: string, data: BarChartDatum[], options: AutotextOptions): string | null {
    try {
        return _.upperFirst(
            autoString.replace(matchVariable, match => matchReplace(match, data, options))
        );
    } catch (e) {
        console.warn(e.message);
        return null;
    }
}

function formatAutotext(autotext: Autotext, data: BarChartDatum[], options: AutotextOptions): string {
    let outString: string | null;
    if (autotext.single) {
        outString = replaceVariables(autotext.single, data, options);
    } else {
        const variables = getVariables(autotext.caseDifferent);
        if (variables.has('vhighvlowdiff')) {
            if (vHighvLowDiff(data) === 0) {
                outString = replaceVariables(autotext.caseEqual, data, options);
            } else {
                outString = replaceVariables(autotext.caseDifferent, data, options);
            }
        } else {
            outString = replaceVariables(autotext.caseEqual, data, options);
        }
    }

    if (outString) return outString;
    if (autotext.caseNoData) {
        return replaceVariables(autotext.caseNoData, data, options) || '';
    }
    return '';
}

export default formatAutotext;
