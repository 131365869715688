









import Vue, { PropType } from 'vue';

import { elementIsAncestor } from './helpers';

export default Vue.extend({
    props: {
        value: {
            type: Boolean,
            default: false
        },
        menuId: {
            type: String as PropType<string | null>,
            default: null
        }
    },
    data() {
        const close = (event: MouseEvent) => {
            const menu = this.menuId
                ? document.querySelector(`#${this.menuId}`)
                : null;

            const anchorClicked = elementIsAncestor(
                event.target as HTMLElement,
                this.$el as HTMLElement
            );
            const menuClicked = menu && elementIsAncestor(
                event.target as HTMLElement,
                menu as HTMLElement
            );
            if (!(anchorClicked || menuClicked)) {
                this.$emit('input', false);
            }
        };

        return {
            close
        };
    },
    mounted() {
        document.addEventListener('click', this.close);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.close);
    },
    methods: {
        onAnchorClick() {
            this.$emit('input', !this.value);
        }
    }
});
