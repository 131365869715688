import copy from 'copy-to-clipboard';
import { ReactiveObject } from '@dha/vue-composition-decorators';
import { SocialDialogState } from '@/types';
import { DeepLinkService } from '../DeepLinkService';

/* eslint-disable class-methods-use-this */

export class SocialShareService extends ReactiveObject {
    private deepLinkService: DeepLinkService<any>;
    private socialDialogTitles: Record<string, string> = {
        link: 'Copy Link to Clipboard',
        twitter: 'Share on Twitter',
        facebook: 'Share on Facebook',
        linkedin: 'Share on LinkedIn',
    };
    private baseLinks: Record<string, string> = {
        twitter: 'https://twitter.com/intent/tweet?url=',
        facebook: 'https://facebook.com/dialog/share?app_id=479247610132647&display=popup&href=',
        linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=',
    };

    constructor(deepLinkService: DeepLinkService<any>) {
        super();
        this.deepLinkService = deepLinkService;
    }

    copyShortLink() {
        return copy(window.location.origin);
    }
    copyDeepLink() {
        return copy(this.deepLinkService.deepLinkUrl);
    }

    socialDialogTitle(social: SocialDialogState): string {
        return social ? this.socialDialogTitles[social] : 'Share';
    }

    shortLink(social: SocialDialogState): string | null {
        return social && social !== 'link'
            ? `${this.baseLinks[social]}${encodeURIComponent(window.location.origin)}`
            : null;
    }

    deepLink(social: SocialDialogState): string | null {
        return social && social !== 'link'
            ? `${this.baseLinks[social]}${encodeURIComponent(this.deepLinkService.deepLinkUrl)}`
            : null;
    }
}
