import { Focus } from '@/model/GeographyModel/Geography';
import { DataService } from '@/services/DataService';
import { GeojsonFeatureCollection } from '@/types';
import { createPointFeature } from '../helpers';

type LatLon = {lat: number; lon: number};

export class PointLayer {
    points: LatLon[] = [];

    constructor(points: LatLon[]) {
        this.points = points;
    }

    getFeatureCollection(showTestSites: boolean): GeojsonFeatureCollection {
        return {
            type: 'FeatureCollection',
            features: showTestSites
                ? this.points
                    .map(({ lon, lat }) => createPointFeature([lon, lat]))
                : []
        };
    }

    static async new(
        dataService: DataService,
        focus: Focus
    ) {
        let points: LatLon[] = [];
        if (focus.geoLevel !== 'national') {
            points = await dataService.getTestLocations({
                geoLevel: focus.geoLevel,
                fipsCode: focus.fips
            });
        }
        return new PointLayer(points);
    }
}
