import { observable, computed, ReactiveObject } from '@dha/vue-composition-decorators';
import { DataService, GeoLevelMeta } from '@/services/DataService';
import { ParsedGeoLevel } from '@/services/DataService/parsers';

import { StateGeography } from './StateGeography';
import { CountyGeography } from './CountyGeography';
import { TractGeography } from './TractGeography';
import { GeographyBuilder } from './GeographyBuilder';
import { Focus, nationalGeoLevelMeta } from './Geography';

type AnyGeography = StateGeography | CountyGeography | TractGeography;

type InitialState = {
    fipsCode: string;
    geoLevel: 'state' | 'county';
    focus: Focus;
} | {
    fipsCode: string;
    geoLevel: 'tract';
    focus: Focus;
}

type SetFocusOptions = {
    availableGeoLevels: ParsedGeoLevel[];
}
export class GeographyModel extends ReactiveObject {
    geographyBuilder: GeographyBuilder;
    @observable.ref private currentGeography: AnyGeography;
    @observable fipsCode: string;

    constructor(dataService: DataService) {
        super();
        this.geographyBuilder = new GeographyBuilder(dataService);

        // TODO: What should we do for this placeholder?
        this.currentGeography = new StateGeography(dataService);
        this.fipsCode = '99999';
    }

    async init(initialState?: InitialState) {
        if (!initialState) {
            await this.currentGeography.init();
            return;
        }

        this.currentGeography = await this.geographyBuilder.build(
            initialState.geoLevel,
            initialState.focus
        );

        this.fipsCode = initialState.fipsCode;
    }

    @computed get geoLevel() {
        return this.currentGeography.geoLevel;
    }
    @computed get name() {
        return this.currentFipsMetadata?.name ?? '';
    }
    @computed get geoLevelOptions() {
        return this.currentGeography.geoLevelOptions;
    }
    @computed get stateFocusOptions() {
        return this.currentGeography.stateFocusOptions;
    }
    @computed get countyFocusOptions() {
        return this.currentGeography.countyFocusOptions;
    }
    @computed get stateFocus() {
        return this.currentGeography.stateFocus;
    }
    @computed get countyFocus() {
        return this.currentGeography.countyFocus;
    }
    @computed get focus() {
        return this.currentGeography.focus;
    }
    @computed get metadataByFipsCode(): Record<string, GeoLevelMeta> {
        return this.currentGeography.metadataByFipsCode;
    }
    @computed get currentFipsMetadata(): GeoLevelMeta | undefined {
        return this.currentGeography.metadataByFipsCode[this.fipsCode];
    }
    @computed get focusMetadata(): GeoLevelMeta {
        return this.currentGeography.metadataByFipsCode[this.focus.fips];
    }
    @computed get currentUpLevelMetadata(): GeoLevelMeta {
        if (this.focus.fips === '99999') {
            return nationalGeoLevelMeta;
        }
        return this.currentGeography.metadataByFipsCode[this.focus.stateFIPS];
    }

    getStateMetadata(geography: AnyGeography): GeoLevelMeta | undefined {
        if (geography.geoLevel === 'state') return geography.metadataByFipsCode[this.fipsCode];
        return geography.getUpLevelMetadata(this.fipsCode);
    }
    getName(fipsCode: string): string {
        return this.metadataByFipsCode[fipsCode]?.name ?? '';
    }
    getPopulation(fipsCode: string): number | undefined {
        return this.metadataByFipsCode[fipsCode]?.population;
    }

    async setFocusFipsCode(
        focus: string,
        options: SetFocusOptions = {
            availableGeoLevels: ['state', 'county', 'tract']
        }
    ): Promise<void> {
        const currentMeta = this.currentGeography.metadataByFipsCode[this.fipsCode];
        const focusMeta = focus ? this.currentGeography.metadataByFipsCode[focus] : nationalGeoLevelMeta;

        if (this.currentGeography.geoLevel === 'tract') {
            if (focusMeta.geoLevel !== 'national') {
                await this.currentGeography.setFocusFipsCode(focus);
            } else {
                this.currentGeography = await this.geographyBuilder.build(
                    'county',
                    focusMeta
                );
            }
        } else if (this.currentGeography.geoLevel === 'county') {
            if (options?.availableGeoLevels.includes('tract')
                && focusMeta && focusMeta?.geoLevel === 'county') {
                this.currentGeography = await this.geographyBuilder.build(
                    'tract',
                    focusMeta
                );
            } else {
                await this.currentGeography.setFocusFipsCode(focus);
            }
        } else if (this.currentGeography.geoLevel === 'state') {
            if (options?.availableGeoLevels.includes('county')
                && focusMeta && focusMeta?.geoLevel === 'state') {
                this.currentGeography = await this.geographyBuilder.build(
                    'county',
                    focusMeta
                );
            } else {
                await this.currentGeography.setFocusFipsCode(focus);
            }
        }
        this.fipsCode = this.currentGeography.getNextFipsCode(currentMeta);
    }
    async setGeoLevel(geoLevel: ParsedGeoLevel): Promise<void> {
        const currentMeta = this.currentGeography.metadataByFipsCode[this.fipsCode];
        if (geoLevel !== this.currentGeography.geoLevel) {
            if (geoLevel === 'tract' && this.currentGeography.focus.geoLevel === 'national') {
                const stateFocus = currentMeta.geoLevel === 'state'
                    ? currentMeta
                    // Try to find the state that the current selection is in (if any)
                    : this.currentGeography.stateFocusOptions.find(
                        option => option.value.geoLevel === 'state'
                            && option.value.stateFIPS === currentMeta.stateFIPS
                    )?.value
                    // Otherwise, find the first state in the focus options
                    ?? this.currentGeography.stateFocusOptions.find(
                        option => option.value.geoLevel === 'state'
                    )?.value
                    // Otherwise, default to currentMeta
                    ?? currentMeta;

                this.currentGeography = await this.geographyBuilder.build(
                    geoLevel,
                    stateFocus
                );
            } else {
                this.currentGeography = await this.geographyBuilder.build(
                    geoLevel,
                    this.currentGeography.focus
                );
            }
        }
        this.fipsCode = this.currentGeography.getNextFipsCode(currentMeta);
    }
}
