import { DataService } from '@/services/DataService';

import { ParsedGeoLevel } from '@/services/DataService/parsers';
import { StateGeography } from './StateGeography';
import { CountyGeography } from './CountyGeography';
import { TractGeography } from './TractGeography';
import { Focus } from './Geography';

type AnyGeography = StateGeography | CountyGeography | TractGeography;

export class GeographyBuilder {
    dataService: DataService;
    constructor(dataService: DataService) {
        this.dataService = dataService;
    }

    async build(geoLevel: ParsedGeoLevel, focus: Focus): Promise<AnyGeography> {
        let newGeography: AnyGeography;
        if (geoLevel === 'state') {
            newGeography = new StateGeography(this.dataService);
        } else if (geoLevel === 'county') {
            newGeography = new CountyGeography(this.dataService, focus);
        } else if (geoLevel === 'tract') {
            if (focus.geoLevel === 'national') {
                throw new Error('Cannot initialize TractGeography without a focus');
            }
            newGeography = new TractGeography(this.dataService, focus);
        } else {
            throw new Error(`Invalid geoLevel in GeographyBuilder.build: ${geoLevel}`);
        }
        await newGeography.init();

        return newGeography;
    }
}
