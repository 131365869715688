













import Vue from 'vue';
import Accordion from '@/components/Accordion/Accordion.vue';
import { AccordionGroup } from '@/types';

export default Vue.extend({
    components: {
        Accordion,
    },
    computed: {
        data(): AccordionGroup[] {
            return this.$appModel.accordionModel.accordionGroups;
        },
        selectedGroup(): string | undefined {
            return this.$appModel.accordionModel.selectedGroup;
        },
        selectedMetric(): string {
            return this.$appModel.baseMetricId;
        },
        selectedRegion(): string {
            return this.$appModel.accordionModel.selectedRegionName;
        }
    },
    methods: {
        onChangeGroup(groupingId: string) {
            this.$appModel.accordionModel.setGrouping(groupingId);
        },
        onChangeMetric(metricId: string) {
            this.$appModel.setMetricId(metricId);
        }
    }
});
