import { observable, reaction, ReactiveObject } from '@dha/vue-composition-decorators';

/* eslint-disable class-methods-use-this */
export class DeepLinkService<T extends Record<string, any>> extends ReactiveObject {
    @observable deepLinkUrl: string = window.location.href;

    observe(callback: () => T): void {
        reaction(
            callback,
            (values: T) => {
                const params = new URLSearchParams(values);
                const url = `${window.location.origin}/?${params.toString()}`;
                this.deepLinkUrl = url;
            },
            { immediate: true, flush: 'sync' }
        );
    }

    async getUrlState(): Promise<Partial<T>> {
        const searchStr = window.location.search;
        if (searchStr.length > 0) {
            const search = new URLSearchParams(searchStr.substr(1));
            const state = {};
            for (const [key, value] of search) {
                state[key] = decodeURIComponent(value);
            }
            return state as Partial<T>;
        }
        return {};
    }
}
