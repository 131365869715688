import _ from 'lodash';
import { ParsedGeoLevel } from '@/services/DataService/parsers';
import { computed, observable, ReactiveObject } from '@dha/vue-composition-decorators';
import { Option } from '@/types';
import { DataService, GeoLevelMeta } from '@/services/DataService';
import { Geography, Focus, focusOptionFromMeta, nationalGeoLevelMeta } from './Geography';
import { properCase } from './helpers';

export class TractGeography extends ReactiveObject implements Geography {
    dataService: DataService
    geoLevel = 'tract' as const;
    @observable.ref metadata: GeoLevelMeta[] = [];
    @observable.ref focus: Focus;

    constructor(dataService: DataService, focus: Focus) {
        super();
        this.dataService = dataService;
        this.focus = focus;
    }
    async init() {
        await this.fetchMetadata();
    }

    async fetchMetadata() {
        const filter = {
            geoLevel: 'state' as const,
            fipsCode: this.focus.stateFIPS
        };

        const [
            stateMeta,
            countyMeta,
            tractMeta
        ] = await Promise.all([
            this.dataService.getGeoLevelMeta('state'),
            this.dataService.getGeoLevelMeta('county', filter),
            this.dataService.getGeoLevelMeta('tract', filter)
        ]);

        this.metadata = [
            nationalGeoLevelMeta,
            ...stateMeta.map(({ name, ...rest }) => ({ name: properCase(name), ...rest })),
            ...countyMeta,
            ...tractMeta
        ];
    }

    readonly geoLevelOptions: Option<ParsedGeoLevel>[] = [{
        value: 'state',
        displayValue: 'States'
    }, {
        value: 'county',
        displayValue: 'Counties'
    }, {
        value: 'tract',
        displayValue: 'Tracts'
    }]

    @computed get metadataByFipsCode() {
        return _.keyBy(this.metadata, 'fips');
    }

    @computed get filteredMetadata() {
        const focus = this.focus;
        if (focus.geoLevel === 'state') {
            return this.metadata.filter(meta => (
                (meta.geoLevel === 'state')
                || (meta.geoLevel === 'county'
                    && focus.stateFIPS === meta.stateFIPS
                )
                || (meta.geoLevel === 'tract'
                    && focus.stateFIPS === meta.stateFIPS)
            ));
        }
        return this.metadata.filter(meta => (
            (meta.geoLevel === 'state')
            || (meta.geoLevel === 'county'
                && focus.countyFIPS === meta.countyFIPS
            )
            || (meta.geoLevel === 'tract'
                && focus.countyFIPS === meta.countyFIPS)
        ));
    }

    @computed get stateFocusOptions(): Option<Focus>[] {
        return [{
            value: nationalGeoLevelMeta,
            displayValue: 'All USA'
        }, ...this.metadata
            .filter(meta => meta.geoLevel === 'state')
            .map(focusOptionFromMeta)
        ];
    }

    @computed get countyFocusOptions(): Option<Focus>[] {
        const focus = this.focus;
        const stateFocusOption = focusOptionFromMeta(this.metadataByFipsCode[focus.stateFIPS]);
        return [
            {
                ...stateFocusOption,
                displayValue: `All ${stateFocusOption?.displayValue} Counties`
            },
            ...this.metadata
                .filter(meta => meta.geoLevel === 'county'
                && meta.stateFIPS === focus.stateFIPS)
                .map(focusOptionFromMeta)
        ];
    }

    get stateFocus() {
        if (this.focus.geoLevel === 'county') {
            return focusOptionFromMeta(this.metadataByFipsCode[this.focus.stateFIPS]).value;
        }
        return this.focus;
    }

    get countyFocus() {
        return this.focus;
    }

    getNextFipsCode(currentFipsMetadata: GeoLevelMeta): string {
        const noChangeResult = _.find(this.filteredMetadata, { geoLevel: 'tract', fips: currentFipsMetadata.fips });
        if (noChangeResult) {
            return noChangeResult.fips;
        }
        const focusGeoLevel = this.focus.geoLevel;
        const matchingFocusResult = _.find(this.filteredMetadata, { fips: this.focus.fips });
        if (matchingFocusResult) {
            return matchingFocusResult.fips;
        }
        if (focusGeoLevel === 'county' && currentFipsMetadata.countyFIPS) {
            const matchingCountyResult = _.find(this.filteredMetadata, { geoLevel: 'tract', countyFIPS: currentFipsMetadata.countyFIPS });
            if (matchingCountyResult) {
                return matchingCountyResult?.fips;
            }
        }
        if (focusGeoLevel === 'state') {
            const matchingStateResult = _.find(this.filteredMetadata, { geoLevel: 'tract', stateFIPS: currentFipsMetadata.stateFIPS });
            if (matchingStateResult) {
                return matchingStateResult?.fips;
            }
        }

        const anyTractResult = _.find(this.filteredMetadata, { geoLevel: 'tract' });
        if (anyTractResult) {
            return anyTractResult?.fips;
        }
        return '01065040200';
    }

    getUpLevelMetadata(fipsCode: string): GeoLevelMeta | undefined {
        const meta = this.metadataByFipsCode[fipsCode];
        return meta.countyFIPS
            ? this.metadataByFipsCode[meta.countyFIPS]
            : undefined;
    }

    async setFocusFipsCode(fips: string): Promise<void> {
        const meta = _.find(this.metadata, { fips });
        if (!meta) {
            console.warn(`No metadata for fips: ${fips}`);
            return;
        }
        const prevStateFips = this.focus.stateFIPS;
        this.focus = focusOptionFromMeta(meta).value;
        if (prevStateFips !== this.focus.stateFIPS) {
            await this.fetchMetadata();
        }
    }
}
