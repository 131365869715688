










import Vue, { PropType } from 'vue';

import DhaSidebar from '@/components/Sidebar/Sidebar.vue';
import { ActionLink, TextLink } from '@/types';
import SidebarContent from './SidebarContent.vue';

export default Vue.extend({
    components: {
        DhaSidebar,
        SidebarContent
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        links: {
            type: Array as PropType<Array<TextLink | ActionLink>>,
            default: () => [],
        }
    },
    methods: {
        onInput(value: boolean) {
            this.$emit('input', value);
        }
    }
});
