import _ from 'lodash';
import { DataService, GeoLevelMeta } from '@/services/DataService';
import { ParsedGeoLevel, ParsedGeoLevelMeta, ParsedMetadata, ParsedMetricByDateData, ParsedMetricMetadata } from '@/services/DataService/parsers';
import { AreaChartData, HeatmapData } from '@/types';
import { observable, computed, ReactiveObject } from '@dha/vue-composition-decorators';
import format from '@dha/number-format';
import { utcFormat } from 'd3-time-format';
import { getTailwindColor } from '@/components/getTailwindStyle';
import { scaleQuantize } from 'd3-scale';
import { categoricalMetricData, extentByValue, numericalMetricData } from './helpers';
import { Metric } from './Metric';
import { GeographyModel } from './GeographyModel';
import { ColorScale } from './Metric/Metric';

const twGray500 = getTailwindColor('gray', 500);
const twGray300 = getTailwindColor('gray', 300);

export interface TemporalDataModelParent {
    isLoaded: boolean;
    metricId: string;
    geoLevel: ParsedGeoLevel;
    fipsCode: string;
    fipsMetadata?: ParsedGeoLevelMeta;
    date: string;
    metadata?: ParsedMetadata;
    metric?: Metric;
    geographyModel: GeographyModel;
}

// Data over time for area chart + heatmap
export class TemporalDataModel extends ReactiveObject {
    dataService: DataService;
    parent: TemporalDataModelParent;

    @observable.ref dataError = false;
    @observable.ref metricDataByDate: ParsedMetricByDateData[] = [];
    @observable.ref metricDataByDateUpGeoLevel: ParsedMetricByDateData[] = [];
    @observable metricMetadata?: ParsedMetricMetadata;

    constructor(dataService: DataService, parent: TemporalDataModelParent) {
        super();
        this.dataService = dataService;
        this.parent = parent;
    }

    async init() {
        await this.updateData();
    }

    async updateData() {
        try {
            const currentMeta = this.parent.geographyModel.currentFipsMetadata;
            const upLevelMeta = this.parent.geographyModel.currentUpLevelMetadata;

            const [
                metricDataByDate,
                metricDataByDateUpGeoLevel
            ] = await Promise.all([
                // TODO: make this work for tracts/national?
                currentMeta
                    ? this.dataService.getMetricDataForFips(this.parent.metricId, { geoLevel: currentMeta.geoLevel, fipsCode: currentMeta.fips })
                    : [],
                this.dataService.getMetricDataForFips(this.parent.metricId, { geoLevel: upLevelMeta.geoLevel, fipsCode: upLevelMeta.fips })
            ]);

            this.metricDataByDate = metricDataByDate;
            this.metricDataByDateUpGeoLevel = metricDataByDateUpGeoLevel;
            this.metricMetadata = this.parent.metric?.metadata;
            this.dataError = false;
        } catch (err) {
            console.log('Error loading temporal data');
            console.error(err);
            this.metricDataByDate = [];
            this.metricDataByDateUpGeoLevel = [];
            this.dataError = true;
        }
    }

    @computed get metricTitle(): string {
        const metric = this.parent.metric;
        const metaTitle = metric?.metadata?.sectionTitles.lineTitle;
        return metaTitle && metaTitle !== ''
            ? metaTitle
            : (metric?.metadata?.label ?? this.parent.metricId);
    }

    @computed get title(): string {
        return `${this.metricTitle} Over Time`;
    }

    @computed get description(): string {
        const metric = this.parent.metric;
        return metric?.metadata?.textBlocks[2] || '';
    }

    @computed get upGeoLevelFipsMetadata(): GeoLevelMeta {
        return this.parent.geographyModel.currentUpLevelMetadata;
    }

    @computed get colorScale(): ColorScale {
        return this.parent.metric?.getColorScale(this.parent.geoLevel)
            ?? scaleQuantize();
    }

    @computed get primaryColor(): string {
        if (!this.metricMetadata) return twGray500;
        return this.metricMetadata.color ?? twGray500;
    }

    @computed get secondaryColor(): string {
        if (!this.metricMetadata) return twGray300;
        return this.metricMetadata?.secondaryColor ?? twGray300;
    }

    @computed get areaData(): AreaChartData[] {
        if (!this.metricMetadata
            || this.metricMetadata.type === 'categorical') return [];

        const selectedLocation = this.parent.fipsMetadata?.name ?? '';
        const upLocation = this.upGeoLevelFipsMetadata?.name ?? '';
        const selectedData = numericalMetricData(this.metricDataByDate);
        const upData = numericalMetricData(this.metricDataByDateUpGeoLevel);

        if (selectedData.length === 0 && upData.length === 0) return [];

        if (!upLocation || selectedLocation === upLocation) {
            return [{
                area: true,
                color: this.secondaryColor,
                data: upData,
                location: upLocation
            }];
        }

        return [
            {
                color: this.primaryColor,
                data: selectedData,
                location: selectedLocation
            },
            {
                area: true,
                data: upData,
                location: upLocation
            }
        ];
    }

    @computed get heatmapData(): HeatmapData | null {
        if (!this.metricMetadata
            || this.metricMetadata.type !== 'categorical') return null;

        const data = categoricalMetricData(this.metricDataByDate);
        return {
            data,
            location: this.parent.fipsMetadata?.name ?? ''
        };
    }

    @computed get heatmapDateRange(): { start: Date; end: Date } | null {
        if (!this.heatmapData || this.heatmapData.data.length === 0) return null;

        const latestDate = _.sortBy(this.heatmapData.data, 'cdate')
            .slice(-1)[0].cdate;
        const firstDate = new Date(latestDate);
        firstDate.setMonth(firstDate.getMonth() - 6);
        firstDate.setDate(0);
        return { start: firstDate, end: latestDate };
    }

    @computed get heatmapExtent() {
        if (!this.metricMetadata
            || this.metricMetadata.type !== 'categorical') return null;

        return extentByValue(this.metricMetadata);
    }

    /* eslint-disable class-methods-use-this */
    @computed get formatX() {
        return utcFormat('%b%e');
    }

    @computed get formatY() {
        const metric = this.parent.metric;
        return metric?.format.bind(metric) ?? ((value: number) => format(value));
    }
}
