import { SearchResult } from '@/types';
import { GeoLevelMeta } from '../DataService';
import { fipsToAbbrev } from './local';

export function localSearchString(i: GeoLevelMeta): string {
    return `${i.name}, ${fipsToAbbrev[i.stateFIPS]}`.toLowerCase();
}

export function startsWithExactWord(i: GeoLevelMeta, query: string): boolean {
    if (!localSearchString(i).startsWith(query)) return false;

    const firstWord = query.split(/[ ,]/)[0];
    return localSearchString(i).startsWith(`${firstWord} `)
        || localSearchString(i).startsWith(`${firstWord},`);
}

export function resultIsState(result: SearchResult): boolean {
    return result.geoLevel === 'state'
        // special case: Washington DC is a county according to
        // Mapbox but we want it to act like a state
        || result.name.startsWith('Washington, District of Columbia');
}
