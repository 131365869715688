import { GeographyModel } from '@/model/GeographyModel';
import { Focus } from '@/model/GeographyModel/Geography';
import { AnyMetric } from '@/model/Metric';
import { DataService } from '@/services/DataService';
import { ParsedGeoLevel } from '@/services/DataService/parsers';
import { observable, ReactiveObject } from '@dha/vue-composition-decorators';
import { buildFilter, MapFilter } from '.';
import { append, replaceAtIndex } from './helpers';

export class FilterGroup extends ReactiveObject {
    dataService: DataService;
    geography: GeographyModel;
    @observable filters: MapFilter[] = [];

    constructor(dataService: DataService, geography: GeographyModel) {
        super();
        this.dataService = dataService;
        this.geography = geography;
    }

    async setFilterMetric(index: number, metric: AnyMetric) {
        this.filters = replaceAtIndex(
            this.filters,
            index,
            await this.buildFilter(metric)
        );
    }

    async setFilterValues(index: number, values: [number, number] | string[]) {
        this.filters[index].setValues(values as any);
    }

    async addFilter(metric: AnyMetric, initialValues?: [number, number] | string[]) {
        this.filters = append(this.filters, await this.buildFilter(metric, initialValues));
    }

    async updateFilterData(geoLevel: ParsedGeoLevel, focus: Focus, defaultFilterMetric: AnyMetric) {
        this.filters = await Promise.all(this.filters.map(async f => {
            if (!f.isGeoLevelAvailable(geoLevel)) {
                return this.buildFilter(
                    defaultFilterMetric
                );
            }
            await f.updateData(
                this.dataService,
                geoLevel,
                focus
            );
            return f;
        }));
    }

    private buildFilter(metric: AnyMetric, initialValues?: [number, number] | string[]) {
        return buildFilter(
            this.dataService,
            this.geography,
            metric,
            initialValues
        );
    }
}
