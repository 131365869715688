import _ from 'lodash';
import { ParsedGeoLevel } from '@/services/DataService/parsers';
import { observable, computed, ReactiveObject } from '@dha/vue-composition-decorators';
import { Option } from '@/types';
import { DataService, GeoLevelMeta } from '@/services/DataService';
import { Geography, Focus, focusOptionFromMeta, nationalGeoLevelMeta } from './Geography';
import { properCase } from './helpers';

export class StateGeography extends ReactiveObject implements Geography {
    dataService: DataService
    geoLevel = 'state' as const;
    @observable.ref metadata: GeoLevelMeta[] = [];
    @observable.ref focus: Focus;

    constructor(dataService: DataService) {
        super();
        this.dataService = dataService;
        this.focus = nationalGeoLevelMeta;
    }
    async init() {
        await this.fetchMetadata();
    }

    async fetchMetadata() {
        const meta = await this.dataService.getGeoLevelMeta('state');
        this.metadata = [
            nationalGeoLevelMeta,
            ...meta.map(({ name, ...rest }) => ({ name: properCase(name), ...rest }))
        ];
    }

    @computed get metadataByFipsCode() {
        return _.keyBy(this.metadata, 'fips');
    }

    // eslint-disable-next-line class-methods-use-this
    @computed get geoLevelOptions(): Option<ParsedGeoLevel>[] {
        return [{
            value: 'state',
            displayValue: 'States'
        }, {
            value: 'county',
            displayValue: 'Counties'
        }, {
            value: 'tract',
            displayValue: 'Tracts'
        }];
    }

    @computed get stateFocusOptions(): Option<Focus>[] {
        return this.metadata.map(focusOptionFromMeta);
    }

    get stateFocus() {
        return this.focus;
    }

    readonly countyFocusOptions: Option<Focus>[] = [];
    readonly countyFocus = undefined;

    getNextFipsCode(currentFipsMetadata: GeoLevelMeta): string {
        if (currentFipsMetadata.geoLevel === 'tract' || currentFipsMetadata.geoLevel === 'county') {
            return nationalGeoLevelMeta.fips;
        }
        return _.find(this.metadata, { stateFIPS: currentFipsMetadata.stateFIPS })?.fips
            ?? nationalGeoLevelMeta.fips;
    }

    // eslint-disable-next-line class-methods-use-this
    getUpLevelMetadata(): GeoLevelMeta {
        return nationalGeoLevelMeta;
    }

    async setFocusFipsCode(fips?: string): Promise<void> {
        const meta = _.find(this.metadata, { fips });
        this.focus = meta ? focusOptionFromMeta(meta).value : nationalGeoLevelMeta;
    }
}
