























import Vue, { PropType } from 'vue';
import DhaFilter from '@/components/Filter/Filter.vue';
import '@/assets/tailwind.css';
import { MapFilter } from '@/model/MapModel/Filter';
import { GeoLevelMeta } from '@/services/DataService';

export default Vue.extend({
    components: {
        DhaFilter
    },
    props: {
        filters: {
            type: Array as PropType<MapFilter[]>,
            required: true
        },
        filterMetricOptions: {
            type: Array as () => string[],
            required: true
        },
        selectedRegions: {
            type: Array as PropType<GeoLevelMeta[]>,
            required: true
        },
    },
    methods: {
        onMetricSelected(filterIndex: number, option: string) {
            this.$emit('selected', filterIndex, option);
        },
        onChange(filterIndex: number, values: [number, number] | string[]) {
            this.$emit('change', filterIndex, values);
        }
    }
});
