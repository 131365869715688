import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config';

const resolvedConfig = resolveConfig(tailwindConfig);

export const getTailwindColor = (color: string, shade: string | number) => {
    const colors = resolvedConfig.theme.colors;
    if (colors[color] && colors[color][Number(shade)]) {
        return colors[color][shade];
    }
    return null;
};

export const getTailwindFontSize = (sizeString: string) => {
    const sizes = resolvedConfig.theme.fontSize;
    return sizes[sizeString] ?? null;
};
