import { Layer } from '@dha/vue-mapbox-gl';
import { LineLayout } from 'mapbox-gl';

export const albersMetricDataLayer: Layer = {
    id: 'albersMetricData',
    source: 'albersMetricData',
    type: 'fill',
    paint: {
        'fill-color': ['get', 'color'],
        'fill-outline-color': ['get', 'outlineColor'],
        'fill-opacity': 1
    },
    hoverable: true,
    clickable: true,
    beforeId: 'state-boundaries'
};

export const mercatorMetricDataLayer: Layer = {
    id: 'mercatorMetricData',
    source: 'mercatorMetricData',
    type: 'fill',
    paint: {
        'fill-color': ['get', 'color'],
        'fill-outline-color': ['get', 'outlineColor'],
        'fill-opacity': 1
    },
    hoverable: true,
    clickable: true,
    beforeId: 'admin-3-4-boundaries'
};

export const selectedLayer: Layer = {
    id: 'selected',
    source: 'selected',
    type: 'line',
    paint: {
        'line-color': '#222',
        'line-width': 3
    },
    layout: {
        'line-cap': 'round'
    } as LineLayout
};

export const selectedOutlineLayer: Layer = {
    id: 'selectedOutline',
    source: 'selected',
    type: 'line',
    paint: {
        'line-color': '#fff',
        'line-width': 5
    },
    layout: {
        'line-cap': 'round'
    } as LineLayout
};
export const selectedOutlineShadowLayer: Layer = {
    id: 'selectedOutlineShadow',
    source: 'selected',
    type: 'line',
    paint: {
        'line-color': 'rgba(0, 0, 0, 0.25)',
        'line-width': 5,
        'line-opacity': 1,
        'line-blur': 3,
        'line-translate': [2, 4]
    },
    layout: {
        'line-cap': 'round'
    } as LineLayout
};

export const testSiteLayer: Layer = {
    id: 'testSites',
    source: 'testSites',
    type: 'circle',
    paint: {
        'circle-color': 'rgb(0, 0, 0)',
        'circle-radius': ['interpolate',
            ['exponential', 0.5],
            ['zoom'],
            1.5,
            1,
            6,
            3]
    }
};
