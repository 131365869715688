var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tooltip font-franklin"},[_c('div',{class:( _obj = {
            'tooltip-body': true
        }, _obj[_vm.align] = true, _obj[_vm.verticalAlign] = true, _obj ),style:({ transform: _vm.transform })},[(_vm.title)?_c('div',{staticClass:"title font-bold text-md text-surgodarkgreen-100"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.subtitle)?_c('div',{staticClass:"subtitle text-md text-surgodarkgreen-100"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e(),_vm._l((_vm.metricsData),function(metric){return _c('div',{key:metric.label,staticClass:"flex justify-between items-baseline text-surgodarkgreen-100 w-full",class:{
                'flex-col mt-2': !_vm.useRows,
                'flex-row': _vm.useRows,
            }},[_c('div',{staticClass:"metric-title",class:{
                    'font-bold': _vm.useRows && !metric.inactive,
                    'text-xs': !_vm.useRows,
                    'text-md': _vm.useRows
                }},[_vm._v(" "+_vm._s(metric.label)+" ")]),_c('div',{staticClass:"value text-left",class:{
                    'font-bold': !metric.inactive,
                    'text-md': metric.inactive,
                    'text-2lg': !metric.inactive
                },style:({ color: !metric.inactive ? metric.color : null })},[_vm._v(" "+_vm._s(metric.displayValue)+" ")]),_c('value-display',{staticClass:"mb-2 self-stretch",attrs:{"datum":metric,"show-labels":false,"active":true,"tile-height":7}})],1)}),_vm._l((_vm.extraMetricsFiltered),function(metric){return _c('div',{key:("extra-" + (metric.label))},[_c('value-display',{staticClass:"mt-2",attrs:{"datum":metric,"monocolor":true,"tile-height":5,"form-factor":'small'}})],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }