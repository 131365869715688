




















































import Vue, { PropType } from 'vue';
import _ from 'lodash';
import ResizeObserver from 'resize-observer-polyfill';
import { axisLeft, axisBottom } from 'd3-axis';
import { ScaleLinear, ScaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { utcFormat } from 'd3-time-format';

import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { ChartDrawInputs, BaseChartDatum, VueWithTypedRefs, BaseChartData, FormatOptions, ChartTooltipParams } from '@/types';
import { getXScale, getYScale } from './helpers';

export default (Vue as VueWithTypedRefs<{
    svg: typeof SVGElement;
    xAxis: typeof SVGGElement;
    yAxis: typeof SVGGElement;
    overlay: typeof HTMLDivElement;

}>).extend({
    components: {
        Tooltip
    },
    props: {
        data: {
            type: Array as () => BaseChartData[],
            default: () => []
        },
        leftMargin: {
            type: Number,
            default: 30
        },
        rightMargin: {
            type: Number,
            default: 0
        },
        topMargin: {
            type: Number,
            default: 10
        },
        bottomMargin: {
            type: Number,
            default: 40
        },
        formatX: {
            type: Function as PropType<(value: Date) => string>,
            default: d => utcFormat('%B')(d)
        },
        formatY: {
            type: Function as PropType<(value: number, options?: FormatOptions) => string>,
            default: d => String(d)
        },
        hoveredPoint: {
            type: Object as PropType<ChartTooltipParams<number>>,
            default: null
        }
    },
    data() {
        return {
            width: 0,
            height: 0,
            resizeObserver: null as ResizeObserver | null
        };
    },
    computed: {
        combinedData(): BaseChartDatum<number | null>[] {
            return ([] as BaseChartDatum<number | null>[]).concat(...this.data.map(({ data }) => data));
        },
        xScale(): ScaleTime<number, number> | null {
            if (!this.data) {
                return null;
            }
            return getXScale(this.combinedData, this.width, this.leftMargin, this.rightMargin);
        },
        yScale(): ScaleLinear<number, number> | null {
            if (!this.data) {
                return null;
            }
            return getYScale(this.combinedData, this.height, this.topMargin, this.bottomMargin);
        },
        drawInputs(): ChartDrawInputs {
            return {
                data: this.data,
                xScale: this.xScale,
                yScale: this.yScale
            };
        },
        tooltipTranslate(): string | null {
            if (!this.hoveredPoint || this.hoveredPoint.points.length === 0
                || !this.xScale || !this.yScale) {
                return null;
            }
            const x = this.xScale(this.hoveredPoint.points[0].x);
            const xMiddle = (this.xScale.range()[1] + this.xScale.range()[0]) / 2;
            const xTranslate = x > xMiddle ? '-100%' : '0';
            return `translateX(${xTranslate})`;
        },
        tooltipX(): string {
            if (!this.hoveredPoint || this.hoveredPoint.points.length === 0
                || !this.xScale || !this.yScale) {
                return '0';
            }
            return `${this.xScale(this.hoveredPoint.points[0].x)}px`;
        }
    },
    watch: {
        async drawInputs() {
            await this.$nextTick();
            this.draw();
        }
    },
    mounted() {
        this.resizeObserver = new ResizeObserver(() => {
            this.updateSize();
        });

        this.resizeObserver.observe(this.$el);
        this.updateSize();
    },
    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
    methods: {
        draw() {
            select(this.$refs.svg)
                .attr('width', this.width)
                .attr('height', this.height);
            this.drawXAxis();
            this.drawYAxis();
        },
        drawXAxis() {
            if (this.xScale) {
                const xAxis = axisBottom<Date>(this.xScale)
                    .tickFormat(d => this.formatX(d));

                if (this.$screens.mobile) {
                    xAxis.ticks(4);
                }
                select(this.$refs.xAxis).call(xAxis);
            }
        },
        drawYAxis() {
            if (this.xScale && this.yScale) {
                const yAxis = axisLeft(this.yScale)
                    .ticks(4)
                    .tickSize(this.xScale.range()[0] - this.xScale.range()[1]);
                const ticks = this.yScale.ticks(4)?.map(n => Number(n));
                yAxis.tickFormat(d => this.formatY(Number(d), { matchFormatList: ticks }));
                select(this.$refs.yAxis).call(yAxis);
            }
        },
        updateSize() {
            const { width, height } = this.$el.getBoundingClientRect();
            if (this.width !== width) {
                this.width = width;
            }
            if (this.height !== height) {
                this.height = height;
            }
        },
        onMousemove: _.throttle(function (this: any, event: MouseEvent) {
            if (this.xScale && this.yScale) {
                const {
                    top,
                    left
                } = this.$refs.overlay.getBoundingClientRect();

                this.$emit(
                    'hover',
                    this.xScale.invert(event.clientX - left),
                    this.yScale.invert(event.clientY - top)
                );
            }
        }, 15, { trailing: false }),
        onMouseout() {
            this.$emit('leave');
        }
    }
});
