

































































































































import Vue, { PropType } from 'vue';
import VueSlider from 'vue-slider-component';
import _ from 'lodash';
import { ScaleLinear, scaleLinear } from 'd3-scale';

import 'vue-slider-component/theme/default.css';
import '@/assets/tailwind.css';
import { NumericFilter } from '@/model/MapModel/Filter';
import { GeoLevelMeta } from '@/services/DataService';

export default Vue.extend({
    components: {
        VueSlider
    },
    props: {
        filter: {
            type: Object as PropType<NumericFilter & {
                selectedRegions: {title: string; value: number | null}[];
            }>,
            required: true,
        },
        selectedRegions: {
            type: Array as PropType<GeoLevelMeta[]>,
            required: true
        }
    },
    data() {
        return {
            tooltip: 'always',
        };
    },
    computed: {
        selectedRegionValues(): { name: string; value: number | null }[] {
            return this.selectedRegions.map(({ name, fips }) => ({
                name,
                value: this.filter.data[fips]
            }));
        },
        marks(): number[] {
            const marksScale = scaleLinear()
                .domain([0, 1])
                .range(this.filter.extent);
            return [0.2, 0.4, 0.6, 0.8].map(d => marksScale(d));
        },
        range(): number[] {
            return [this.filter.start, this.filter.end];
        },
        scale(): ScaleLinear<number, number> {
            return scaleLinear()
                .domain(this.filter.extent)
                .range([0, 100])
                .clamp(true);
        },
        min(): number {
            const min = _.min(Object.values(this.filter.data));
            return _.isNil(min) ? this.filter.extent[0] : min;
        },
        max(): number {
            const max = _.max(Object.values(this.filter.data));
            return _.isNil(max) ? this.filter.extent[1] : max;
        }
    },
    methods: {
        onChange(value: number): void {
            this.$emit('change', value);
        },
        showLessThan(value: number): boolean {
            return value === this.filter.extent[0] && this.min < this.filter.extent[0];
        },
        showGreaterThan(value: number): boolean {
            return value === this.filter.extent[1] && this.max > this.filter.extent[1];
        }
    }
});
