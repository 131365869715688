













import Vue from 'vue';
import DhaLoader from '@/components/Loader/Loader.vue';
import DhaButton from '@/components/Button/Button.vue';

export default Vue.extend({
    components: {
        DhaLoader,
        DhaButton
    },
    props: {
        canDismiss: {
            type: Boolean,
            default: true
        },
        onDismiss: {
            type: Function,
            default: null
        },
        text: {
            type: String,
            default: 'Explore'
        }
    }
});
