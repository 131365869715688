











































































































import Vue from 'vue';
import DhaOverlay from '@/components/Overlay/Overlay.vue';
import OverlayLinks from '@/components/Overlay/OverlayLinks.vue';
import ButtonContainer from '@/components/Overlay/ButtonContainer.vue';
import { TextLink } from '@/types';
import { defaultParagraph, defaultTitle } from './constants';

export default Vue.extend({
    components: {
        DhaOverlay,
        OverlayLinks,
        ButtonContainer
    },
    props: {
        value: {
            type: Boolean,
            default: true
        },
        canDismiss: {
            type: Boolean,
            required: true
        },
        autoExplore: {
            type: Boolean,
            required: true,
        }
    },
    computed: {
        title(): string {
            const metaTitle = this.$appModel.metadata?.loadingOverlayText.title;
            if (metaTitle !== undefined) {
                return metaTitle !== '' ? metaTitle : defaultTitle;
            }
            return '';
        },
        paragraph(): string {
            const metaParagraph = this.$appModel.metadata?.loadingOverlayText.paragraph;
            if (metaParagraph !== undefined) {
                return metaParagraph !== '' ? metaParagraph : defaultParagraph;
            }
            return '';
        },
        links(): TextLink[] {
            return this.$appModel.splashScreenLinks;
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(value: boolean) {
                const html = document.querySelector('html');
                if (html) {
                    html.scrollTop = 0;
                    html.scrollLeft = 0;
                    html.style.overflowY = value
                        ? 'hidden'
                        : 'auto';

                    if (window.history) {
                        window.history.scrollRestoration = 'manual';
                    }
                }
            }
        },
        canDismiss(dismiss: boolean) {
            if (dismiss && this.autoExplore) {
                this.onDismiss();
            }
        }
    },
    mounted() {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
    },
    beforeDestroy() {
        document.body.style.removeProperty('overflow');
        document.body.style.removeProperty('position');
    },
    methods: {
        onDismiss() {
            this.$emit('input', false);
        }
    }
});
