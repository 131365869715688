





























import Vue from 'vue';

import ButtonSelect from '@/components/ButtonSelect/ButtonSelect.vue';
import FocusControls from '@/views/FocusControls/FocusControls.vue';

import { Option } from '@/types';
import { ParsedGeoLevel } from '@/services/DataService/parsers';

export default Vue.extend({
    components: {
        ButtonSelect,
        FocusControls
    },
    computed: {
        geoLevelOptions(): Option[] {
            return this.$appModel.geoLevelOptions;
        },
        geoLevel(): string {
            return this.$appModel.geographyModel.geoLevel;
        },
    },
    methods: {
        onGeoLevelSelected(option: Option<ParsedGeoLevel>): void {
            // this.$appModel.geographyModel.setGeoLevel(option.value);
            this.$appModel.setGeoLevel(option.value);
        }
    }
});
