import _ from 'lodash';
import { extent } from 'd3-array';
import { scaleUtc } from 'd3-scale';
import { HeatmapDatum } from '@/types';

export const getXScale = (
    data: HeatmapDatum[],
    scaleExtent: { start: Date; end: Date } | undefined,
    width: number,
    leftMargin: number,
    rightMargin: number
) => {
    if (data.length < 2) {
        return null;
    }
    const domain = extent(data, ({ cdate }) => cdate);
    if (_.compact(domain).length < 2) return null;
    return scaleUtc()
        .domain(scaleExtent ? [scaleExtent.start, scaleExtent.end] : domain as [Date, Date])
        .range([leftMargin, width - rightMargin]);
};
