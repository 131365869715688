



























































import Vue from 'vue';

import ShareButtons from '@/components/ShareButtons/ShareButtons.vue';
import { TextLink } from '@/types';

export default Vue.extend({
    components: {
        ShareButtons
    },
    computed: {
        links(): TextLink[] {
            return this.$appModel.textLinks;
        }
    },
    methods: {
        onSocialButtonClicked(socialType: string) {
            this.$emit('social-button-click', socialType);
        }
    }
});
