




































































import Vue, { PropType } from 'vue';
import ResizeObserver from 'resize-observer-polyfill';
import ValueDisplay from '@/components/ValueDisplay/ValueDisplay.vue';
import { AccordionDatum, AccordionGroup, VueWithTypedRefs } from '@/types';
import AccordionMetric from './AccordionMetric.vue';

export default (Vue as VueWithTypedRefs<{
    inner: typeof HTMLDivElement;
}>).extend({
    components: {
        AccordionMetric,
        ValueDisplay
    },
    props: {
        content: {
            type: Object as PropType<AccordionGroup>,
            required: true
        },
        selectedRegion: {
            type: String,
            default: 'United States'
        },
        selectedMetric: {
            type: String,
            required: true
        },
        open: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            resizeObserver: null as ResizeObserver | null,
            height: '0px'
        };
    },
    computed: {

    },
    watch: {
        open() {
            this.setHeight();
        }
    },
    mounted() {
        this.resizeObserver = new ResizeObserver(() => {
            this.setHeight();
        });

        this.resizeObserver.observe(this.$refs.inner);
    },
    methods: {
        getSubtitles(metric: AccordionDatum): string[] {
            return metric.subtitles ?? [];
        },
        setHeight() {
            if (!this.open || !this.$refs.inner) {
                this.height = '0';
            } else {
                this.height = `${this.$refs.inner.getBoundingClientRect().height}px`;
            }
        },
        onHeaderClick() {
            if (!this.open) {
                this.$emit('group', this.content.id);
            }
        },
        onMetricClick(metricId: string) {
            this.$emit('metric', metricId);
        }
    }
});
