



























import Vue from 'vue';
import MenuAnchor from '@/components/MenuAnchor/MenuAnchor.vue';

export default Vue.extend({
    components: {
        MenuAnchor
    },
    props: {
        menuIsOpen: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        logo(): string {
            return this.$screens.mobile
                ? 'surgo_logo.svg'
                : 'surgo_logo_precision.svg';
        }
    },
    methods: {
        onMenuInput(value: boolean) {
            this.$emit('input:menu', value);
        }
    }
});
