import _ from 'lodash';
import { observable, ReactiveObject } from '@dha/vue-composition-decorators';

import { NumericMetric } from '@/model/Metric';
import { ParsedGeoLevel } from '@/services/DataService/parsers';
import { DataService } from '@/services/DataService';
import { GeographyModel } from '@/model/GeographyModel';
import { Focus } from '@/model/GeographyModel/Geography';

import { BaseFilter } from './BaseFilter';

export class NumericFilter extends ReactiveObject implements BaseFilter {
    type = 'numeric' as const;
    private metric: NumericMetric;
    extent: number[];
    interval: number;
    percentage: boolean;
    showLabels: boolean;

    @observable.ref data: Record<string, number | null>;
    @observable start: number;
    @observable end: number;

    private constructor(
        metric: NumericMetric,
        geoLevel: ParsedGeoLevel,
        data: Record<string, number | null>,
        initialValues?: [number, number]
    ) {
        super();

        this.metric = metric;
        this.data = data;
        this.extent = metric.getDomain(geoLevel);

        this.start = initialValues
            ? Math.max(initialValues[0], this.extent[0])
            : this.extent[0];
        this.end = initialValues
            ? Math.min(initialValues[1], this.extent[1])
            : this.extent[1];

        this.interval = 0.01;
        this.percentage = metric.metadata.type === 'percent';
        this.showLabels = metric.metadata.groupingId === 'vulnerability';
    }

    get metricId() {
        return this.metric.metadata.metricId;
    }

    get values(): [number, number] {
        return [this.start, this.end];
    }

    isGeoLevelAvailable(geoLevel: ParsedGeoLevel): boolean {
        return this.metric.isGeoLevelAvailable(geoLevel);
    }
    includes(fipsCode: string) {
        const value = this.data[fipsCode];
        const filterStart = this.start === this.extent[0] ? Number.NEGATIVE_INFINITY : this.start;
        const filterEnd = this.end === this.extent[1] ? Number.POSITIVE_INFINITY : this.end;
        if (_.isNil(value)) {
            return !(this.start === this.extent[0] && this.end === this.extent[1]);
        }
        return value >= filterStart && value <= filterEnd;
    }

    setValues(values: [number, number]) {
        this.start = values[0];
        this.end = values[1];
    }

    async updateData(dataService: DataService, geoLevel: ParsedGeoLevel, focus: Focus) {
        this.data = await NumericFilter.fetchData(
            dataService,
            this.metricId,
            geoLevel,
            focus
        );
    }

    static async new(
        dataService: DataService,
        geography: GeographyModel,
        metric: NumericMetric,
        initialValues?: [number, number]
    ): Promise<NumericFilter> {
        const geoLevel = geography.geoLevel;
        const focus = geography.focus;
        const data = await NumericFilter.fetchData(
            dataService,
            metric.metadata.metricId,
            geoLevel,
            focus
        );

        return new NumericFilter(
            metric,
            geoLevel,
            data,
            initialValues
        );
    }

    private static async fetchData(
        dataService: DataService,
        metricId: string,
        geoLevel: ParsedGeoLevel,
        focus: Focus
    ) {
        const [
            data,
            focusData
        ] = await Promise.all([
            dataService.getLatestMetricData(
                metricId,
                {
                    geoLevel,
                    fipsCode: focus.fips,
                    filterGeoLevel: focus.geoLevel
                }
            ),
            dataService.getAllMetricsValues(
                focus.fips,
                focus.geoLevel
            )
        ]);

        return {
            ..._(data)
                .keyBy('fips')
                .mapValues('value')
                .value(),
            [focus.fips]: focusData[metricId]
        } as Record<string, number | null>; // TODO: Figure out how to type this
    }

    isFiltered(): boolean {
        return this.start !== this.extent[0] || this.end !== this.extent[1];
    }

    clear() {
        this.start = this.extent[0];
        this.end = this.extent[1];
    }
}
