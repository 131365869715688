import _ from 'lodash';
import { ParsedGeoLevel, ParsedMetricMetadata } from '@/services/DataService/parsers';
import { scaleOrdinal } from 'd3-scale';
import { isEnabled } from '@dha/feature-toggles';
import { LegendValue, Metric } from './Metric';
import { extentByValue } from '../helpers';

type CategoricalMetricMetadata = ParsedMetricMetadata & { type: 'categorical' };

export class CategoricalMetric extends Metric {
    kind = 'categorical' as const;
    metadata: CategoricalMetricMetadata;
    quantizeBuckets: number;

    constructor(metadata: CategoricalMetricMetadata) {
        // redundant constructor but needed to get types to check properly
        super(metadata);
        this.metadata = metadata;
        this.quantizeBuckets = this.getDomain().length;
    }

    get quantizeColors() {
        return this.metadata.colors;
    }

    getDomain() {
        return this.metadata.extentOptions.extent;
    }

    getLegendValues() {
        return _(extentByValue(this.metadata))
            .values()
            .map(v => ({
                kind: 'categorical',
                color: v.color,
                label: v.label
            } as LegendValue))
            .value();
    }

    getColorScale() {
        return scaleOrdinal<string, string, string>()
            .domain(this.metadata.extentOptions.extent)
            .range(this.metadata.colors)
            .unknown('#eee');
    }

    getQuantizeScale() {
        return scaleOrdinal<string, number, number>()
            .domain(this.metadata.extentOptions.extent)
            .range(this.metadata.extentOptions.extent.map((v, i) => i))
            .unknown(-1);
    }

    // eslint-disable-next-line class-methods-use-this
    valuesBelowExtent(): boolean {
        return false;
    }
    // eslint-disable-next-line class-methods-use-this
    valuesAboveExtent(): boolean {
        return false;
    }
    getDisplayValue(value: number | string | null) {
        const index = this.metadata.extentOptions.extent.indexOf(String(value));
        return this.metadata.displayValues[index];
    }
    getColor(geoLevel: ParsedGeoLevel, value: string | null) {
        // Much easier to see that shapes are there, just missing data
        // when they're not invisible
        const missingDataColor = isEnabled('auditMissingShapes')
            ? 'magenta'
            : 'rgba(255, 255, 255, 0)';

        const colorScale = this.getColorScale();
        return _.isNil(value) ? missingDataColor : colorScale(value);
    }
}
