






















































































import Vue, { PropType } from 'vue';
import { select } from 'd3-selection';
import { TutorialEntry, VueWithTypedRefs } from '@/types';

import DhaButton from '@/components/Button/Button.vue';

type ComponentData = {
    currentIndex: number;
    exiting: boolean;
    resizeTimer: number | null;
    noShowChecked: boolean;
};

export default (Vue as VueWithTypedRefs<{
    controls: typeof HTMLDivElement;
    'guide-rect': typeof SVGRectElement;
}>).extend({
    components: {
        DhaButton,
    },
    props: {
        tutorialSequence: {
            type: Array as PropType<TutorialEntry[]>,
            required: true
        },
    },
    data(): ComponentData {
        return {
            currentIndex: 0,
            exiting: false,
            resizeTimer: null,
            noShowChecked: false,
        };
    },
    computed: {
        currentEntry(): TutorialEntry {
            return this.tutorialSequence[this.currentIndex];
        },
        currentInstruction(): string {
            return (this.$screens.mobile && this.currentEntry.altInstruction)
                ? this.generateInstructionHtml(this.currentEntry.altInstruction)
                : this.generateInstructionHtml(this.currentEntry.instruction);
        },
        guidePadding(): { x: number; y: number } {
            return (this.$screens.mobile) ? { x: 6, y: 4 } : { x: 10, y: 10 };
        }
    },
    mounted() {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        window.addEventListener('resize', this.onResize);

        // On mobile, force the first tutorial step to scroll passed the title bar
        if (this.$screens.mobile) {
            const element = document.querySelector(this.currentEntry.selector) as HTMLElement;
            const rect = element.getBoundingClientRect() as ClientRect;
            document.body.scrollBy({
                top: this.calculateMaskDimensions(rect)[3] - this.guidePadding.y,
                behavior: 'smooth'
            });
            this.$refs['guide-rect'].classList.add('transitioning');
            this.updateMask(element);
        } else {
            this.highlight(this.currentEntry.selector, false);
        }
    },
    beforeDestroy() {
        document.body.style.removeProperty('overflow');
        document.body.style.removeProperty('position');
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            if (this.resizeTimer !== null) {
                window.clearTimeout(this.resizeTimer);
            }
            this.resizeTimer = window.setTimeout(() => {
                this.highlight(this.currentEntry.selector, true);
            }, 250);
        },
        highlight(selector: string, transition = true) {
            const element = document.querySelector(selector) as HTMLElement;

            if (transition) {
                this.$refs['guide-rect'].classList.add('transitioning');
                const rect = element.getBoundingClientRect() as ClientRect;
                const [, height,, top] = this.calculateMaskDimensions(rect);

                if (this.$screens.mobile || window.innerHeight < 640) {
                    const usableHeight = window.innerHeight
                        - this.$refs.controls.getBoundingClientRect().height;
                    if (height < usableHeight) {
                        // If the element fits in the available space, center it
                        document.body.scrollBy({
                            top: top - ((usableHeight - height) / 2),
                            behavior: 'smooth'
                        });
                    } else {
                        // Otherwise, scroll it to the top so most of it can be visible
                        document.body.scrollBy({
                            top: top - this.guidePadding.y,
                            behavior: 'smooth'
                        });
                    }
                } else {
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });
                }
            }
            this.updateMask(element, transition);
        },
        calculateMaskDimensions(rect: ClientRect): [number, number, number, number] {
            let width = rect.width + (2 * this.guidePadding.x);
            const height = rect.height + (2 * this.guidePadding.y);
            let left = rect.left - this.guidePadding.x;
            const top = rect.top - this.guidePadding.y;
            if (width > window.innerWidth) {
                width = window.innerWidth - (2 * this.guidePadding.x);
                left = this.guidePadding.x;
            }
            return [width, height, left, top];
        },
        updateMask(element: HTMLElement, transition = true) {
            // Note: This assumes the element will be within the viewport within 750ms
            window.setTimeout(() => {
                const rect = element.getBoundingClientRect() as ClientRect;
                const [width, height, left, top] = this.calculateMaskDimensions(rect);
                select('.guide-rect')
                    .attr('width', `${width}px`)
                    .attr('height', `${height}px`)
                    .attr('x', `${left}px`)
                    .attr('y', `${top}px`)
                    .attr('rx', '10px')
                    .attr('ry', '10px');
                this.$refs['guide-rect'].classList.remove('transitioning');
            }, transition ? 750 : 0);
        },
        generateInstructionHtml(instruction: string): string {
            const classes = 'font-code font-bold text-md text-surgodarkgreen-100 tracking-wider uppercase';
            return instruction.replaceAll(/\[\[([^\]]+)]]/g, `<span class="${classes}">$1</span>`);
        },
        next() {
            if (this.exiting) return;
            this.currentIndex++;
            this.highlight(this.currentEntry.selector);
        },
        back() {
            if (this.exiting) return;
            this.currentIndex--;
            this.highlight(this.currentEntry.selector);
        },
        exit() {
            if (this.exiting) return;
            this.exiting = true;
            this.$refs['guide-rect'].classList.add('transitioning');
            if (document.body.scrollTop === 0) {
                this.$emit('exit', this.noShowChecked);
            } else {
                document.body.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
                window.setTimeout(() => this.$emit('exit', this.noShowChecked), 750);
            }
        },
        toggleNoShow() {
            this.noShowChecked = !this.noShowChecked;
        }
    }
});
