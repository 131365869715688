import _ from 'lodash';
import { ParsedGeoLevel, ParsedMetricMetadata } from '@/services/DataService/parsers';
import { scaleQuantize } from 'd3-scale';
import { isEnabled } from '@dha/feature-toggles';
import { LegendValue, Metric } from './Metric';

type NumericMetricMetadata = ParsedMetricMetadata & { type: 'nominal' | 'percent' };

export class NumericMetric extends Metric {
    kind = 'numeric' as const;
    metadata: NumericMetricMetadata;

    constructor(metadata: NumericMetricMetadata) {
        // redundant constructor but needed to get types to check properly
        super(metadata);
        this.metadata = metadata;
    }

    // eslint-disable-next-line class-methods-use-this
    getDomain(geoLevel: ParsedGeoLevel): [number, number] {
        const extentOptions = this.metadata.extentOptions;
        return extentOptions.kind === 'static'
            ? extentOptions.extent
            : extentOptions.extentByGeoLevel[geoLevel];
    }

    getLegendValues(geoLevel: ParsedGeoLevel) {
        const colorScale = this.getColorScale(geoLevel);

        const domain = colorScale.domain();
        const thresholds: (number | null)[] = [
            domain[0],
            // thresholds() exists in d3-scale but not @types/d3-scale...
            // PR is currently open: https://github.com/DefinitelyTyped/DefinitelyTyped/pull/49665
            // TODO: Remove type shim when the PR is merged
            ...(colorScale as unknown as {thresholds: () => number[]}).thresholds(),
            domain[1]
        ];

        const matchFormatList = thresholds.map(threshold => threshold ?? 0);

        return colorScale.range().map((color, i) => ({
            kind: 'threshold',
            color,
            // For n colors, there are n+1 thresholds
            // color i is for the range from threshold i to threshold i + 1
            labels: [
                this.format(thresholds[i], { matchFormatList }),
                this.format(thresholds[i + 1], { matchFormatList })
            ]
        } as LegendValue));
    }

    getColorScale(geoLevel: ParsedGeoLevel) {
        const colors = this.metadata.colors;

        return scaleQuantize<string, string>()
            .domain(this.getDomain(geoLevel))
            .range(colors)
            .unknown('magenta');
    }

    // eslint-disable-next-line class-methods-use-this
    getQuantizeScale(geoLevel: ParsedGeoLevel) {
        return scaleQuantize()
            .domain(this.getDomain(geoLevel))
            .range([0, 1, 2, 3, 4]);
    }
    readonly quantizeBuckets = 5;
    get quantizeColors() {
        return Array.from({ length: this.quantizeBuckets }).fill(0)
            .map(() => this.metadata.color);
    }

    valuesBelowExtent(geoLevel: ParsedGeoLevel, values: (number | string | null)[]): boolean {
        const domain = this.getDomain(geoLevel);
        return _.some(values, v => typeof v === 'number' && v < domain[0]);
    }
    valuesAboveExtent(geoLevel: ParsedGeoLevel, values: (number | string | null)[]): boolean {
        const domain = this.getDomain(geoLevel);
        return _.some(values, v => typeof v === 'number' && v > domain[1]);
    }
    getDisplayValue(value: number | string | null) {
        return this.format(value);
    }
    getColor(geoLevel: ParsedGeoLevel, value: number | null) {
        // Much easier to see that shapes are there, just missing data
        // when they're not invisible
        const missingDataColor = isEnabled('auditMissingShapes')
            ? 'magenta'
            : 'rgba(255, 255, 255, 0)';

        const colorScale = this.getColorScale(geoLevel);
        return _.isNil(value) ? missingDataColor : colorScale(value);
    }
}
