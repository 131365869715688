import _ from 'lodash';
import * as geobuf from 'geobuf';
import Pbf from 'pbf';
import {
    ParsedAllMetricsData,
    ParsedGeojsonFeatureCollection,
    ParsedGeoLevel,
    ParsedGeoLevelMeta,
    ParsedMetadata,
    ParsedMetricByFipsData,
    ParsedMetricByDateData,
    ParsedMetricMetadata,
    ParsedGroupedMetricData,
    ParsedTestLocation
} from '../parsers';
import { DataSource, GeoLevelFilter, QueryLatestFilters, QueryForFipsFilters, QueryGroupedFilters } from './DataSource';

export class ApiDataSource implements DataSource {
    apiUrl: string;
    metadata?: ParsedMetadata;

    private geojsonCache: Record<string, ParsedGeojsonFeatureCollection> = {};

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }

    async init() {
        this.metadata = await this.fetchAndBuildMetadata();
    }

    private async getQueryResult<T>(query: string, params: Record<string, any> = {}): Promise<T[]> {
        const search = new URLSearchParams(params);
        const searchString = search.toString() ? `?${search.toString()}` : '';
        const request = await fetch(`${this.apiUrl}/query/data/${query}${searchString}`);
        const rows = (await request.json()).rows;
        return rows;
    }
    // eslint-disable-next-line class-methods-use-this
    private async getJsonFile<T>(file: string): Promise<T> {
        const request = await fetch(file);
        return request.json();
    }

    // eslint-disable-next-line class-methods-use-this
    private async getPbfFile(file: string): Promise<ArrayBufferLike> {
        const request = await fetch(file);
        if (file.endsWith('.b64.pbf')) {
            const binaryString = window.atob(await request.text());
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes.buffer;
        }
        return request.arrayBuffer();
    }

    async getGeoLevelMeta(level: ParsedGeoLevel, filter?: GeoLevelFilter) {
        if (filter && filter.geoLevel !== 'national') {
            return this.getQueryResult<ParsedGeoLevelMeta>(
                `GetBy${_.upperFirst(filter.geoLevel)}Fips${_.upperFirst(level)}Metadata`,
                { FIPSCode: filter.fipsCode }
            );
        }
        return this.getQueryResult<ParsedGeoLevelMeta>(`Get${_.upperFirst(level)}Metadata`);
    }
    async getTestLocations(filter: GeoLevelFilter) {
        if (!this.metadata) {
            return [];
        }
        const query = this.metadata?.queries.testLocations[filter.geoLevel];
        if (!query) {
            return [];
        }

        const { geoLevel, fipsCode } = filter;

        return this.getQueryResult<ParsedTestLocation>(query, geoLevel !== 'national' ? { FIPSCode: fipsCode } : undefined);
    }

    // TODO: Think through the API design of this once we have all different requests
    // implemented (temporal vs. not, all fips for date, all dates for fips, etc)
    async getLatestMetricData(
        metadata: ParsedMetricMetadata,
        filters: QueryLatestFilters
    ) {
        const { geoLevel, fipsCode, filterGeoLevel } = filters;
        const dataSource = metadata.dataSources[geoLevel];
        if (!dataSource.available) {
            return [];
        }

        const { queries: { byFips: queries } } = dataSource;

        if (filterGeoLevel !== 'national' && fipsCode && filterGeoLevel !== geoLevel) {
            const query = queries?.[filterGeoLevel];
            if (!query) {
                return [];
            }
            return this.getQueryResult<ParsedMetricByFipsData>(query, { FIPSCode: fipsCode });
        }

        if (!queries?.all) {
            return [];
        }
        return this.getQueryResult<ParsedMetricByFipsData>(queries.all);
    }

    async getMetricDataForFips(
        metadata: ParsedMetricMetadata,
        filters: QueryForFipsFilters
    ) {
        const { geoLevel, fipsCode } = filters;
        const dataSource = metadata.dataSources[geoLevel];
        if (!dataSource.available) {
            return [];
        }
        const { queries: { byDate: query } } = dataSource;
        if (!query) {
            return [];
        }

        return this.getQueryResult<ParsedMetricByDateData>(query, geoLevel !== 'national' ? { FIPSCode: fipsCode } : undefined);
    }

    async getGroupedMetricDataForDateFips(
        metadata: ParsedMetricMetadata,
        filters: QueryGroupedFilters
    ) {
        const { geoLevel, fipsCode } = filters;
        const dataSource = metadata.dataSources[geoLevel];
        const { queries: { byGroup: query } } = dataSource;

        if (!dataSource.available || !query) {
            return [];
        }

        return this.getQueryResult<ParsedGroupedMetricData>(query, geoLevel !== 'national' ? { FIPSCode: fipsCode } : undefined);
    }

    getAllMetricsData(
        fips: string,
        geoLevel: ParsedGeoLevel
    ): Promise<ParsedAllMetricsData[]> {
        const query = this.metadata?.queries.allMetrics[geoLevel];
        if (!query) {
            throw new Error('Cant query allMetrics before ApiDataSource has been initialized');
        }
        return this.getQueryResult<ParsedAllMetricsData>(query, geoLevel !== 'national' ? { FIPSCode: fips } : undefined);
    }

    async getGeojson(geoLevel: ParsedGeoLevel, focus: {geoLevel: ParsedGeoLevel; fipsCode: string}) {
        const key = focus.geoLevel === 'national'
            ? `${geoLevel}-albersusa`
            : (geoLevel === 'county' ? `${geoLevel}-${focus.fipsCode.slice(0, 2)}` : `${geoLevel}-${focus.fipsCode}`);

        if (!this.geojsonCache[key]) {
            this.geojsonCache[key] = await this.getJsonFile<ParsedGeojsonFeatureCollection>(`data/geojson/${key}.geojson`);
        }
        if (focus.geoLevel === geoLevel) {
            const { features, ...meta } = this.geojsonCache[key];
            return {
                ...meta,
                features: features.filter(f => f.properties.fips === focus.fipsCode)
            };
        }
        return this.geojsonCache[key];
    }

    private async fetchAndBuildMetadata(): Promise<ParsedMetadata> {
        const metadata = await this.getJsonFile<ParsedMetadata>('metadata.json');
        const extentQueries = [
            { geoLevel: 'state', query: metadata.queries.extent.state },
            { geoLevel: 'county', query: metadata.queries.extent.county },
            { geoLevel: 'tract', query: metadata.queries.extent.tract }
        ].filter(({ query }) => query !== '');
        const metricExtents = _(await Promise.all(extentQueries.map(
            async ({ geoLevel, query }) => ({
                geoLevel,
                extentByMetricId: await this.getQueryResult(query)
                    .then(result => _(result as any[])
                        .keyBy('metric')
                        .mapValues(({ min, max }) => [+min, +max] as [number, number])
                        .value())
            })
        ))).flatMap(({ geoLevel, extentByMetricId }) => _.flatMap(
            extentByMetricId,
            (extent, metricId) => ({ geoLevel, metricId, extent })
        )).groupBy('metricId')
            .mapValues(arr => _(arr).keyBy('geoLevel').mapValues('extent').value())
            .value();

        return {
            ...metadata,
            metricsByGrouping: _.map(
                metadata.metricsByGrouping,
                group => ({
                    ...group,
                    metrics: group.metrics.map(metric => {
                        if (metric.type === 'categorical') {
                            // No automatic extents
                            return metric;
                        }
                        return {
                            ...metric,
                            extentOptions: metric.extentOptions.kind === 'dynamic' ? {
                                kind: 'dynamic' as 'dynamic',
                                extentByGeoLevel: {
                                    national: metricExtents[metric.metricId].national ?? [0, 1],
                                    state: metricExtents[metric.metricId].state ?? [0, 1],
                                    county: metricExtents[metric.metricId].county ?? [0, 1],
                                    tract: metricExtents[metric.metricId].tract ?? [0, 1]
                                }
                            } : metric.extentOptions
                        };
                    })
                })
            )
        };
    }

    async getMetadata() {
        if (!this.metadata) {
            return this.fetchAndBuildMetadata();
        }
        return this.metadata;
    }
}
