











































import Vue from 'vue';

import FocusControls from '@/views/FocusControls/FocusControls.vue';
import DhaSection from '@/components/Section/Section.vue';
import AreaChart from '@/components/AreaChart/AreaChart.vue';
import Heatmap from '@/components/Heatmap/Heatmap.vue';
import { AreaChartData, ExtentValue, HeatmapData } from '@/types';

export default Vue.extend({
    components: {
        FocusControls,
        DhaSection,
        AreaChart,
        Heatmap
    },
    computed: {
        dataError(): boolean {
            return this.$appModel.temporalDataModel.dataError;
        },
        areaChartData(): AreaChartData[] {
            return this.$appModel.temporalDataModel.areaData;
        },
        hasAreaData(): boolean {
            return this.areaChartData.length > 0;
        },
        heatmapData(): HeatmapData | null {
            return this.$appModel.temporalDataModel.heatmapData;
        },
        hasHeatmapData(): boolean {
            return !!this.heatmapData && this.heatmapData.data.length > 0;
        },
        title(): string {
            return this.$appModel.temporalDataModel.title;
        },
        metricTitle(): string {
            return this.$appModel.temporalDataModel.metricTitle;
        },
        description(): string {
            return this.$appModel.temporalDataModel.description;
        },
        primaryColor(): string {
            return this.$appModel.temporalDataModel.primaryColor;
        },
        secondaryColor(): string {
            return this.$appModel.temporalDataModel.secondaryColor;
        },
        heatmapDateRange(): { start: Date; end: Date } | null {
            return this.$appModel.temporalDataModel.heatmapDateRange;
        },
        heatmapExtent(): Record<string, ExtentValue> | null {
            return this.$appModel.temporalDataModel.heatmapExtent;
        },
        formatX(): (date: Date) => string {
            return this.$appModel.temporalDataModel.formatX;
        },
        formatY(): (value: number) => string {
            return this.$appModel.temporalDataModel.formatY;
        }
    }
});
