import { ParsedGeoLevel, ParsedMetricMetadata } from '@/services/DataService/parsers';
import format from '@dha/number-format';
import { ScaleOrdinal, ScaleQuantize } from 'd3-scale';
import { FormatOptions } from '@/types';

export type ColorScale = ScaleQuantize<string, string> | ScaleOrdinal<string, string, string>;
export type QuantizeScale = ScaleQuantize<number> | ScaleOrdinal<string, number, number>;

export type CategoricalLegendValue = {
    kind: 'categorical';
    label: string;
    color: string;

};
export type ThresholdLegendValue = {
    kind: 'threshold';
    labels: [string, string];
    color: string;
};
export type LegendValue = CategoricalLegendValue | ThresholdLegendValue;
export abstract class Metric {
    metadata: ParsedMetricMetadata;

    constructor(metadata: ParsedMetricMetadata) {
        this.metadata = metadata;
    }

    format(value: number | string | null, options?: FormatOptions) {
        if (value === null || value === undefined) {
            return 'NA';
        }
        return format(value, {
            type: this.metadata.type,
            units: this.metadata.units,
            ...options
        });
    }

    groupedFormat(value: number | string | null, options?: FormatOptions) {
        return this.format(value, {
            type: this.metadata.groupedType || this.metadata.type,
            ...options
        });
    }

    get title() {
        const metaTitle = this.metadata.sectionTitles.mapTitle;
        return metaTitle !== ''
            ? metaTitle
            : (this.metadata.label ?? this.metadata.metricId);
    }
    get description() {
        return this.metadata?.textBlocks[0] || '';
    }

    isGeoLevelAvailable(geoLevel: ParsedGeoLevel): boolean {
        return this.metadata.dataSources[geoLevel].available;
    }

    abstract quantizeBuckets: number;
    abstract quantizeColors: string[];
    abstract getDomain(geoLevel: ParsedGeoLevel): [number, number] | string[];
    abstract getLegendValues(geoLevel: ParsedGeoLevel): LegendValue[];
    abstract getColorScale(geoLevel: ParsedGeoLevel): ColorScale;
    abstract getQuantizeScale(geoLevel: ParsedGeoLevel): QuantizeScale;

    abstract valuesBelowExtent(geoLevel: ParsedGeoLevel, values: (number | string | null)[]): boolean;
    abstract valuesAboveExtent(geoLevel: ParsedGeoLevel, values: (number | string | null)[]): boolean;
    abstract getDisplayValue(value: number | string | null): string;
    abstract getColor(geoLevel: ParsedGeoLevel, value: number | string | null): string;
}
