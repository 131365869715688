import { DataService } from '@/services/DataService';
import { ParsedGeoLevel, ParsedGeoLevelMeta, ParsedMetadata, ParsedMetricByFipsData } from '@/services/DataService/parsers';
import { AccordionGroup, CheckboxMetric, Option } from '@/types';
import { observable, computed, ReactiveObject } from '@dha/vue-composition-decorators';
import { ScaleQuantize, ScaleOrdinal } from 'd3-scale';
import _ from 'lodash';
import { GeographyModel } from './GeographyModel';
import { Metric } from './Metric';

type QuantizeScale = ScaleQuantize<number> | ScaleOrdinal<string, number, number>;
export interface AccordionModelParent {
    isLoaded: boolean;
    baseMetricId: string;
    validCheckCombination: CheckboxMetric | undefined;
    geoLevel: ParsedGeoLevel;
    fipsCode: string;
    metadata?: ParsedMetadata;
    metricById: Record<string, Metric>;
    metric: Metric;
    fipsMetadata?: ParsedGeoLevelMeta;
    geographyModel: GeographyModel;
    setMetricId: (metricId: string) => void;
}

export class AccordionModel extends ReactiveObject {
    dataService: DataService;
    parent: AccordionModelParent;

    @observable.ref values: Record<string, string | number | null> = {};
    @observable accordionData: Record<string, ParsedMetricByFipsData[]> = {};

    constructor(dataService: DataService, parent: AccordionModelParent) {
        super();
        this.dataService = dataService;
        this.parent = parent;
    }

    async init() {
        await this.updateData();
        const accordionData = await this.getAccordionData();

        this.accordionData = accordionData;
    }

    get selectedRegionName() {
        return this.parent.fipsMetadata?.name ?? '';
    }

    @computed get selectedGroup(): string | undefined {
        const metricId = this.parent.baseMetricId;
        return _.find(
            this.parent.metadata?.metricsByGrouping,
            grouping => !!_.find(grouping.metrics, { metricId })
        )?.groupingId;
    }

    @computed get accordionGroups(): AccordionGroup[] {
        const metadata = this.parent.metadata;

        if (!metadata) {
            return [];
        }

        return metadata.metricsByGrouping.map(grouping => ({
            id: grouping.groupingId,
            name: grouping.label,
            metrics: grouping.metrics
                .filter(metric => metric.accordionEnabled)
                .map(metric => {
                    const m = this.parent.metricById[metric.metricId];
                    const scale = this.accordionQuantizeScales?.[metric.metricId];
                    // we use the metric selected by the checkboxes if any are selected and then we put
                    // that information into the selected accordion item
                    const checkboxMetric = this.parent.baseMetricId === metric.metricId
                        ? this.parent.metric
                        : undefined;
                    const value = checkboxMetric
                        ? (this.values[checkboxMetric.metadata.metricId] ?? this.values[metric.metricId])
                        : this.values[metric.metricId];
                    // grab subtitles for the currently selected accordion item if there is a checkbox metric
                    const subtitles = checkboxMetric
                        ? this.parent.validCheckCombination?.checked
                        : undefined;

                    if (metric.type === 'categorical') {
                        const extent = metric.extentOptions.extent;
                        const index = extent.indexOf(String(value));
                        return {
                            id: metric.metricId,
                            label: metric.label,
                            subtitles,
                            extent: metric.extentOptions,
                            value,
                            displayValue: m.format(metric.displayValues[index]),
                            color: metric.color,
                            trafficLight: _.isNil(value) ? undefined : {
                                index,
                                buckets: extent.length,
                                primaryColor: metric.color,
                                colors: metric.colors
                            }
                        };
                    }
                    return {
                        id: metric.metricId,
                        label: metric.label,
                        subtitles,
                        extent: metric.extentOptions,
                        value,
                        displayValue: m.format(value),
                        color: metric.color,
                        trafficLight: _.isNil(value) ? undefined : {
                            index: scale?.(value as any ?? 0) ?? -1,
                            buckets: 5,
                            primaryColor: metric.color,
                            secondaryColor: metric.secondaryColor
                        }
                    };
                })
        })).filter(({ metrics }) => metrics.length > 0);
    }

    @computed get accordionQuantizeScales(): Record<string, QuantizeScale> | null {
        if (this.accordionData !== undefined && this.allAvailableMetrics !== undefined) {
            return _(this.allAvailableMetrics)
                .keyBy()
                .mapValues(metricId => {
                    const metric = this.parent.metricById[metricId];
                    return metric.getQuantizeScale(this.parent.geoLevel);
                })
                .value();
        }
        return null;
    }

    @computed get allAvailableMetrics(): string[] {
        const metadata = this.parent.metadata;
        const geoLevel = this.parent.geoLevel;

        if (!metadata) {
            return [];
        }

        let allMetrics: string[] = [];
        _.forEach(metadata.metricsByGrouping, grouping => {
            allMetrics = _.concat(allMetrics, grouping.metrics
                .filter(metric => metric.dataSources[geoLevel].available)
                .map(metric => metric.metricId));
        });

        return allMetrics;
    }

    @computed get metricsByGroup(): Option<string>[] {
        return this.accordionGroups.flatMap(
            ({ name, metrics }) => metrics.map(({ id, label }) => ({
                value: id,
                displayValue: label,
                group: name
            }))
        );
    }

    async getAccordionData() {
        const meta = this.parent.geographyModel.currentFipsMetadata;
        if (!meta) {
            return {};
        }
        const data = await Promise.all(this.allAvailableMetrics.map(
            metricId => this.dataService.getLatestMetricData(
                metricId,
                {
                    geoLevel: meta.geoLevel,
                    fipsCode: meta.fips,
                    filterGeoLevel: this.parent.geoLevel
                }
            ).then(records => ({
                metricId,
                records
            }))
        ));
        return _(data).keyBy('metricId').mapValues('records').value();
    }

    async updateData() {
        const meta = this.parent.geographyModel.currentFipsMetadata;
        if (!meta) {
            return;
        }
        const values = await this.dataService.getAllMetricsValues(
            meta.fips,
            meta.geoLevel
        );
        this.values = values;
    }

    async setGrouping(groupingId: string) {
        const firstMetricId = this.accordionGroups
            .find(group => group.id === groupingId)
            ?.metrics[0].id;
        if (firstMetricId) {
            this.parent.setMetricId(firstMetricId);
        }
    }
}
