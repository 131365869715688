import _ from 'lodash';
import { GeojsonFeature, GeojsonFeatureCollection, ValueDisplayDatum } from '@/types';
import { Source } from '@dha/vue-mapbox-gl';
import { ISpatialDataset } from './Geojson/SpatialDataset';

export function mapGeojsonProps<BeforeProps extends Record<string, unknown>, AfterProps extends Record<string, unknown>>(
    geojson: GeojsonFeatureCollection<BeforeProps>,
    callback: (before: BeforeProps) => AfterProps
): GeojsonFeatureCollection<AfterProps> {
    return {
        ...geojson,
        features: geojson.features.map(feature => ({
            ...feature,
            properties: callback(feature.properties)
        }))
    };
}
export function filterGeojsonByProps<Props extends Record<string, unknown>>(
    geojson: GeojsonFeatureCollection<Props>,
    callback: (props: Props) => boolean
): GeojsonFeatureCollection<Props> {
    return {
        ...geojson,
        features: geojson.features.filter(feature => callback(feature.properties))
    };
}

export function createPointFeature(lnglat: [number, number]): GeojsonFeature {
    return {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: lnglat
        },
        properties: {}
    };
}

export function getFormattedTooltipData(
    dataset: ISpatialDataset
): Record<string, ValueDisplayDatum> {
    return _(dataset?.data ?? {})
        .mapValues((value, fipsCode) => ({
            label: dataset.metricTitle,
            value,
            displayValue: dataset.getDisplayValue(fipsCode),
            trafficLight: {
                index: dataset.getIndex(fipsCode),
                buckets: dataset.buckets,
                colors: dataset.colors
            }
        }))
        .value();
}

export function createSource(id: string, geojson: GeojsonFeatureCollection): Source {
    return {
        id,
        data: {
            type: 'geojson',
            data: geojson
        }
    };
}
