var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"value-display",style:({
        'min-height': _vm.minHeight
    })},[(_vm.showLabels && _vm.label)?_c('div',{staticClass:"label w-full text-surgodarkgreen-100",class:{
            small: _vm.formFactor === 'small'
        }},[_c('div',{staticClass:"metric-labels",class:{
                'no-data': _vm.valueIsNA,
                'font-bold': _vm.active
            },style:({
                color: _vm.active ? _vm.primaryTitleColor : null
            })},[_c('div',[_vm._v(_vm._s(_vm.label))]),_vm._t("default")],2),_c('span',{staticClass:"metric-value",class:{
                'no-data': _vm.valueIsNA,
                'font-bold': _vm.active && !_vm.valueIsNA
            },style:({
                color: _vm.active ? _vm.primaryColor : null
            })},[_vm._v(_vm._s(_vm.value))])]):_vm._e(),(_vm.trafficLight)?_c('div',{staticClass:"traffic-light"},_vm._l((_vm.trafficLight.buckets),function(i){return _c('div',{key:i,staticClass:"tooltip-tile grid-row-1",style:({ 'grid-column': i + 5 - _vm.trafficLight.buckets })},[_c('div',{staticClass:"tooltip-tile-fill",style:({ 'background-color': _vm.getTileBackground(_vm.trafficLight, i - 1), 'height': _vm.tileHeight + 'px' })})])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }