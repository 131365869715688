import { ApiDataSource } from '@/services/DataService/sources/ApiDataSource';
import { DataService } from '@/services/DataService';
import { DeepLinkService } from '@/services/DeepLinkService';
import RegionSearchService from '@/services/RegionSearchService';
import { SocialShareService } from '@/services/SocialShareService';
import { AppModel } from '@/model/AppModel';
import { AppState } from '@/model/AppState';

/**
 * Setup function to kick off the chain of model + service wiring. This is also
 * where feature toggles and environment config should be provided to the models
 * + services.
 *
 * IMPORTANT: This must be a _synchronous_ function so we can immediately return
 * the instances of models that should be provided to components. Async processes
 * can be _started_ here (such as appModel.init()), just not awaited
 */
export function setupModelsAndServices() {
    const apiDataSource = new ApiDataSource('');
    const dataService = new DataService(apiDataSource);
    const deepLinkService = new DeepLinkService<Partial<AppState>>();
    const regionSearchService = new RegionSearchService(dataService);
    const socialShareService = new SocialShareService(deepLinkService);
    const appModel = new AppModel(dataService);

    deepLinkService.getUrlState().then(state => console.log('deepLinkState', state));

    return {
        dataService,
        deepLinkService,
        regionSearchService,
        socialShareService,
        appModel
    };
}
