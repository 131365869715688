



















import Vue from 'vue';
import '@/assets/tailwind.css';
import DhaButton from '@/components/Button/Button.vue';
import { Option } from '@/types';

export default Vue.extend({
    components: {
        DhaButton
    },
    props: {
        options: {
            type: Array as () => Option[],
            default: () => [],
        },
        value: {
            type: String,
            default: null
        },
    },
    computed: {
    },
    methods: {
        onChooseOption(option: Option) {
            if (!option.disabled) {
                this.$emit('selected', option);
            }
        }
    }
});
