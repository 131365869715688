





























import Vue, { PropType } from 'vue';
import _ from 'lodash';

import 'vue-slider-component/theme/default.css';
import '@/assets/tailwind.css';
import { CategoricalFilter } from '@/model/MapModel/Filter';
import Checkbox from '@/components/Checkbox/Checkbox.vue';

export default Vue.extend({
    name: 'CategoryFilter',
    components: {
        Checkbox,
    },
    props: {
        filter: {
            type: Object as PropType<CategoricalFilter>,
            required: true,
        }
    },
    methods: {
        onChange(category: string) {
            this.$emit('change', _.xor(this.filter.selectedCategories, [category]));
        },
        selectedRegionDistance(option) {
            const index = _.findIndex(this.filter.categories, d => d === option.value) + 1;
            return ((index / this.filter.categories.length) - (1 / this.filter.categories.length) / 1.5) * 100;
        },
    }
});
