






















import Vue from 'vue';
import VueMarkdown from '@adapttive/vue-markdown';

export default Vue.extend({
    components: {
        VueMarkdown
    },
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        }
    }
});
