import _ from 'lodash';

import { GeographyModel } from '@/model/GeographyModel';
import { Metric } from '@/model/Metric';
import { observable, computed, ReactiveObject } from '@dha/vue-composition-decorators';
import { DataService } from '@/services/DataService';
import { ColorScale, LegendValue } from '@/model/Metric/Metric';
import { scaleQuantize } from 'd3-scale';

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface ISpatialDataset {
    data: Record<string, string | number | null>;
    colorScale: ColorScale;
    hasValuesBelowExtent: boolean;
    hasValuesAboveExtent: boolean;
    dataType: 'categorical' | 'percent' | 'nominal';
    metricTitle: string;
    buckets: number;
    colors: string[];
    legendValues: LegendValue[];

    getColor(fipsCode: string, excluded?: boolean): string;
    getDisplayValue(fipsCode: string): string;
    getIndex(fipsCode: string): number;

    updateData(
        dataService: DataService,
        metric: Metric
    ): Promise<void>;
}
export class NullSpatialDataset implements ISpatialDataset {
    readonly data = {};
    readonly colorScale = scaleQuantize<string, string>();
    hasValuesBelowExtent = false;
    hasValuesAboveExtent = false;
    dataType = 'percent' as 'percent';
    metricTitle = 'null';
    buckets = 5;
    colors = ['#fff', '#fff', '#fff', '#fff', '#fff'];
    legendValues = [];

    /* eslint-disable class-methods-use-this, @typescript-eslint/no-empty-function */
    getColor() { return ''; }
    getDisplayValue() { return ''; }
    getIndex() { return 0; }

    async updateData() {}
    /* eslint-enable class-methods-use-this, @typescript-eslint/no-empty-function */
}

export class SpatialDataset extends ReactiveObject implements ISpatialDataset {
    @observable.ref data: Record<string, string | number | null>;
    @observable.ref private metric: Metric;
    @observable.ref private geography: GeographyModel;

    private constructor(
        data: Record<string, string | number | null>,
        metric: Metric,
        geography: GeographyModel
    ) {
        super();

        this.data = data;
        this.metric = metric;
        this.geography = geography;
    }

    getColor(fipsCode: string, excluded = false) {
        return this.metric.getColor(
            this.geography.geoLevel,
            excluded ? null : this.data[fipsCode]
        );
    }
    getDisplayValue(fipsCode: string) {
        return this.metric.getDisplayValue(
            this.data[fipsCode]
        );
    }
    getIndex(fipsCode: string) {
        const value = this.data[fipsCode];
        return _.isNil(value)
            ? 0
            : this.quantizeScale(value as any);
    }

    @computed get colorScale(): ColorScale {
        return this.metric.getColorScale(this.geography.geoLevel)
            ?? scaleQuantize();
    }

    @computed get quantizeScale() {
        return this.metric.getQuantizeScale(this.geography.geoLevel)
            ?? scaleQuantize();
    }

    @computed get hasValuesBelowExtent() {
        return this.metric.valuesBelowExtent(
            this.geography.geoLevel,
            _.values(this.data)
        );
    }
    @computed get hasValuesAboveExtent() {
        return this.metric.valuesAboveExtent(
            this.geography.geoLevel,
            _.values(this.data)
        );
    }
    @computed get dataType() {
        return this.metric.metadata.type;
    }
    @computed get metricTitle() {
        return this.metric.metadata.label;
    }
    @computed get buckets() {
        return this.metric.quantizeBuckets;
    }
    @computed get colors() {
        return this.metric.quantizeColors;
    }
    @computed get legendValues() {
        return this.metric.getLegendValues(this.geography.geoLevel);
    }

    async updateData(
        dataService: DataService,
        metric: Metric
    ) {
        const focus = this.geography.focus;
        const geoLevel = this.geography.geoLevel;
        const data = await dataService.getLatestMetricData(
            metric.metadata.metricId,
            {
                geoLevel,
                fipsCode: focus.fips,
                filterGeoLevel: focus.geoLevel
            }
        );
        this.data = _(data)
            .keyBy(d => d.fips)
            .mapValues(d => d.value)
            .value();
        this.metric = metric;
    }

    static async new(
        dataService: DataService,
        metric: Metric,
        geography: GeographyModel
    ): Promise<SpatialDataset> {
        const focus = geography.focus;
        const geoLevel = geography.geoLevel;
        const data = await dataService.getLatestMetricData(
            metric.metadata.metricId,
            {
                geoLevel,
                fipsCode: focus.fips,
                filterGeoLevel: focus.geoLevel
            }
        );

        return new SpatialDataset(
            _(data)
                .keyBy(d => d.fips)
                .mapValues(d => d.value)
                .value(),
            metric,
            geography
        );
    }
}
