







































import Vue from 'vue';

export default Vue.extend({
    name: 'Checkbox',
    props: {
        value: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: null
        },
        checked: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        checkboxLabel(): string {
            return this.label ?? this.value;
        }
    },
    methods: {
        onClick() {
            if (!this.disabled) {
                this.$emit('change', this.value);
            }
        }
    }
});
