


































































































































import '@dha/vue-search-bar/dist/vue-search-bar.css';

import Vue from 'vue';
import _ from 'lodash';
import {
    ActionLink,
    BoundingBox,
    SearchResult,
    SocialDialogState,
    TextLink,
    VueWithTypedRefs
} from '@/types';

import SearchBar from '@dha/vue-search-bar';
import TutorialOverlay from '@/views/TutorialOverlay/TutorialOverlay.vue';
import TitleBar from '@/views/TitleBar/TitleBar.vue';
import DhaFooter from '@/views/Footer/Footer.vue';
import AccordionView from '@/views/AccordionView/AccordionView.vue';
import MobileMetricSelect from '@/views/MobileMetricSelect/MobileMetricSelect.vue';
import MapSection from '@/views/MapSection/MapSection.vue';
import TemporalDataSection from '@/views/TemporalDataSection/TemporalDataSection.vue';
import CategoricalDataSection from '@/views/CategoricalDataSection/CategoricalDataSection.vue';
import LoadingOverlay from '@/views/LoadingOverlay/LoadingOverlay.vue';
import SidebarView from '@/views/Sidebar/SidebarView.vue';
import ShareButtons from '@/components/ShareButtons/ShareButtons.vue';
import SocialDialog from '@/components/SocialDialog/SocialDialog.vue';

import { setupModelsAndServices } from './setup';

const {
    appModel,
    dataService,
    deepLinkService,
    regionSearchService,
    socialShareService
} = setupModelsAndServices();

export default (Vue as VueWithTypedRefs<{
    search: typeof SearchBar;
    'map-section': typeof MapSection;
}>).extend({
    appModel,
    components: {
        TutorialOverlay,
        TitleBar,
        AccordionView,
        MobileMetricSelect,
        MapSection,
        TemporalDataSection,
        CategoricalDataSection,
        DhaFooter,
        LoadingOverlay,
        SidebarView,
        SearchBar,
        ShareButtons,
        SocialDialog
    },
    data() {
        return {
            socialShareService,
            menuIsOpen: false,
            loadingOverlayIsOpen: true,
            tutorialIsOpen: false,
            socialDialogState: null as SocialDialogState
        };
    },
    computed: {
        metricIsTemporal(): boolean {
            return this.$appModel.metric?.metadata.isTemporal ?? false;
        },
        metricHasCategoricalData(): boolean {
            return this.$appModel.categoricalDataModel.barData.length > 0;
        },
        source(): string | null {
            return this.$appModel.metric.metadata.source || null;
        },
        sourceURL(): string | null {
            return this.$appModel.metric.metadata.sourceURL || null;
        },
        sourceDescription(): string | null {
            return this.$appModel.metric.metadata.sourceDescription || null;
        },
        fipsCode(): string {
            return this.$appModel.fipsCode;
        },
        links(): Array<TextLink | ActionLink> {
            const tutorialLink = {
                text: 'Tutorial',
                action: this.onTutorialLinkClicked
            };
            return [tutorialLink, ...this.$appModel.textLinks];
        },
        showTutorial(): boolean {
            return this.$appModel.showTutorial;
        },
        autoExplore(): boolean {
            return this.$appModel.autoExplore;
        }
    },
    watch: {
        fipsCode() {
            if (this.$refs.search) {
                // eslint-disable-next-line
                // @ts-ignore
                this.$refs.search.clearSearchInput();
            }
            this.$refs['map-section'].clearSearchInput();
        }
    },
    async mounted() {
        (window as any).appModel = appModel;

        await dataService.init();
        const initialState = await deepLinkService.getUrlState();
        await appModel.init(initialState);
        deepLinkService.observe(
            () => _.pickBy(appModel.appState, v => !_.isNil(v))
        );
    },
    methods: {
        onMenuInput(value: boolean) {
            this.menuIsOpen = value;
        },
        async onRegionSearch(searchText: string | null): Promise<SearchResult[]> {
            return (searchText && searchText !== '')
                ? regionSearchService.getRegionsFromSearch(searchText)
                : [];
        },
        async onSearchItemSelected(result: SearchResult): Promise<void> {
            if (!result) {
                return;
            }

            const newFips = await this.$appModel.handleSearchResult(result);

            if (result.center && newFips) {
                const bounds = result.bbox ?? this.$appModel.mapModel.getFeatureBounds(newFips);
                if (bounds === undefined) {
                    return;
                }
                const width = bounds[2] - bounds[0];
                const height = bounds[3] - bounds[1];
                const adjustedBounds = [
                    bounds[0] - width,
                    bounds[1] - height,
                    bounds[2] + width,
                    bounds[3] + height
                ] as BoundingBox;

                this.$refs['map-section'].zoomToBounds(adjustedBounds);
            }
        },
        onLoadingOverlayDismiss() {
            if (this.showTutorial) {
                this.tutorialIsOpen = true;
            }
            this.loadingOverlayIsOpen = false;
            // Safeguard logic in case LoadingOverlay beforeDestroy() is not called
            document.body.style.removeProperty('overflow');
            document.body.style.removeProperty('position');
        },
        async onTutorialLinkClicked() {
            this.menuIsOpen = false;
            if (!this.tutorialWorksForCurrentState()) {
                await this.$appModel.init();
            }
            this.tutorialIsOpen = true;
            this.loadingOverlayIsOpen = false;
        },
        onTutorialExit(noShowTutorial: boolean) {
            if (noShowTutorial) {
                this.$appModel.updateLocalStore('noShowTutorial', 'true');
            }
            this.$appModel.showTutorial = false;
            this.tutorialIsOpen = false;
            document.body.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        },
        tutorialWorksForCurrentState() {
            return this.$appModel.tutorialSequence
                .every((step) => document.querySelector(step.selector) !== null);
        },
        onSocialButtonClicked(socialType: SocialDialogState) {
            this.socialDialogState = socialType;
        },
        onZoomToBounds() {
            this.$appModel.setFocusFipsCode('99999');
        }
    }
});
