













































import Vue, { PropType } from 'vue';
import DhaSelect from '@/components/Select/Select.vue';
import { Option } from '@/types';
import { Focus } from '@/model/GeographyModel/Geography';

export default Vue.extend({
    components: {
        DhaSelect
    },
    props: {
        initialFocus: {
            type: Object as PropType<Focus>,
            default: null
        },
        label: {
            type: String,
            default: 'FOCUS ON'
        },
        showSearch: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        stateFocusOptions(): Option<string | undefined>[] {
            // Turn objects into strings so we don't get key errors
            // in the select component
            const focusParent = this.$appModel.geographyModel.fipsCode.substring(0, 2);
            return this.$appModel.geographyModel.stateFocusOptions
                .map(o => ({
                    ...o,
                    value: o.value ? o.value.fips : o.value
                }))
                .concat([{ value: '__separator', disabled: true }])
                .sort((a, b) => {
                    if (b.value === '99999') return 1;
                    if (a.value === '99999') return -1;

                    if (b.value === focusParent) return 1;
                    if (a.value === focusParent) return -1;

                    if (b.value === '__separator') return 1;
                    if (a.value === '__separator') return -1;

                    if (a.value === b.value) return 0;
                    return (b.value < a.value) ? 1 : -1;
                });
        },
        stateFocus(): string | undefined {
            return this.$appModel.geographyModel.stateFocus?.fips;
        },
        countyFocusOptions(): Option<string | undefined>[] {
            // Turn objects into strings so we don't get key errors
            // in the select component
            return this.$appModel.geographyModel.countyFocusOptions.map(o => ({
                ...o,
                value: o.value ? o.value.fips : o.value
            }));
        },
        countyFocus(): string | undefined {
            return this.$appModel.geographyModel.countyFocus?.fips;
        },
        hasSelection(): boolean {
            return this.$appModel.geographyModel.fipsCode !== this.$appModel.geographyModel.focus.fips;
        }
    },
    methods: {
        focusOptionSelected(fipsCode: string): void {
            this.$appModel.setFocusFipsCode(fipsCode);
        },
    }
});
