var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"area-chart flex flex-col"},[_c('div',{staticClass:"flex flex-grow-0 justify-end"},[_c('legend-categorical',{attrs:{"values":_vm.legendValues}})],1),_c('chart-base',{attrs:{"data":_vm.data,"format-x":_vm.formatX,"format-y":_vm.formatY,"hovered-point":_vm.hoveredPoint},on:{"hover":_vm.onHover,"leave":_vm.onLeave},scopedSlots:_vm._u([{key:"default",fn:function(chartProps){return [_vm._l((_vm.areaData(chartProps.data)),function(d,i){return _c('line-chart-line',_vm._b({key:("area-" + i)},'line-chart-line',{
                data: d.data,
                xScale: chartProps.xScale,
                yScale: chartProps.yScale,
                area: true,
                color: d.color || _vm.defaultAreaColor
            },false))}),_vm._l((_vm.lineData(chartProps.data)),function(d,i){return _c('line-chart-line',_vm._b({key:("line-" + i)},'line-chart-line',{
                data: d.data,
                xScale: chartProps.xScale,
                yScale: chartProps.yScale,
                area: false,
                color: d.color || _vm.defaultLineColor
            },false))}),(_vm.hoveredPoint)?_c('g',[_c('line',{staticClass:"hover-line",attrs:{"x1":chartProps.xScale(_vm.hoveredPoint.points[0].x),"x2":chartProps.xScale(_vm.hoveredPoint.points[0].x),"y1":"0","y2":chartProps.yScale.range()[0]}}),_vm._l((_vm.hoveredPoint.points.slice().reverse()),function(point,i){return _c('circle',{key:("circle-" + i),attrs:{"cx":chartProps.xScale(point.x),"cy":chartProps.yScale(point.y),"fill":point.color,"stroke":"white","r":"6"}})})],2):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }