import _ from 'lodash';
import { AsyncModel, computed, observable } from '@dha/vue-composition-decorators';
import { DataService, GeoLevelMeta } from '@/services/DataService';
import { ParsedFilterValues, ParsedGeoLevel, ParsedMetadata } from '@/services/DataService/parsers';
import { Option, FilterMetric, TextLink, FilterValue, TutorialEntry, SearchResult, CheckboxDatum, CheckboxMetric } from '@/types';

import { GeographyModel } from './GeographyModel';
import { MapModel, MapModelParent } from './MapModel';
import { AccordionModel, AccordionModelParent } from './AccordionModel';
import { TemporalDataModel, TemporalDataModelParent } from './TemporalDataModel';
import { CategoricalDataModel, CategoricalDataModelParent } from './CategoricalDataModel';
import { AnyMetric, metricFromMetadata } from './Metric';
import { AppState } from './AppState';
import { getInitialFilters, getNewGeoLevelIfDisabled } from './helpers';
import { nationalGeoLevelMeta } from './GeographyModel/Geography';
import { NullMetric } from './Metric/NullMetric';
import { properCase } from './GeographyModel/helpers';

export class AppModel extends AsyncModel implements
    MapModelParent,
    AccordionModelParent,
    TemporalDataModelParent,
    CategoricalDataModelParent {
    dataService: DataService;
    localStore: Storage;
    geographyModel: GeographyModel;
    mapModel: MapModel;
    accordionModel: AccordionModel;
    temporalDataModel: TemporalDataModel;
    categoricalDataModel: CategoricalDataModel;

    // Temporary placeholder state
    @observable baseMetricId = '<loading>';

    @observable.ref metadata?: ParsedMetadata;
    @observable.ref metricById: Record<string, AnyMetric> = {};
    @observable isLoaded = false;

    readonly geoLevelLabels: Record<Exclude<ParsedGeoLevel, 'national'>, {singular: string; plural: string}> = {
        state: {
            singular: 'State',
            plural: 'States'
        },
        county: {
            singular: 'County',
            plural: 'Counties'
        },
        tract: {
            singular: 'Tract',
            plural: 'Tracts'
        }
    };
    @computed get geoLevelOptions(): Option<ParsedGeoLevel>[] {
        return this.geographyModel.geoLevelOptions.map(category => ({
            ...category,
            // if there is already a disabled mode check if the data is actually unavailable
            // else check if data is available
            disabled: category.disabled || !this.metric.isGeoLevelAvailable(category.value)
        }));
    }
    @computed get currentGeoLevelLabels(): {singular: string; plural: string} {
        return this.geoLevelLabels[this.geoLevel];
    }

    // TODO: Should we initialize these from somewhere?
    @computed get geoLevel() {
        return this.geographyModel.geoLevel;
    }
    @computed get fipsCode() {
        return this.geographyModel.fipsCode;
    }
    @observable date = '2020-11-01';

    @observable autoExplore = false;
    @observable showTutorial = true;
    readonly tutorialSequence: TutorialEntry[] = [{
        selector: '.accordion, .mobile-metric-select',
        instruction: 'You can select an indicator of interest from the accordion on the left to view its associated maps and charts.',
        altInstruction: 'You can select an indicator of interest from the dropdown at the top to view its associated maps and charts.'
    }, {
        selector: '.search-bar-container',
        instruction: 'Use the search feature to quickly find a place.'
    }, {
        selector: '.geography-controls',
        instruction: 'Choose the granularity of the map and/or focus the map and subsequent charts on a particular region of interest.'
    }, {
        selector: '.filter-group',
        instruction: 'Use the filter to further focus your attention. For instance, you can view COVID-19 case rates in just the high vulnerability counties.'
    }, {
        selector: '.chart',
        instruction: 'When sufficient data is available you will be able to see the breakdown amongst regions with a high vulnerability index vs. those with a low one.'
    }, {
        selector: '.categorical-data-section .focus-controls',
        instruction: 'Like the map above you can focus the breakdown on a particular region of interest.'
    }, {
        selector: '.temporal-data-section .content',
        instruction: 'When data is available over time you may view a timeline. Click on a region on the map to highlight a region. Use the [[focus on]] dropdown to select a region for comparison.'
    }];

    // The list of checkboxes checked to modify the output metric (if any are defined)
    @observable checkedLabels: string[] = [];

    constructor(dataService: DataService, localStore: Storage = localStorage) {
        super();
        this.dataService = dataService;
        this.localStore = localStore;
        this.geographyModel = new GeographyModel(dataService);
        this.mapModel = new MapModel(dataService, this);
        this.accordionModel = new AccordionModel(dataService, this);
        this.temporalDataModel = new TemporalDataModel(dataService, this);
        this.categoricalDataModel = new CategoricalDataModel(dataService, this);
    }

    // Important that this prop is optional and partial. Makes it easier to be
    // backwards-compatible if these props are optional
    async init(initialState?: Partial<AppState>) {
        const [
            metadata
        ] = await Promise.all([
            this.dataService.getMetadata()
        ]);

        const firstMetricId = _.values(metadata.metricsByGrouping)[0].metrics[0].metricId;

        const defaults = metadata.defaults;

        this.metadata = metadata;
        this.metricById = _(metadata.metricsByGrouping)
            .flatMap(group => group.metrics)
            .keyBy(metric => metric.metricId)
            .mapValues(metric => metricFromMetadata(metric))
            .value();

        // TODO: Initialize from metadata as well
        const metricId = initialState?.metricId ?? defaults?.metricId ?? firstMetricId;
        let geoLevel = initialState?.geoLevel ?? defaults?.geoLevel ?? 'state';
        const fipsCode = initialState?.fipsCode ?? defaults?.fipsCode ?? '99999';

        const defaultFilters: ParsedFilterValues = [{
            metricId: defaults?.filterMetricId ?? _.keys(this.metricById)[0],
            values: undefined
        }];
        const filters = initialState?.filters
            ? (getInitialFilters(initialState.filters) ?? defaultFilters)
            : defaultFilters;

        const focusLevel = initialState?.focusLevel;
        const focusFips = initialState?.focusFips;
        let initialFocus = nationalGeoLevelMeta;

        if (focusLevel && focusFips) {
            const geoMetadata = await this.dataService.getGeoLevelMeta(focusLevel);
            const focusMeta = _.find(geoMetadata, { fips: focusFips }) ?? nationalGeoLevelMeta;
            initialFocus = focusMeta
                ? { ...focusMeta, name: properCase(focusMeta.name) }
                : nationalGeoLevelMeta;
        } else if (geoLevel === 'tract') {
            // can't have initial geoLevel be tract without focus
            geoLevel = 'county';
        }

        this.baseMetricId = metricId;

        await this.geographyModel.init({
            fipsCode,
            geoLevel: geoLevel as 'state' | 'county',
            focus: initialFocus
        });

        await Promise.all([
            this.mapModel.init(filters),
            this.accordionModel.init(),
            this.temporalDataModel.init(),
            this.categoricalDataModel.init()
        ]);

        // prevent eg. tract granularity due to initialFocus if no tract data
        if (_.find(this.geoLevelOptions, { value: geoLevel })?.disabled) {
            await this.setGeoLevel('state');
        }

        // If noShowTutorial is set to anything, then the tutorial will not show
        this.showTutorial = this.localStore.getItem('noShowTutorial') === null;

        if (initialState && Object.keys(initialState).length !== 0) {
            this.autoExplore = true;
            this.showTutorial = false;
        }

        this.isLoaded = true;
    }

    @computed get metricId(): string {
        return this.checkboxSelectedMetric ?? this.baseMetricId;
    }

    @computed get defaultFilterMetric(): string {
        return this.metadata?.defaults?.filterMetricId ?? _.keys(this.metricById)[0];
    }

    @computed get textLinks(): TextLink[] {
        return this.metadata?.textLinks ?? [];
    }

    @computed get splashScreenLinks(): TextLink[] {
        return this.metadata?.splashScreenLinks ?? [];
    }

    // Computed watcher that stores the mutable state. Used for deep linking
    // or other state serialization
    @computed get appState(): AppState {
        const focus = this.geographyModel.focus;

        return {
            metricId: this.baseMetricId,
            geoLevel: this.geoLevel,
            fipsCode: this.fipsCode,
            focusLevel: focus.geoLevel !== 'national' ? focus.geoLevel : undefined,
            focusFips: focus.geoLevel !== 'national' ? focus.fips : undefined,
            filters: JSON.stringify(this.filterState)
        };
    }

    /*
        For the current top-level metric, get all valid checkbox combinations -
        ie. some combinations  of checked/unchecked are not valid. If they are, then
        the valid combination is listed in the config sheet and points to another metricId
    */
    @computed get checkboxMetrics(): CheckboxMetric[] {
        const topLevelMetric = this.metricById[this.baseMetricId] ?? new NullMetric();
        return topLevelMetric.metadata.checkboxMetrics ?? [];
    }

    /*
        Given the actually checked checkboxes on the page, get the first entry in checkboxMetrics
        to have at least those checked items.

        If there is an exact match, return it.

        If there is not an exact match, return the first entry with the checked items plus others.

        If there is no match at all, return undefined.

        NOTE: This method of finding a match for the user-selected checkboxes may not work that
        great for 3+ checkboxes. That is a more complicated problem with UI design implications.
    */
    @computed get validCheckCombination(): CheckboxMetric | undefined {
        if (this.checkedLabels.length === 0) return undefined;

        // get all checkboxMetrics that match the currently checked boxes (and maybe more)
        const hasChecked = this.checkboxMetrics.filter(
            metric => this.checkedLabels.every(label => metric.checked.includes(label))
        );
        if (hasChecked.length === 0) return undefined;

        // exact checkbox matches take priority
        const exactMatch = hasChecked.find(d => d.checked.length === this.checkedLabels.length);
        if (exactMatch) {
            return exactMatch;
        }

        // pick the first with at least the selected checkboxes
        // this is the main assumption that may not hold up with 3+
        return hasChecked[0];
    }

    @computed get checkboxStatus(): CheckboxDatum[] {
        return _(this.checkboxMetrics)
            .flatMap(d => d.checked)
            .uniq()
            .map(checkLabel => {
                const checked = this.validCheckCombination?.checked.includes(checkLabel) ?? false;
                // if the valid combination contains this checkbox and another one, otherInPair is the other one
                const otherInPair = this.validCheckCombination?.checked.find(c => c !== checkLabel);
                /*
                    otherCheckCantBeAlone determines if a checkbox must be checked (ie. is unclickable)
                    given the valid combination. For example:

                    If the possible combinations of checked boxes as defined in the config are

                    [A], [A, B]

                    then B may not be checked alone. Thus, if the user checks B alone, we want to
                    check [A, B] and disable A to indicate that it is required if B is checked.

                    The current logic also only works with two boxes.
                */
                const otherCheckCantBeAlone = !!otherInPair
                    && !this.checkboxMetrics.find(
                        metric => metric.checked.includes(otherInPair) && metric.checked.length === 1
                    );
                return {
                    value: checkLabel,
                    label: checkLabel,
                    checked,
                    disabled: checked && otherCheckCantBeAlone
                };
            })
            .value();
    }

    @computed get checkboxSelectedMetric(): string | null {
        return this.validCheckCombination?.metricId ?? null;
    }

    // this metric is set as the id picked by the top checkbox group,
    // OR the selected top level metric otherwise.
    @computed get metric(): AnyMetric {
        return this.metricById[this.metricId] ?? new NullMetric();
    }

    @computed get allMetricMetadata() {
        return this.metadata?.metricsByGrouping
            .flatMap(grouping => ({
                id: grouping.groupingId,
                name: grouping.label,
                metrics: grouping.metrics
            })) ?? [];
    }
    @computed get filterOptions(): Option<string>[] {
        return this.allMetricMetadata.flatMap(
            ({ name, metrics }) => metrics
                .filter(({ filterEnabled }) => filterEnabled)
                .map(({ dataSources, metricId, label }) => ({
                    value: metricId,
                    displayValue: label,
                    group: name,
                    isAvailableByGeoLevel: _.mapValues(dataSources, source => source.available)
                }))
        );
    }
    @computed get filterMetricData(): FilterMetric[] {
        const metrics: any[] = this.allMetricMetadata;
        return metrics.map(metric => {
            const extent = metric.extentOptions.kind === 'static'
                ? metric.extentOptions.extent
                : metric.extentOptions.extentByGeoLevel[this.geoLevel];
            return {
                value: metric.metricId,
                showLabels: metric.groupingId === 'vulnerability',
                extent,
                type: metric.type,
                displayValues: metric.type === 'categorical' ? metric.displayValues : undefined
            };
        });
    }
    @computed get filterState(): FilterValue[] {
        return this.mapModel.filters.map(filter => ({
            metricId: filter.metricId,
            values: filter.values
        }));
    }
    @computed get fipsMetadata(): GeoLevelMeta | undefined {
        return this.geographyModel.currentFipsMetadata;
    }
    @computed get tooltipPersistentMetrics(): string[] {
        return this.metadata?.tooltipMetrics ?? [];
    }

    async setFocusFipsCode(fipsCode: string, preferredGeoLevel?: ParsedGeoLevel) {
        if (this.geographyModel.focus.fips !== fipsCode) {
            this.mapModel.startLoading();

            let availableGeoLevels = _(this.metric?.metadata.dataSources)
                .pickBy(source => source.available)
                .keys()
                .value() as ParsedGeoLevel[];

            if (preferredGeoLevel && availableGeoLevels.includes(preferredGeoLevel)) {
                availableGeoLevels = [preferredGeoLevel];
            }

            await this.geographyModel.setFocusFipsCode(fipsCode, { availableGeoLevels });
            await Promise.all([
                this.mapModel.updateAll(),
                this.accordionModel.updateData(),
                this.temporalDataModel.updateData(),
                this.categoricalDataModel.updateData()
            ]);
        }
    }
    async setMetricId(metricId: string) {
        this.checkedLabels = [];
        this.baseMetricId = metricId;
        const newGeoLevel = getNewGeoLevelIfDisabled(this.geoLevelOptions, this.geoLevel);
        if (newGeoLevel) {
            await this.setGeoLevel(newGeoLevel);
        } else {
            await Promise.all([
                this.mapModel.updateData(),
                this.temporalDataModel.updateData(),
                this.categoricalDataModel.updateData()
            ]);
        }
    }

    async setChecked(id: string) {
        // this part might get smarter
        let checks = this.checkedLabels;
        if (checks.includes(id)) {
            checks = checks.filter(check => check !== id);
        } else {
            checks.push(id);
        }
        this.checkedLabels = checks;
        const newGeoLevel = getNewGeoLevelIfDisabled(this.geoLevelOptions, this.geoLevel);
        if (newGeoLevel) {
            await this.setGeoLevel(newGeoLevel);
        } else {
            await Promise.all([
                this.mapModel.updateData(),
                this.temporalDataModel.updateData(),
                this.categoricalDataModel.updateData()
            ]);
        }
    }

    async setGeoLevel(geoLevel: ParsedGeoLevel) {
        if (this.geoLevel !== geoLevel) {
            this.mapModel.startLoading();
            await this.geographyModel.setGeoLevel(geoLevel);
            await Promise.all([
                this.mapModel.updateAll(),
                this.accordionModel.updateData(),
                this.temporalDataModel.updateData(),
                this.categoricalDataModel.updateData()
            ]);
        }
    }

    async setFipsCode(fipsCode: string) {
        if (fipsCode === this.geographyModel.fipsCode) {
            // eslint-disable-next-line
            fipsCode = this.geographyModel.focus.fips;
        }
        this.geographyModel.fipsCode = fipsCode;
        await Promise.all([
            this.mapModel.updateDataLayersAndSources(),
            this.accordionModel.updateData(),
            this.temporalDataModel.updateData(),
            this.categoricalDataModel.updateData()
        ]);
    }

    async handleSearchResult(result: SearchResult): Promise<string | false> {
        if (!result) {
            return false;
        }

        let newFipsCode = result.countyFips || result.stateFips;

        await this.setGeoLevel('county');
        await this.setFocusFipsCode(newFipsCode);

        if (result.center) {
            const fips = this.mapModel.getEnclosingFeature(result.center);
            if (fips) {
                newFipsCode = fips;
                await this.setFocusFipsCode(newFipsCode);
            }
        } else {
            await this.setFipsCode(newFipsCode);
        }

        return newFipsCode;
    }

    updateLocalStore(key: string, value: string) {
        this.localStore.setItem(key, value);
    }
}
