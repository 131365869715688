


































































































import _ from 'lodash';
import Vue, { PropType } from 'vue';
import { getTailwindColor, getTailwindFontSize } from '@/components/getTailwindStyle';
import { TrafficLightDatum, ValueDisplayDatum } from '@/types';

const twGray100 = getTailwindColor('gray', 100);
const twGray300 = getTailwindColor('gray', 300);
const twGray400 = getTailwindColor('gray', 400);
const textXs = getTailwindFontSize('xs');
const textLg = getTailwindFontSize('lg');

export default Vue.extend({
    props: {
        greyedOutColor: {
            type: String,
            default: twGray100
        },
        datum: {
            type: Object as PropType<ValueDisplayDatum>,
            default: null
        },
        showLabels: {
            type: Boolean,
            default: true
        },
        tileHeight: {
            type: Number,
            default: 10
        },
        monocolor: {
            type: Boolean,
            default: false
        },
        formFactor: {
            type: String,
            default: 'large'
        },
        active: {
            type: Boolean,
            default: false
        },
        showNA: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        label(): string {
            return this.datum?.label ?? '';
        },
        value(): string {
            const displayValue = this.datum?.displayValue ?? '';
            if (this.showNA) {
                return displayValue;
            }
            return displayValue === 'NA' ? '' : displayValue;
        },
        valueIsNA(): boolean {
            return _.isNil(this.datum?.value);
        },
        trafficLight(): TrafficLightDatum | undefined {
            return this.datum?.trafficLight;
        },
        minHeight(): string {
            // the size the value display would be if there was a traffic light,
            // ie. tileHeight + 0.5rem padding + title's line height
            const textSize = this.formFactor === 'small' ? textXs : textLg;
            const padding = this.formFactor === 'small' ? '0' : '0.5rem';
            return `calc(${this.tileHeight}px + ${padding} + ${textSize} * 1.5)`;
        },
        primaryTitleColor(): string {
            if (this.monocolor) {
                return twGray400;
            }
            return this.datum?.color ?? twGray400;
        },
        primaryColor(): string {
            if (this.monocolor) {
                return twGray400;
            }
            return this.datum?.color ?? twGray400;
        },
        secondaryColor(): string {
            if (this.valueIsNA) {
                return twGray300;
            }
            if (this.monocolor) {
                return twGray400;
            }
            return this.datum?.trafficLight?.secondaryColor ?? twGray300;
        },
    },
    methods: {
        getTileBackground(metric: TrafficLightDatum, index: number): string {
            if (this.valueIsNA) return this.greyedOutColor;

            const selectedColor = this.active ? this.primaryColor : this.secondaryColor;
            const color = metric.colors?.[index] ?? selectedColor ?? this.greyedOutColor;
            return metric.index === index ? color : this.greyedOutColor;
        }
    }
});
