var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-base"},[_c('svg',{ref:"svg",staticClass:"overflow-visible"},[_c('g',{ref:"yAxis",staticClass:"axis y",style:({
                transform: ("translateX(" + _vm.leftMargin + "px)")
            })}),_c('g',[_vm._t("default",null,{"data":_vm.data,"xScale":_vm.xScale,"yScale":_vm.yScale})],2),_c('g',{ref:"xAxis",staticClass:"axis x",style:({
                transform: ("translateY(" + (_vm.height - _vm.bottomMargin) + "px)")
            })})]),_c('div',{ref:"overlay",staticClass:"base-overlay",on:{"mousemove":_vm.onMousemove,"mouseout":_vm.onMouseout}}),(_vm.hoveredPoint)?_c('div',{staticClass:"absolute",style:({
            left: _vm.tooltipX,
            top: 0,
        })},[_c('tooltip',{attrs:{"subtitle":_vm.hoveredPoint.subtitle,"transform":_vm.tooltipTranslate,"metrics-data":_vm.hoveredPoint.points,"use-rows":true}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }