






























import Vue, { PropType } from 'vue';
import _ from 'lodash';

import { interpolatePath } from 'd3-interpolate-path';
import { ScaleLinear, ScaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { area, line } from 'd3-shape';
import 'd3-transition';
import { BaseChartDatum, ChartDrawInputs, VueWithTypedRefs } from '@/types';
import { getTailwindColor } from '@/components/getTailwindStyle';
import { uid } from './helpers';

const TRANSITION_DURATION = 500;

export default (Vue as VueWithTypedRefs<{
    above: typeof SVGPathElement;
    below: typeof SVGPathElement;
    clipAbove: typeof SVGPathElement;
    clipBelow: typeof SVGPathElement;
    line: typeof SVGPathElement;
}>).extend({
    props: {
        area: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: getTailwindColor('gray', 500)
        },
        data: {
            type: Array as () => BaseChartDatum<number>[],
            default: () => []
        },
        xScale: {
            type: Function as PropType<ScaleTime<number, number>>,
            default: null
        },
        yScale: {
            type: Function as PropType<ScaleLinear<number, number>>,
            default: null
        }
    },
    data() {
        return {
            aboveUid: uid('areaAbove'),
            belowUid: uid('areaBelow')
        };
    },
    computed: {
        drawInputs(): {
            data: BaseChartDatum<number>[];
            xScale: ScaleTime<number, number>;
            yScale: ScaleLinear<number, number>;
            } {
            return {
                data: this.sortedData,
                xScale: this.xScale,
                yScale: this.yScale
            };
        },
        sortedData(): BaseChartDatum<number>[] {
            return _.sortBy(this.data, d => d.cdate);
        }
    },
    watch: {
        drawInputs(inputs: ChartDrawInputs) {
            this.aboveUid = uid('areaAbove');
            this.belowUid = uid('areaBelow');

            this.draw(inputs);
        }
    },
    methods: {
        areaAbove({ xScale, yScale }: ChartDrawInputs): string | null {
            if (!xScale || !yScale) return null;

            return area<BaseChartDatum<number>>()
                .x(d => xScale(d.cdate))
                .y0(d => yScale(d.value))
                .y1(yScale.range()[1])(this.sortedData);
        },
        areaBelow({ xScale, yScale }: ChartDrawInputs): string | null {
            if (!xScale || !yScale) return null;

            return area<BaseChartDatum<number>>()
                .x(d => xScale(d.cdate))
                .y0(yScale.range()[0])
                .y1(d => yScale(d.value))(this.sortedData);
        },
        clipAbove({ xScale, yScale }: ChartDrawInputs): string | null {
            if (!xScale || !yScale) return null;

            return area<BaseChartDatum<number>>()
                .x(d => xScale(d.cdate))
                .y0(yScale(0))
                .y1(yScale.range()[1])(this.sortedData);
        },
        clipBelow({ xScale, yScale }: ChartDrawInputs): string | null {
            if (!xScale || !yScale) return null;

            return area<BaseChartDatum<number>>()
                .x(d => xScale(d.cdate))
                .y0(yScale.range()[0])
                .y1(yScale(0))(this.sortedData);
        },
        d({ xScale, yScale }: ChartDrawInputs): string | null {
            if (!xScale || !yScale) return null;

            return line<BaseChartDatum<number>>()
                .x(d => xScale(d.cdate))
                .y(d => yScale(d.value))(this.sortedData);
        },
        draw(inputs: ChartDrawInputs, transition = true) {
            const mainLine = select(this.$refs.line);
            const lineDuration = transition ? TRANSITION_DURATION : 0;

            mainLine
                .transition()
                .duration(lineDuration)
                .attrTween('d', () => {
                    const next = this.d(inputs) as string;
                    const prev = mainLine.attr('d') ?? next;

                    return interpolatePath(prev, next);
                })
                .attr('stroke', this.area ? 'none' : this.color);

            if (this.area) {
                this.drawAreas(
                    inputs,
                    lineDuration
                );
            }
        },
        drawAreas(
            inputs: ChartDrawInputs,
            duration: number
        ) {
            const below = select(this.$refs.below);
            const belowD = this.areaBelow(inputs);

            if (belowD) {
                const clipAboveD = this.clipAbove(inputs);

                below
                    .transition()
                    .duration(duration)
                    .attrTween('d', () => {
                        const next = belowD as string;
                        const prev = below.attr('d') ?? next;

                        return interpolatePath(prev, next);
                    })
                    .attr('fill', this.color);

                if (clipAboveD) {
                    const clipAbove = select(this.$refs.clipAbove);
                    clipAbove
                        .transition()
                        .duration(duration)
                        .attrTween('d', () => {
                            const next = clipAboveD as string;
                            const prev = clipAbove.attr('d') ?? next;

                            return interpolatePath(prev, next);
                        });
                    below.attr('clip-path', this.aboveUid.svgUrl);
                } else {
                    below.attr('clip-path', null);
                }
            } else {
                below
                    .attr('clip-path', null)
                    .attr('fill', 'none');
            }

            const above = select(this.$refs.above);
            const aboveD = this.areaAbove(inputs);

            if (aboveD) {
                const clipBelowD = this.clipBelow(inputs);

                above.transition()
                    .duration(duration)
                    .attrTween('d', () => {
                        const next = aboveD as string;
                        const prev = above.attr('d') ?? next;

                        return interpolatePath(prev, next);
                    })
                    .attr('fill', this.color);

                if (clipBelowD) {
                    const clipBelow = select(this.$refs.clipBelow);
                    clipBelow
                        .transition()
                        .duration(duration)
                        .attrTween('d', () => {
                            const next = clipBelowD as string;
                            const prev = clipBelow.attr('d') ?? next;

                            return interpolatePath(prev, next);
                        });
                    above.attr('clip-path', this.belowUid.svgUrl);
                } else {
                    above.attr('clip-path', null);
                }
            } else {
                above
                    .attr('clip-path', null)
                    .attr('fill', 'none');
            }
        }
    }
});
