


























































import Vue, { PropType } from 'vue';
import _ from 'lodash';
import Color from 'color';
import { AreaChartData, ChartTooltipParams, ChartDrawInputs, BaseChartDatum } from '@/types';
import { CategoricalLegendValue } from '@/model/Metric/Metric';
import { getTailwindColor } from '@/components/getTailwindStyle';
import { utcFormat } from 'd3-time-format';
import ChartBase from './ChartBase.vue';
import LineChartLine from './LineChartLine.vue';
import LegendCategorical from '../Legend/LegendCategorical.vue';

type AreaChartDrawInputs = ChartDrawInputs & {
    data: AreaChartData[];
};

const twGray100 = getTailwindColor('gray', 100);
const twGray400 = getTailwindColor('gray', 400);

export default Vue.extend({
    components: {
        ChartBase,
        LegendCategorical,
        LineChartLine
    },
    props: {
        data: {
            type: Array as PropType<AreaChartData[]>,
            default: () => []
        },
        defaultAreaColor: {
            type: String,
            default: twGray100
        },
        defaultLineColor: {
            type: String,
            default: twGray400
        },
        formatX: {
            type: Function,
            default: null
        },
        formatY: {
            type: Function,
            default: null
        },
        metricTitle: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            hoveredPoint: null as ChartTooltipParams<number> | null
        };
    },
    computed: {
        legendValues(): (CategoricalLegendValue & { area: boolean })[] {
            return _(this.data)
                .filter(({ data }) => data.length > 0)
                .sortBy(({ area }) => !!area)
                .uniqBy(({ location }) => location)
                .map(({ area, color, location }) => ({
                    kind: 'categorical' as 'categorical',
                    color: color ?? (area ? this.defaultAreaColor : this.defaultLineColor),
                    label: location,
                    area: area ?? false
                }))
                .value();
        }
    },
    methods: {
        areaData(data: AreaChartData[]) {
            return data.filter(({ area }) => area);
        },
        lineData(data: AreaChartData[]) {
            return data.filter(({ area }) => !area);
        },
        getHoverPoint(mouseX: Date, data?: BaseChartDatum<number | null>[]): BaseChartDatum<number | null> | null {
            // Add a half day so when we set hours to 0, we're effectively
            // rounding to the nearest day
            const HALF_DAY = 1000 * 60 * 60 * 12;
            const nearestDay = new Date(mouseX.getTime() + HALF_DAY);
            nearestDay.setHours(0, 0, 0, 0);

            if (!data) {
                return null;
            }
            const pointsAtLeastX = data.filter(
                d => d.cdate.getTime() >= nearestDay.getTime()
            );
            const firstPoint = _.minBy(pointsAtLeastX, d => d.cdate.getTime());
            // check if there are no points here, or if the closest point is more than a day away
            if (firstPoint === undefined
                || firstPoint.cdate.getTime() > nearestDay.getTime() + HALF_DAY * 2) {
                return null;
            }
            return firstPoint;
        },
        onHover(mouseX: Date) {
            const areaData = this.data.find(d => d.area)?.data;
            const lineData = this.data.find(d => !d.area)?.data;
            const areaHoverPoint = this.getHoverPoint(mouseX, areaData);
            const lineHoverPoint = this.getHoverPoint(mouseX, lineData);
            const datePoint = lineHoverPoint ?? areaHoverPoint;
            if (!datePoint) {
                this.onLeave();
                return;
            }

            const title = this.legendValues[0].label;
            this.hoveredPoint = {
                title: title ?? '',
                subtitle: utcFormat('%b %e, %Y')(datePoint.cdate),
                points: this.legendValues
                    .map(({ area, color, label }) => {
                        const hoverPoint = (area ? areaHoverPoint : lineHoverPoint) ?? datePoint; // for type
                        const value = hoverPoint.value ?? 0;
                        return {
                            x: hoverPoint.cdate,
                            y: value,
                            label,
                            color: area ? Color(color).darken(0.2).string() : color,
                            value,
                            displayValue: this.formatY(value),
                            inactive: !!lineHoverPoint && area
                        };
                    })
            };
        },
        onLeave() {
            this.hoveredPoint = null;
        }
    }
});
