import _ from 'lodash';
import { ScaleOrdinal, ScaleQuantize } from 'd3-scale';
import * as turf from '@turf/turf';
import { AsyncModel, observable, computed } from '@dha/vue-composition-decorators';
import { Layer, Source } from '@dha/vue-mapbox-gl';
import {
    Option,
    ValueDisplayDatum,
    FilterMetric,
    LonLat,
    GeojsonFeature,
    FilterValue,
    BoundingBox
} from '@/types';

import { DataService } from '@/services/DataService';
import { ParsedGeoLevel, ParsedGeoLevelMeta } from '@/services/DataService/parsers';

import { createSource, getFormattedTooltipData } from './helpers';
import {
    albersMetricDataLayer,
    mercatorMetricDataLayer,
    selectedLayer,
    selectedOutlineLayer,
    selectedOutlineShadowLayer,
    testSiteLayer
} from './layers';
import { AnyMetric } from '../Metric';
import { LegendValue } from '../Metric/Metric';
import { GeographyModel } from '../GeographyModel';
import { Focus } from '../GeographyModel/Geography';
import { IBoundaryLayer, BoundaryLayer, NullBoundaryLayer } from './Geojson/BoundaryLayer';
import { PointLayer } from './Geojson/PointLayer';
import { ISpatialDataset, NullSpatialDataset, SpatialDataset } from './Geojson/SpatialDataset';
import { FilterGroup } from './Filter/FilterGroup';

export interface MapModelParent {
    // State that the MapModel depends on
    metricId: string;
    fipsCode: string;
    date: string;
    geoLevel: ParsedGeoLevel;
    geoLevelOptions: Option<ParsedGeoLevel>[];
    geographyModel: GeographyModel;
    defaultFilterMetric: string;

    metric: AnyMetric;
    metricById: Record<string, AnyMetric>;
    filterOptions: Option[];

    filterMetricData: FilterMetric[];
    fipsMetadata?: ParsedGeoLevelMeta;
    tooltipPersistentMetrics: string[];

    setGeoLevel(geoLevel: string): Promise<void>;
    setFipsCode(fipsCode: string): Promise<void>;
    setFocusFipsCode(fipsCode?: string): Promise<void>;
}

type ColorScale = ScaleQuantize<string, string> | ScaleOrdinal<string, string, string>;
type QuantizeScale = ScaleQuantize<number> | ScaleOrdinal<string, number, number>;
export class MapModel extends AsyncModel {
    dataService: DataService;
    parent: MapModelParent;

    @observable dataError = false;
    @observable isLoading = true;

    @observable.ref data: ISpatialDataset = new NullSpatialDataset();
    @observable.ref dataLayer: IBoundaryLayer = new NullBoundaryLayer();
    @observable.ref sources: Source[] = [];
    @observable.ref layers: Layer[] = [];

    @observable.ref tooltipDatasets: Record<string, ISpatialDataset> = {};
    @observable.ref values: Record<string, string | number | null> = {};
    @observable.ref testLocations: PointLayer = new PointLayer([]);

    filterGroup: FilterGroup;

    constructor(dataService: DataService, parent: MapModelParent) {
        super();
        this.dataService = dataService;
        this.parent = parent;
        this.filterGroup = new FilterGroup(
            dataService,
            this.parent.geographyModel
        );
    }

    async init(filters: FilterValue[]) {
        await this.updateAll();
        this.filterGroup.filters = [];
        await Promise.all(filters.map(filter => this.filterGroup.addFilter(
            this.parent.metricById[filter.metricId],
            filter.values
        )));
    }

    @computed get tooltipPersistentMetrics(): string[] {
        return this.parent.tooltipPersistentMetrics;
    }

    @computed get title(): string {
        return this.parent.metric.title;
    }

    @computed get description(): string {
        return this.parent.metric.description;
    }

    @computed get legendValues(): LegendValue[] {
        return this.data.legendValues;
    }

    @computed get colorScale(): ColorScale {
        return this.parent.metric.getColorScale(this.parent.geoLevel);
    }

    @computed get tooltipSelectedMetricValues(): Record<string, ValueDisplayDatum>[] {
        const metricId = this.parent.metricId;
        return [getFormattedTooltipData(this.tooltipDatasets[metricId])];
    }

    @computed get tooltipPersistentMetricValues(): Record<string, ValueDisplayDatum>[] {
        return this.tooltipPersistentMetrics.map(metricId => getFormattedTooltipData(
            this.tooltipDatasets[metricId]
        ));
    }

    @computed get geojsonWithPropsInjected() {
        if (this.isLoading) {
            return this.dataLayer.getFilteredGeojson(() => false);
        }
        return this.dataLayer.getGeojsonWithPropsInjected(
            this.filters,
            this.data
        );
    }

    @computed get selectedGeojson() {
        return this.dataLayer.getFilteredGeojson(props => props.fips === this.parent.fipsCode);
    }

    @computed get testSiteGeojson() {
        const showTestSites = !!this.parent.metric.metadata.showTestSites;
        return this.testLocations.getFeatureCollection(showTestSites);
    }

    @computed get testSitesShowing() {
        return !!this.parent.metric.metadata.showTestSites
            && this.testSiteGeojson.features.length > 0;
    }

    @computed get filterMetricOptions(): Option[] {
        return this.parent.filterOptions.filter(d => d.isAvailableByGeoLevel?.[this.parent.geoLevel]);
    }

    @computed get stateFocusOptions() {
        return this.parent.geographyModel.stateFocusOptions;
    }
    @computed get countyFocusOptions() {
        return this.parent.geographyModel.countyFocusOptions;
    }

    @computed get showLegendGreaterLessThan() {
        return {
            showLessThan: this.data.hasValuesBelowExtent,
            showGreaterThan: this.data.hasValuesAboveExtent
        };
    }

    @computed get filters() {
        return this.filterGroup.filters;
    }

    async setFilterMetric(index: number, metricId: string) {
        const metric = this.parent.metricById[metricId];
        await this.filterGroup.setFilterMetric(index, metric);
        await this.updateDataLayersAndSources();
    }

    async setFilterValues(index: number, values: [number, number] | string[]) {
        this.filterGroup.setFilterValues(index, values);
        await this.updateDataLayersAndSources();
    }

    async addFilter(
        metricId: string = this.parent.metricId,
        initialValues?: [number, number] | string[]
    ) {
        const metric = this.parent.metricById[metricId];
        await this.filterGroup.addFilter(metric, initialValues);
        await this.updateDataLayersAndSources();
    }

    async updateFilterData(geoLevel: ParsedGeoLevel, focus: Focus) {
        await this.filterGroup.updateFilterData(
            geoLevel,
            focus,
            this.parent.metricById[this.parent.defaultFilterMetric]
        );
    }

    async setMapFocusOn(fipsCode?: string) {
        return this.parent.setFocusFipsCode(fipsCode);
    }

    async setGeoLevel(geoLevel: ParsedGeoLevel) {
        return this.parent.setGeoLevel(geoLevel);
    }

    startLoading() {
        this.isLoading = true;
    }

    async updateAll() {
        try {
            this.isLoading = true;
            const geoLevel = this.parent.geoLevel;
            const focus = this.parent.geographyModel.focus;

            const [
                tooltipDatasets,
                testLocations,
                data,
                dataLayer
            ] = await Promise.all([
                this.getTooltipData(),
                PointLayer.new(this.dataService, focus),
                SpatialDataset.new(this.dataService, this.parent.metric, this.parent.geographyModel),
                BoundaryLayer.new(this.dataService, this.parent.geographyModel),
                this.updateFilterData(geoLevel, focus)
            ]);

            this.data = data;
            this.dataLayer = dataLayer;
            this.tooltipDatasets = tooltipDatasets;
            this.testLocations = testLocations;
            this.isLoading = false;
            this.dataError = false;

            await this.updateDataLayersAndSources();
        } catch (err) {
            console.log('Error loading map data');
            console.error(err);
            this.isLoading = false;
            this.dataError = true;
            this.tooltipDatasets = {};
            this.testLocations = new PointLayer([]);
        }
    }

    async updateData() {
        this.isLoading = true;
        const geoLevel = this.parent.geoLevel;
        const focus = this.parent.geographyModel.focus;

        try {
            if (!this.parent.metric) {
                return;
            }

            const [
                tooltipDatasets
            ] = await Promise.all([
                this.getTooltipData(),
                this.updateFilterData(geoLevel, focus),
                this.data.updateData(
                    this.dataService,
                    this.parent.metric
                )
            ]);

            this.tooltipDatasets = tooltipDatasets;
            this.isLoading = false;
            this.dataError = false;

            await this.updateDataLayersAndSources();
        } catch (err) {
            console.log('Error loading map data');
            console.error(err);
            this.isLoading = false;
            this.dataError = true;
            this.tooltipDatasets = {};
        }
    }

    // this is left as a non-computed property to reduce the number of redundant updates
    async updateDataLayersAndSources(): Promise<void> {
        this.sources = [
            this.parent.geographyModel.focus.geoLevel === 'national'
                ? createSource('albersMetricData', this.geojsonWithPropsInjected)
                : createSource('mercatorMetricData', this.geojsonWithPropsInjected),
            createSource('selected', this.selectedGeojson),
            createSource('testSites', this.testSiteGeojson)
        ];
        this.layers = [
            this.parent.geographyModel.focus.geoLevel === 'national'
                ? albersMetricDataLayer
                : mercatorMetricDataLayer,
            testSiteLayer,
            selectedOutlineShadowLayer,
            selectedOutlineLayer,
            selectedLayer
        ];
    }

    private async getTooltipData() {
        const allTooltipMetrics = this.tooltipPersistentMetrics.concat(this.parent.metricId);
        const datasets = await Promise.all(allTooltipMetrics.map(
            metricId => SpatialDataset.new(
                this.dataService,
                this.parent.metricById[metricId],
                this.parent.geographyModel
            ).then(dataset => ({ metricId, dataset }))
        )).then(d => _(d).keyBy('metricId').mapValues('dataset').value());
        return datasets;
    }

    getEnclosingFeature(point: LonLat): string | undefined {
        const turfPoint = turf.point(point);
        const features: GeojsonFeature[] = (this.dataLayer as BoundaryLayer).geojson.features;
        for (let i = 0; i < features.length; i++) {
            if (features[i].geometry.type === 'MultiPolygon') {
                for (let j = 0; j < features[i].geometry.coordinates.length; j++) {
                    const turfPolygon = turf.polygon(features[i].geometry.coordinates[j]);
                    if (turf.booleanPointInPolygon(turfPoint, turfPolygon)) {
                        return features[i].properties.fips as string;
                    }
                }
            } else {
                const turfPolygon = turf.polygon(features[i].geometry.coordinates);
                if (turf.booleanPointInPolygon(turfPoint, turfPolygon)) {
                    return features[i].properties.fips as string;
                }
            }
        }
        return undefined;
    }

    getFeatureBounds(fips: string): BoundingBox | undefined {
        const features: GeojsonFeature[] = (this.dataLayer as BoundaryLayer).geojson.features;
        const feature = features.find(({ properties }) => properties.fips === fips);
        if (feature) {
            if (feature.geometry.type === 'MultiPolygon') {
                return MapModel.getMultiPolygonExtent(feature.geometry.coordinates);
            }
            return MapModel.getPolygonExtent(feature.geometry.coordinates);
        }
        return undefined;
    }

    private static getPolygonExtent(polygon: [number, number][][]): BoundingBox | undefined {
        let [left, bottom, right, top]: (number | null)[] = [null, null, null, null];
        polygon.forEach((coordinates) => {
            coordinates.forEach(([lon, lat]) => {
                if (left === null || lon < left) {
                    left = lon;
                }
                if (right === null || lon > right) {
                    right = lon;
                }
                if (bottom === null || lat < bottom) {
                    bottom = lat;
                }
                if (top === null || lat > top) {
                    top = lat;
                }
            });
        });
        if (left === null || bottom === null || right === null || top === null) {
            return undefined;
        }
        return [left, bottom, right, top];
    }

    private static getMultiPolygonExtent(
        multiPolygon: [number, number][][][]
    ): BoundingBox | undefined {
        let [left, bottom, right, top]: (number | null)[] = [null, null, null, null];
        multiPolygon.forEach((polygon) => {
            const bounds = MapModel.getPolygonExtent(polygon);
            if (bounds === undefined) {
                return;
            }
            const [newLeft, newBottom, newRight, newTop] = bounds;
            left = (left !== null && left < newLeft) ? left : newLeft;
            bottom = (bottom !== null && bottom < newBottom) ? bottom : newBottom;
            right = (right !== null && right > newRight) ? right : newRight;
            top = (top !== null && top > newTop) ? top : newTop;
        });
        if (left === null || bottom === null || right === null || top === null) {
            return undefined;
        }
        return [left, bottom, right, top];
    }
}
