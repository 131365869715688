import { scaleLinear, scaleBand } from 'd3-scale';
import { BarchartXScaleOptions, BarchartYScaleOptions } from '@/types';

export const getXScale = (options: BarchartXScaleOptions) => {
    const scale = scaleLinear()
        .range([options.leftMargin + options.labelMargin, options.width - options.rightMargin]);

    if (options.dataMin === 0 && options.dataMax === 0) {
        return scale.domain([0, 1]);
    }
    return scale.domain([Math.min(options.dataMin, 0), Math.max(options.dataMax, 0)]);
};

export const getYScale = (options: BarchartYScaleOptions) => scaleBand()
    .range([0, options.height])
    .paddingInner(0.85)
    .paddingOuter(0.4)
    .domain(options.data.map((d) => d.grouping));
