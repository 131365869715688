





























import Vue from 'vue';
import FocusControls from '@/views/FocusControls/FocusControls.vue';
import DhaSection from '@/components/Section/Section.vue';
import BarChart from '@/components/BarChart/BarChart.vue';
import { BarChartDatum } from '@/types';

export default Vue.extend({
    components: {
        FocusControls,
        DhaSection,
        BarChart
    },
    computed: {
        title(): string {
            return this.$appModel.categoricalDataModel.title;
        },
        description(): string {
            return this.$appModel.categoricalDataModel.description;
        },
        dataError(): boolean {
            return this.$appModel.categoricalDataModel.dataError;
        },
        color(): string {
            return this.$appModel.categoricalDataModel.color;
        },
        barchartData(): BarChartDatum[] {
            return this.$appModel.categoricalDataModel.barData;
        },
        autotext(): string {
            return this.$appModel.categoricalDataModel.autotext;
        },
        formatValue() {
            return this.$appModel.categoricalDataModel.formatValue;
        }
    }
});
