


















































































































import Vue from 'vue';
import { paths } from './constants';

export default Vue.extend({
    props: {
        darkScheme: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return { paths };
    },
    methods: {
        onSocialButtonClicked(socialType: string) {
            this.$emit('social-button-click', socialType);
        },
    }
});
