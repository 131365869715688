


























import Vue, { PropType } from 'vue';
import { getTailwindColor } from '@/components/getTailwindStyle';
import { AccordionDatum } from '@/types';

const twGray500 = getTailwindColor('gray', 500);

export default Vue.extend({
    components: {

    },
    props: {
        metric: {
            type: Object as PropType<AccordionDatum>,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        color(): string {
            return this.metric?.color ?? twGray500;
        }
    },
    methods: {
        onClick() {
            this.$emit('click', this.metric.id);
        }
    }
});
