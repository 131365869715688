import { AnyMetric } from '@/model/Metric';
import { DataService } from '@/services/DataService';
import { GeographyModel } from '@/model/GeographyModel';
import { NumericFilter } from './NumericFilter';
import { CategoricalFilter } from './CategoricalFilter';

export { BaseFilter as BaseMapFilter } from './BaseFilter';
export type MapFilter = NumericFilter | CategoricalFilter;
export {
    NumericFilter,
    CategoricalFilter
};

function isStrings(array: string[] | [number, number]): array is string[] {
    return typeof array[0] === 'string';
}

export async function buildFilter(
    dataService: DataService,
    geography: GeographyModel,
    metric: AnyMetric,
    initialValues?: [number, number] | string[]
): Promise<MapFilter> {
    return metric.kind === 'categorical'
        ? CategoricalFilter.new(
            dataService,
            geography,
            metric,
            initialValues && isStrings(initialValues) ? initialValues : undefined
        )
        : NumericFilter.new(
            dataService,
            geography,
            metric,
            initialValues && !isStrings(initialValues) ? initialValues : undefined
        );
}
