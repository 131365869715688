import _ from 'lodash';
import {
    ParsedMetadata,
    parseMetadata,
    ParsedGeojsonFeatureCollection,
    parseGeojsonFeatureCollection,
    ParsedMetricMetadata,
    ParsedMetricByDateData,
    ParsedMetricByFipsData,
    ParsedGeoLevel,
    parseMetricByFipsDataArray,
    parseMetricByDateDataArray,
    parseAllMetricsDataArray,
    parseGeoLevelMetaArray,
    parseGroupedMetricDataArray,
    ParsedGroupedMetricData,
    ParsedGeoLevelMeta,
    ParsedTestLocation,
    parseTestLocationArray
} from './parsers';
import { DataSource, QueryLatestFilters, QueryGroupedFilters, QueryForFipsFilters, GeoLevelFilter } from './sources/DataSource';

export type GeoLevelMeta = ParsedGeoLevelMeta & {
    geoLevel: ParsedGeoLevel;
}
export class DataService {
    dataSource: DataSource;

    private metricMetadataByMetricId: Record<string, ParsedMetricMetadata> = {};

    constructor(dataSource: DataSource) {
        this.dataSource = dataSource;
    }

    async init() {
        await this.dataSource.init();
        const [
            metadata
        ] = await Promise.all([
            this.dataSource.getMetadata()
        ]);
        this.metricMetadataByMetricId = _(metadata.metricsByGrouping)
            .flatMap(grouping => grouping.metrics)
            .keyBy(metric => metric.metricId)
            .value();
    }

    async getGeojson(level: ParsedGeoLevel, focus: {geoLevel: ParsedGeoLevel; fipsCode: string}): Promise<ParsedGeojsonFeatureCollection> {
        const rawData = await this.dataSource.getGeojson(level, focus);
        const parsed = parseGeojsonFeatureCollection(rawData);
        return parsed;
    }

    async getGeoLevelMeta(geoLevel: ParsedGeoLevel, filter?: GeoLevelFilter): Promise<GeoLevelMeta[]> {
        const rawData = await this.dataSource.getGeoLevelMeta(geoLevel, filter);
        const parsed = parseGeoLevelMetaArray(rawData);

        // TODO: Is this ok? Or should we have the API add this property
        return parsed.map(meta => ({ ...meta, geoLevel }));
    }
    async getTestLocations(filter: GeoLevelFilter): Promise<ParsedTestLocation[]> {
        const rawData = await this.dataSource.getTestLocations(filter);
        return parseTestLocationArray(rawData);
    }
    async getMetadata(): Promise<ParsedMetadata> {
        const rawData = await this.dataSource.getMetadata();
        return parseMetadata(rawData);
    }

    async getAllMetricsValues(fips: string, geoLevel: ParsedGeoLevel): Promise<Record<string, string | number | null>> {
        if (fips === '99999') {
            // eslint-disable-next-line
            geoLevel = 'national';
        }
        const rawData = await this.dataSource.getAllMetricsData(fips, geoLevel);
        const parsed = parseAllMetricsDataArray(rawData);
        return _(parsed)
            .keyBy(v => v.metric)
            .mapValues(v => v.value)
            .value();
    }

    async getLatestMetricData(metricId: string, filters: QueryLatestFilters): Promise<ParsedMetricByFipsData[]> {
        const metadata = this.metricMetadataByMetricId[metricId];
        const rawData = await this.dataSource.getLatestMetricData(metadata, filters);
        return parseMetricByFipsDataArray(rawData);
    }

    async getMetricDataForFips(metricId: string, filters: QueryForFipsFilters): Promise<ParsedMetricByDateData[]> {
        if (filters.fipsCode === '99999') {
            // eslint-disable-next-line
            filters = {
                ...filters,
                geoLevel: 'national'
            };
        }
        const metadata = this.metricMetadataByMetricId[metricId];
        const rawData = await this.dataSource.getMetricDataForFips(metadata, filters);

        // TODO: Figure out a cleaner way to handle this data _sometimes_ having dates
        const filteredData = rawData.filter(r => r.cdate !== undefined);
        return parseMetricByDateDataArray(filteredData);
    }

    async getGroupedMetricDataForDateFips(
        metricId: string,
        filters: QueryGroupedFilters
    ): Promise<ParsedGroupedMetricData[]> {
        if (filters.fipsCode === '99999') {
            // eslint-disable-next-line
            filters = {
                ...filters,
                geoLevel: 'national'
            };
        }
        const metadata = this.metricMetadataByMetricId[metricId];
        const rawData = await this.dataSource.getGroupedMetricDataForDateFips(metadata, filters);
        return parseGroupedMetricDataArray(rawData);
    }
}
