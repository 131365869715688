





















































import Vue, { PropType } from 'vue';
import _ from 'lodash';
import ResizeObserver from 'resize-observer-polyfill';
import { axisBottom } from 'd3-axis';
import { ScaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { utcFormat } from 'd3-time-format';
import { ChartTooltipParams, ExtentValue, HeatmapData, HeatmapDatum, VueWithTypedRefs } from '@/types';
import LegendCategorical from '@/components/Legend/LegendCategorical.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { LegendValue } from '@/model/Metric/Metric';
import { getXScale } from './helpers';

type HeatmapDrawInputs = {
    data: HeatmapData;
    xScale: ScaleTime<number, number> | null;
    height: number;
}

export default (Vue as VueWithTypedRefs<{
    svgContainer: typeof HTMLDivElement;
    svg: typeof SVGElement;
    points: typeof SVGGElement;
    overlays: typeof SVGGElement;
    xAxis: typeof SVGGElement;
}>).extend({
    components: {
        LegendCategorical,
        Tooltip
    },
    props: {
        data: {
            type: Object as () => HeatmapData,
            default: () => ({ data: [], location: '' })
        },
        dateRange: {
            type: Object as () => { start: Date; end: Date },
            default: null
        },
        leftMargin: {
            type: Number,
            default: 0
        },
        rightMargin: {
            type: Number,
            default: 0
        },
        topMargin: {
            type: Number,
            default: 0
        },
        bottomMargin: {
            type: Number,
            default: 40
        },
        extent: {
            type: Object as () => Record<string, ExtentValue>,
            default: () => ({})
        },
        formatX: {
            type: Function as PropType<(value: Date) => string>,
            default: d => utcFormat('%B')(d)
        },
        metricTitle: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            width: 0,
            height: 0,
            resizeObserver: null as ResizeObserver | null,
            hoveredPoint: null as ChartTooltipParams<string> | null
        };
    },
    computed: {
        legendValues(): LegendValue[] {
            return _.values(this.extent).map(({ label, color }) => ({
                kind: 'categorical',
                label,
                color
            }));
        },
        xScale(): ScaleTime<number, number> | null {
            if (!this.data) {
                return null;
            }
            return getXScale(this.data.data, this.dateRange, this.width, this.leftMargin, this.rightMargin);
        },
        barSpace(): number {
            if (!this.xScale) return 0;

            const firstDate = this.xScale.domain()[0];
            firstDate.setDate(firstDate.getDate() + 1);
            return (this.xScale(firstDate) - this.leftMargin);
        },
        drawInputs(): HeatmapDrawInputs {
            return {
                data: this.data,
                xScale: this.xScale,
                height: this.height
            };
        },
        tooltipTranslate(): string | null {
            if (!this.hoveredPoint || this.hoveredPoint.points.length === 0 || !this.xScale) {
                return null;
            }
            const x = this.xScale(this.hoveredPoint.points[0].x);
            const xMiddle = (this.xScale.range()[1] + this.xScale.range()[0]) / 2;
            const xTranslate = x > xMiddle ? '-100%' : '0';
            return `translateX(${xTranslate})`;
        },
        tooltipX(): string {
            if (!this.hoveredPoint || this.hoveredPoint.points.length === 0 || !this.xScale) {
                return '0';
            }
            return `${this.xScale(this.hoveredPoint.points[0].x)}px`;
        }
    },
    watch: {
        async drawInputs() {
            await this.$nextTick();
            this.draw();
        }
    },
    mounted() {
        this.resizeObserver = new ResizeObserver(() => {
            this.updateSize();
        });

        this.resizeObserver.observe(this.$el);
        this.updateSize();
    },
    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
    methods: {
        draw() {
            select(this.$refs.svg)
                .attr('width', this.width)
                .attr('height', this.height);
            const filteredData = this.data.data
                .filter(
                    d => !this.xScale
                        || d.cdate.getTime() >= this.xScale.domain()[0].getTime()
                );
            this.drawXAxis();
            this.drawDataPoints(filteredData);
            this.drawOverlays(filteredData);
        },
        drawXAxis() {
            if (this.xScale) {
                const xAxis = axisBottom<Date>(this.xScale)
                    .tickFormat(d => this.formatX(d));
                select(this.$refs.xAxis).call(xAxis);
            }
        },
        drawDataPoints(data: HeatmapDatum[]) {
            const xScale = this.xScale;
            if (xScale) {
                const barWidth = this.barSpace / 2;

                select(this.$refs.points)
                    .selectAll('rect')
                    .data(data)
                    .join('rect')
                    .attr('x', d => xScale(d.cdate) - barWidth / 2)
                    .attr('y', this.topMargin)
                    .attr('width', barWidth)
                    .attr('height', this.height - this.topMargin - this.bottomMargin)
                    .attr('fill', d => this.extent[d.value]?.color || 'white');
            }
        },
        drawOverlays(data: HeatmapDatum[]) {
            const xScale = this.xScale;
            if (xScale) {
                select(this.$refs.overlays)
                    .selectAll('rect.overlay')
                    .data(data)
                    .join('rect')
                    .classed('overlay', true)
                    .attr('x', d => xScale(d.cdate) - this.barSpace / 2)
                    .attr('y', this.topMargin)
                    .attr('width', this.barSpace)
                    .attr('height', this.height - this.topMargin - this.bottomMargin)
                    .on('mousemove', (event, d) => {
                        this.onHover(d);
                    });
            }
        },
        onHover: _.throttle(function (this: any, point: HeatmapDatum) {
            this.setHoveredPoint(point);
        }, 15, { trailing: false }),
        setHoveredPoint(point: HeatmapDatum) {
            const value = this.extent[point.value]?.label ?? point.value;
            this.hoveredPoint = {
                title: this.data.location,
                subtitle: utcFormat('%b %e, %Y')(point.cdate),
                points: [{
                    x: point.cdate,
                    y: point.value,
                    label: this.metricTitle,
                    value,
                    displayValue: value
                }]
            };
        },
        onMouseleave() {
            this.hoveredPoint = null;
        },
        updateSize() {
            const { width, height } = this.$refs.svgContainer.getBoundingClientRect();
            if (this.width !== width) {
                this.width = width;
            }
            if (this.height !== height) {
                this.height = height;
            }
        }
    }
});
