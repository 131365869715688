


















import Vue, { PropType } from 'vue';

export default Vue.extend({
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: {
            type: String as PropType<string | null>,
            default: null
        }
    },
    methods: {
        onClick() {
            console.log('Sidebar onclick');
        }
    }
});
