












import Vue from 'vue';
import DhaSelect from '@/components/Select/Select.vue';
import { Option } from '@/types';

export default Vue.extend({
    components: {
        DhaSelect
    },
    computed: {
        metricOptions(): Option<string>[] {
            return this.$appModel.accordionModel.metricsByGroup;
        },
        selectedMetric(): string {
            return this.$appModel.baseMetricId;
        }
    },
    methods: {
        metricOptionSelected(option: string) {
            this.$appModel.setMetricId(option);
        }
    }
});
