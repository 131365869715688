import { render, staticRenderFns } from "./LegendCategorical.vue?vue&type=template&id=79b38018&scoped=true&"
import script from "./LegendCategorical.vue?vue&type=script&lang=ts&"
export * from "./LegendCategorical.vue?vue&type=script&lang=ts&"
import style0 from "./LegendCategorical.vue?vue&type=style&index=0&id=79b38018&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79b38018",
  null
  
)

export default component.exports