import { FilteredMapboxResult, LonLat, RawMapboxResult, SearchResult } from '@/types';
import { DataService, GeoLevelMeta } from '@/services/DataService';
import { abbrevToFips, fipsToAbbrev } from './local';
import { startsWithExactWord, localSearchString, resultIsState } from './helpers';

/**
 * Service for fetching and aggregating geographic search results based on a provided search query
 */
export default class RegionSearchService {
    private readonly MAX_RESULTS: number = 10;
    private readonly MAX_LOCAL_COMBINED_RESULTS: number = 5;

    readonly localData: DataService;
    readonly baseUrl: string;
    readonly accessToken: string;
    readonly regionTypes: string;

    constructor(
        localData: DataService,
        options?: { baseUrl?: string; accessToken?: string; regionTypes?: string }
    ) {
        this.localData = localData;
        this.baseUrl = options?.baseUrl ?? 'https://api.mapbox.com/geocoding/v5/mapbox.places';
        this.accessToken = options?.accessToken ?? process.env.MAPBOX_ACCESS_TOKEN;
        this.regionTypes = options?.regionTypes
            ?? 'region,postcode,place,locality,neighborhood,address';
    }

    /**
     * Fetch region search results from both Mapbox and local sources of data
     * @param searchText The search string to filter results by
     */
    async getRegionsFromSearch(searchText: string): Promise<SearchResult[]> {
        let results: SearchResult[] = [];
        await Promise.all([
            this.getMapboxSearchResults(searchText),
            this.getLocalSearchResults(searchText)
        ]).then(([mapboxSearchResults, localSearchResults]) => {
            if (localSearchResults.length === 0) {
                results = mapboxSearchResults;
            } else {
                const stateResults = mapboxSearchResults.filter(res => resultIsState(res));
                const numNonStateResults = Math.max(0, this.MAX_RESULTS - localSearchResults.length - stateResults.length);
                const nonStateResults = mapboxSearchResults
                    .filter(res => !resultIsState(res))
                    .slice(0, numNonStateResults);
                results = [...stateResults, ...localSearchResults, ...nonStateResults];
            }
        }).catch((error) => console.error(error));
        return results;
    }

    /**
     * Return an array of filtered search results from the Mapbox geocoding API
     * @param searchText The search string to filter results by
     */
    private async getMapboxSearchResults(searchText: string): Promise<SearchResult[]> {
        const urlText = encodeURIComponent(searchText);
        const response = await fetch(`${this.baseUrl}/${urlText}.json?access_token=${
            this.accessToken
        }&country=US&limit=10&types=${this.regionTypes}`);
        const data: { features: RawMapboxResult[] } = await response.json();
        return data.features
            .filter(feature => RegionSearchService.isSearchableFeature(feature))
            .map(feature => {
                const stateFips = RegionSearchService
                    .getFeatureFips(feature as FilteredMapboxResult);
                const searchResult: SearchResult = {
                    name: feature.place_name,
                    geoLevel: 'state',
                    stateFips,
                };
                if (feature.id.startsWith('place') || feature.id.startsWith('locality')) {
                    searchResult.geoLevel = 'county';
                    searchResult.center = feature.center as LonLat;
                } else if (!feature.id.startsWith('region')) {
                    searchResult.geoLevel = 'tract';
                    searchResult.center = feature.center as LonLat;
                }
                return searchResult;
            });
    }

    /**
     * Return an array of filtered search results from local data (e.g. counties and tracts)
     * @param searchText The search string to filter results by
     */
    private async getLocalSearchResults(searchText: string): Promise<SearchResult[]> {
        const query = searchText.toLowerCase();
        const allValues = await this.localData.getGeoLevelMeta('county');

        // go through all values and find the relevant ones
        const { exactWordMatch, startsWithMatch, containsMatch } = allValues
        // filter those that include `query`
            .filter(i => localSearchString(i).includes(query))
        // split filtered results by `startsWithExactWord`, `startsWith` and `contains`
            .reduce((res, i) => {
                const exactWord = startsWithExactWord(i, query);
                const startsWith = localSearchString(i).startsWith(query);
                res[exactWord
                    ? 'exactWordMatch'
                    : startsWith
                        ? 'startsWithMatch'
                        : 'containsMatch'
                ].push(i);
                return res;
            }, {
                exactWordMatch: [] as GeoLevelMeta[],
                startsWithMatch: [] as GeoLevelMeta[],
                containsMatch: [] as GeoLevelMeta[],
            });

        // sort the result sets
        exactWordMatch.sort(this.sortByName);
        startsWithMatch.sort(this.sortByName);
        containsMatch.sort(this.sortByName);

        // get the results that don't start with an exact word
        const numNonExactResults = Math.max(0, this.MAX_LOCAL_COMBINED_RESULTS - exactWordMatch.length);
        const nonExactResults = startsWithMatch
            .concat(containsMatch)
            .slice(0, numNonExactResults);

        return exactWordMatch
            .concat(nonExactResults) // merge results together
            .map(county => ({
                name: `${county.name}, ${fipsToAbbrev[county.stateFIPS]}`,
                geoLevel: 'county',
                stateFips: county.stateFIPS,
                countyFips: county.countyFIPS
            }));
    }

    // eslint-disable-next-line class-methods-use-this
    private sortByName(a: GeoLevelMeta, b: GeoLevelMeta): number {
        return (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
    }

    private static isSearchableFeature(feature: RawMapboxResult): boolean {
        if (feature.id.startsWith('region')) {
            if (feature.properties.short_code === undefined) {
                return false;
            }
            return RegionSearchService.getStateFips(feature.properties.short_code) !== undefined;
        }

        const regionLevel = feature.context.find((level) => level.id.startsWith('region'));
        if (regionLevel === undefined || regionLevel.short_code === undefined) {
            return false;
        }
        return RegionSearchService.getStateFips(regionLevel.short_code) !== undefined;
    }

    private static getFeatureFips(feature: FilteredMapboxResult): string {
        if (feature.id.startsWith('region')) {
            return RegionSearchService.getStateFips(feature.properties.short_code);
        }

        const regionLevel = feature.context
            .find((level) => level.id.startsWith('region')) as { short_code: string };
        return RegionSearchService.getStateFips(regionLevel.short_code);
    }

    private static getStateFips(shortCode: string) {
        return abbrevToFips[shortCode.substr(shortCode.length - 2)];
    }
}
