





















import Vue from 'vue';
import DhaPopover from '@/components/Popover/Popover.vue';

export default Vue.extend({
    components: {
        DhaPopover
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        align: {
            type: String,
            default: 'middle'
        },
        verticalAlign: {
            type: String,
            default: 'bottom',
        },
        duration: {
            type: Number,
            default: 1500
        }
    },
    watch: {
        value(value: boolean) {
            if (value) {
                setTimeout(() => {
                    this.$emit('input', false);
                }, this.duration);
            }
        }
    },
    methods: {
        onInput(value: boolean) {
            this.$emit('input', value);
        }
    }
});
