import _ from 'lodash';
import { DataService } from '@/services/DataService';
import { ParsedGeoLevel, ParsedGeoLevelMeta, ParsedMetadata } from '@/services/DataService/parsers';
import { BarChartDatum } from '@/types';
import { observable, computed, ReactiveObject } from '@dha/vue-composition-decorators';
import format from '@dha/number-format';
import { utcFormat } from 'd3-time-format';
import { getTailwindColor } from '@/components/getTailwindStyle';
import { GeographyModel } from './GeographyModel';
import { numericalMetricData } from './helpers';
import { Metric } from './Metric';
import formatAutotext from './autotext';

export interface CategoricalDataModelParent {
    isLoaded: boolean;
    metricId: string;
    geoLevel: ParsedGeoLevel;
    fipsCode: string;
    date: string;
    metadata?: ParsedMetadata;
    fipsMetadata?: ParsedGeoLevelMeta;
    metric?: Metric;
    geographyModel: GeographyModel;

    setFipsCode(fipsCode: string): Promise<void>;
    setFocusFipsCode(fipsCode?: string): Promise<void>;
    currentGeoLevelLabels: {singular: string; plural: string};
}

export class CategoricalDataModel extends ReactiveObject {
    dataService: DataService;
    parent: CategoricalDataModelParent

    @observable.ref dataError = false;
    @observable.ref barData: BarChartDatum[] = [];

    constructor(dataService: DataService, parent: CategoricalDataModelParent) {
        super();
        this.dataService = dataService;
        this.parent = parent;
    }

    async init() {
        await this.updateData();
    }

    /**
     * Get the region that we want to display breakdown data for. If we're focusing
     * a region, we want data for it, otherwise show the current selection
     *
     * In particular, this is useful for tract-level data since we never
     * have categorical data for a tract, but we _do_ for the focused state/county
     */
    private getFocusedRegion(): { geoLevel: ParsedGeoLevel; fipsCode: string; name: string } {
        return {
            geoLevel: this.parent.geographyModel.focus.geoLevel,
            fipsCode: this.parent.geographyModel.focus.fips,
            name: this.parent.geographyModel.focus.name
        };
    }

    async updateData() {
        try {
            const groupings = ['Q1', 'Q2', 'Q3', 'Q4', 'Q5'];
            const labels = [
                'Very High',
                'High',
                'Moderate',
                'Low',
                'Very Low'
            ];

            const { geoLevel, fipsCode } = this.getFocusedRegion();
            const barData = await this.dataService.getGroupedMetricDataForDateFips(
                this.parent.metricId,
                { geoLevel, fipsCode }
            );

            // check if the data is only undefined | null
            if (barData.length === 0 || _.every(barData, d => _.isNil(d.value))) {
                this.barData = [];
                return;
            }

            const numericBarData = _(numericalMetricData(barData))
                .keyBy('grouping')
                .mapValues('value')
                .value();
            this.barData = groupings.map((grouping, i) => ({
                grouping: labels[i],
                value: numericBarData[grouping],
                unavailableText: barData[i].hasQuintile
            }));
            this.dataError = false;
        } catch (err) {
            this.barData = [];
            this.dataError = true;
            console.log('Error fetching bar chart data');
            console.error(err);
        }
    }

    async setFocusOn(fipsCode?: string) {
        return this.parent.setFocusFipsCode(fipsCode);
    }

    @computed get title(): string {
        const metric = this.parent.metric;
        const metaTitle = metric?.metadata?.sectionTitles?.barTitle;
        return metaTitle && metaTitle !== ''
            ? metaTitle
            : `Breakdown of ${metric?.metadata?.label ?? this.parent.metricId} by Vulnerability`;
        // return `${metric?.metadata?.label ?? this.parent.metricId} For ${this.parent.currentGeoLevelLabels.plural} At Each Overall Vulnerability Level Index`;
    }

    @computed get description(): string {
        const metric = this.parent.metric;
        return metric?.metadata?.textBlocks?.[1] ?? '';
    }

    @computed get color(): string {
        const metric = this.parent.metric;
        return metric?.metadata?.color || getTailwindColor('gray', 500);
    }

    /* eslint-disable class-methods-use-this */
    @computed get formatX() {
        return utcFormat('%b%e');
    }

    @computed get formatValue() {
        const metric = this.parent.metric;
        return metric?.groupedFormat.bind(metric)
            ?? metric?.format.bind(metric)
            ?? ((value: number) => format(value));
    }

    @computed get autotext(): string {
        const metric = this.parent.metric;
        if (!metric?.metadata?.autotext) return '';
        const { name } = this.getFocusedRegion();
        const options = {
            metric: metric.metadata.label,
            location: name,
            formatter: this.formatValue
        };
        return formatAutotext(
            metric.metadata.autotext,
            this.barData,
            options
        );
    }

    @computed get focusOptions() {
        return this.parent.geographyModel.stateFocusOptions;
    }
}
