export function replaceAtIndex<T>(arr: T[], index: number, newVal: T): T[] {
    return [
        ...arr.slice(0, index),
        newVal,
        ...arr.slice(index + 1)
    ];
}

export function append<T>(arr: T[], newVal: T): T[] {
    return [
        ...arr,
        newVal
    ];
}
