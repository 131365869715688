










































































































































































































































































import '@/assets/tailwind.css';
import '@dha/vue-mapbox-gl/dist/vue-mapbox-gl.css';
import '@dha/vue-search-bar/dist/vue-search-bar.css';

import Vue from 'vue';
import _ from 'lodash';
import { format } from 'd3-format';
import { Map } from 'mapbox-gl';
import ResizeObserver from 'resize-observer-polyfill';
import MapboxGlMap, { Layer, Source } from '@dha/vue-mapbox-gl';
import SearchBar from '@dha/vue-search-bar';

import FilterGroup from '@/components/FilterGroup/FilterGroup.vue';
import DhaSection from '@/components/Section/Section.vue';

import {
    GeojsonFeature,
    Option,
    VueWithTypedRefs,
    ValueDisplayDatum,
    BoundingBox,
    SearchResult,
    CheckboxDatum
} from '@/types';

import { LegendValue } from '@/model/Metric/Metric';
import { Focus } from '@/model/GeographyModel/Geography';
import { MapFilter } from '@/model/MapModel/Filter';
import { ParsedGeoLevel } from '@/services/DataService/parsers';
import CheckboxGroup from '@/components/CheckboxGroup/CheckboxGroup.vue';
import LegendThreshold from '@/components/Legend/LegendThreshold.vue';
import LegendCategorical from '@/components/Legend/LegendCategorical.vue';
import LegendTestSites from '@/components/Legend/LegendTestSites.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { GeoLevelMeta } from '@/services/DataService';
import MapGeographyControls from './MapGeographyControls.vue';
import { paths } from './constants';

type ComponentData = {
    // TODO: Real type
    hoveredFeature: Record<string, any> | null;
    tooltipAlignment: {
        horizontal: 'left' | 'right';
        vertical: 'bottom' | 'top';
    };
    tooltipFlipThreshold: number; // pixels from top of screen
    lastFocus: Focus | undefined;
    resizeObserver: ResizeObserver | null;
    lastGeoLevel?: string;
    paths: Record<string, string>;
}

export default (Vue as VueWithTypedRefs<{
    map: typeof MapboxGlMap;
    search: typeof SearchBar;
}>).extend({
    components: {
        CheckboxGroup,
        FilterGroup,
        MapboxGlMap,
        MapGeographyControls,
        LegendThreshold,
        LegendCategorical,
        LegendTestSites,
        Tooltip,
        DhaSection,
        SearchBar
    },
    props: {
        searchCallback: {
            type: Function,
            required: true,
        }
    },
    data(): ComponentData {
        return {
            hoveredFeature: null,
            tooltipAlignment: {
                horizontal: 'left',
                vertical: 'bottom'
            },
            tooltipFlipThreshold: 300,
            lastFocus: undefined,
            resizeObserver: null,
            lastGeoLevel: undefined,
            paths,
        };
    },
    computed: {
        accessToken(): string {
            return process.env.MAPBOX_ACCESS_TOKEN;
        },
        dataError(): boolean {
            return this.$appModel.mapModel.dataError;
        },
        title(): string {
            return this.$appModel.mapModel.title;
        },
        description(): string {
            return this.$appModel.mapModel.description;
        },
        geoLevel(): ParsedGeoLevel {
            return this.$appModel.geoLevel;
        },
        mapStyle(): string {
            return (this.focus.focus?.geoLevel === 'national')
                ? 'mapbox://styles/developmentdha/ckmuzuk5l01t017n0osfv3lwc'
                : 'mapbox://styles/developmentdha/ckige1mf85hwt19pbu73b60oh';
        },
        filterMetricOptions(): Option[] {
            return this.$appModel.mapModel.filterMetricOptions;
        },
        filters(): MapFilter[] {
            return this.$appModel.mapModel.filters;
        },
        isFiltered(): boolean {
            return this.filters.some(filter => filter.isFiltered());
        },
        filterSelectedRegions(): GeoLevelMeta[] {
            return [this.$appModel.geographyModel.currentFipsMetadata]
                .filter(v => !_.isNil(v)) as GeoLevelMeta[];
        },
        focus(): { isLoading: boolean; focus: Focus | undefined } {
            // We have to watch loading so we only animate when the map is loaded
            // TODO: Need to find a better way...
            return {
                isLoading: this.$appModel.mapModel.isLoading,
                focus: this.$appModel.geographyModel.focus
            };
        },
        metricTitle(): string {
            return this.$appModel.mapModel.data.metricTitle;
        },
        legendIsThreshold(): boolean {
            return this.$appModel.mapModel.data.dataType !== 'categorical';
        },
        legendValues(): LegendValue[] {
            return this.$appModel.mapModel.data.legendValues;
        },
        testSitesShowing(): boolean {
            return this.$appModel.mapModel.testSitesShowing;
        },
        layers(): Layer[] {
            return this.$appModel.mapModel.dataError
                ? []
                : this.$appModel.mapModel.layers;
        },
        sources(): Source[] {
            return this.$appModel.mapModel.sources;
        },
        tooltipPersistentMetricValues(): ValueDisplayDatum[] {
            if (!this.hoveredFeature) return [];
            const hovered = this.hoveredFeature;
            const values = _.compact(
                this.$appModel.mapModel.tooltipPersistentMetricValues
                    .map(formattedData => formattedData[hovered.fips])
            );
            if (!_.isNil(hovered.population)) {
                return [
                    ...values,
                    {
                        label: 'Population',
                        value: hovered.population,
                        displayValue: format(',')(hovered.population)
                    }
                ];
            }
            return values;
        },
        tooltipSelectedMetricValues(): ValueDisplayDatum[] {
            if (!this.hoveredFeature) return [];
            const hovered = this.hoveredFeature;
            return _.compact(
                this.$appModel.mapModel.tooltipSelectedMetricValues
                    .map(formattedData => formattedData[hovered.fips])
            );
        },
        showLegendGreaterLessThan(): Record<string, boolean> {
            return this.$appModel.mapModel.showLegendGreaterLessThan;
        },
        checkboxes(): CheckboxDatum[] {
            return this.$appModel.checkboxStatus;
        },
        checkboxesTitle(): string {
            return this.$appModel.checkboxMetrics[0]?.groupTitle ?? '';
        }
    },
    watch: {
        geoLevel(currLevel: ParsedGeoLevel, prevLevel: ParsedGeoLevel) {
            if (currLevel === 'tract' && this.lastFocus?.geoLevel === 'national') {
                this.$refs.map.zoomToUsaBounds({ animate: false, padding: 0 });
            }
            this.lastGeoLevel = prevLevel;
        },
        focus: {
            async handler(this: any, value: { isLoading: boolean; focus: Focus }): Promise<void> {
                const { isLoading, focus } = value;
                if (isLoading) {
                    return;
                }
                if (focus.fips === this.lastFocus?.fips) {
                    return;
                }

                const animate = focus.geoLevel !== this.lastFocus?.geoLevel
                    || focus.fips !== this.lastFocus?.fips;
                if (focus.geoLevel === 'national') {
                    // eslint-disable-next-line
                    // @ts-ignore
                    this.$refs.map.zoomToUsaBounds({ animate: false, padding: 5 });
                } else if (this.$appModel.mapModel.geojsonWithPropsInjected) {
                    if (this.lastFocus?.geoLevel === 'national') {
                        this.$refs.map.zoomToUsaBounds({ animate: false, padding: 0 });
                    }
                    // eslint-disable-next-line
                    // @ts-ignore
                    this.$refs.map.zoomToFeatures(
                        this.$appModel.mapModel.geojsonWithPropsInjected.features,
                        { wrapUsa: true, animate, padding: 5 }
                    );
                }
                this.lastFocus = focus;
            }
        }
    // For whatever reason, Vue types are breaking when we have watch
    // functions despite them being explicitly typed. So by casting this
    // object to any, we at least get types in the rest of the component
    } as any,
    mounted() {
        this.resizeObserver = new ResizeObserver(() => {
            this.setMapControls();
        });

        this.resizeObserver.observe(this.$el);
        this.setMapControls();
    },
    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
    methods: {
        setMapControls() {
            // eslint-disable-next-line
            // @ts-ignore
            const mapbox = this.$refs.map.map as any;
            if (mapbox && this.$screens.lg) {
                mapbox.dragPan.enable();
            } else {
                mapbox.dragPan.disable();
            }
        },
        onMapHover({ event, features, quadrant }: { event: {originalEvent: MouseEvent}; features?: GeojsonFeature[]; quadrant?: string }): void {
            if (features?.length) {
                this.hoveredFeature = features[0].properties;
            } else {
                this.hoveredFeature = null;
            }
            this.tooltipAlignment.horizontal = quadrant?.includes('left')
                ? 'left'
                : 'right';
            this.tooltipAlignment.vertical = event.originalEvent?.clientY < this.tooltipFlipThreshold
                ? 'top'
                : 'bottom';
        },
        onMapClick(features?: (GeojsonFeature<{fips: string}> & {source: string})[]): void {
            const dataFeatures = features?.filter(f => f.source.endsWith('MetricData'));
            if (dataFeatures?.length) {
                const props = dataFeatures[0]?.properties;
                const fipsCode = props.fips;
                if (fipsCode) {
                    this.$appModel.setFipsCode(fipsCode);
                }
            }
        },
        onMapLoaded(map: Map): void {
            // Disable scroll zooming entirely
            map.scrollZoom.disable();

            const initFips = this.focus.focus?.fips;
            if (this.focus.isLoading || !initFips || initFips === '99999') {
                // eslint-disable-next-line
                // @ts-ignore
                this.$refs.map.zoomToUsaBounds({ animate: false, padding: 5 });
            } else {
                // if the focus/geojson are ready by the time the map has loaded then
                // we'll end up here - otherwise we'll hit the focus watcher above
                // eslint-disable-next-line
                // @ts-ignore
                this.$refs.map.zoomToFeatures(
                    this.$appModel.mapModel.geojsonWithPropsInjected.features,
                    { wrapUsa: true, animate: true, padding: 5 }
                );
            }
        },
        onFilterMetricSelected(filterIndex: number, metricId: string): void {
            this.$appModel.mapModel.setFilterMetric(filterIndex, metricId);
        },
        onFilterValuesChanged(filterIndex: number, values: [number, number] | string[]): void {
            this.$appModel.mapModel.setFilterValues(filterIndex, values);
        },
        onSearchItemSelected(result: SearchResult) {
            this.$emit('search-item-selected', result);
        },
        zoomToBounds(bounds: BoundingBox) {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.map.map.fitBounds(bounds);
        },
        clearSearchInput() {
            if (this.$refs.search) {
                // eslint-disable-next-line
                // @ts-ignore
                this.$refs.search.clearSearchInput();
            }
        },
        async resetFilter() {
            this.filters.forEach(filter => filter.clear());
            await this.$appModel.mapModel.updateDataLayersAndSources();
        },
        zoomIn() {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.map.map.zoomIn();
        },
        zoomOut() {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.map.map.zoomOut();
        },
        zoomToUsaBounds() {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.map.zoomToUsaBounds({ animate: true, padding: 5 });
            this.$emit('zoom-to-bounds');
        },
        async onCheckboxClick(value: string) {
            await this.$appModel.setChecked(value);
        }
    }
});
