






































































































import { ValueDisplayDatum } from '@/types';
import ValueDisplay from '@/components/ValueDisplay/ValueDisplay.vue';
import Vue, { PropType } from 'vue';
import _ from 'lodash';

export default Vue.extend({
    components: { ValueDisplay },
    props: {
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        metricsData: {
            type: Array as PropType<ValueDisplayDatum[]>,
            default: () => []
        },
        extraMetricsData: {
            type: Array as PropType<ValueDisplayDatum[]>,
            default: () => []
        },
        greyedOutColor: {
            type: String,
            default: '#EEEEEE'
        },
        transform: {
            type: String,
            default: null
        },
        align: {
            type: String,
            default: 'left',
            validator(value: string) {
                return ['left', 'right'].includes(value);
            }
        },
        verticalAlign: {
            type: String,
            default: 'bottom',
            validator(value: string) {
                return ['top', 'bottom'].includes(value);
            }
        },
        useRows: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        extraMetricsFiltered() {
            const extraMetricsData = this.extraMetricsData as ValueDisplayDatum[];
            const metricsData = this.metricsData as ValueDisplayDatum[];
            return _.differenceBy(extraMetricsData, metricsData, 'label');
        }
    }
});
