









import Vue from 'vue';
import DhaOverlay from '@/components/Overlay/Overlay.vue';

type ComponentData = {
    error: string | null;
}
export default Vue.extend({
    components: {
        DhaOverlay
    },
    data(): ComponentData {
        return {
            error: null
        };
    },
    errorCaptured(err) {
        this.error = err.message;
    }
});
