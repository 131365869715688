import * as t from 'io-ts';

// Most efficient way to define an enum in io-ts
// TODO: Re-add national and tract as we have data + queries configured for them
export const GeoLevelType = t.keyof({
    national: null,
    state: null,
    county: null,
    tract: null
}, 'GeoLevel');

export const MetricType = t.keyof({
    nominal: null,
    percent: null,
    categorical: null
});

export const DataSourceType = t.type({
    available: t.boolean,
    queries: t.type({
        byFips: t.union([t.partial({
            all: t.string,
            state: t.string,
            county: t.string
        }), t.undefined]),
        byDate: t.union([t.string, t.undefined]),
        byGroup: t.union([t.string, t.undefined])
    })
}, 'DataSource');

export const AutotextType = t.type({
    single: t.string,
    caseDifferent: t.string,
    caseEqual: t.string,
    caseNoData: t.string
});

export const CheckboxMetricType = t.type({
    checked: t.array(t.string),
    groupTitle: t.string,
    metricId: t.string
});

const BaseMetricMetadataType = t.type({
    accordionEnabled: t.boolean,
    filterEnabled: t.boolean,
    groupingId: t.string,
    metricId: t.string,
    label: t.string,
    isTemporal: t.boolean,
    dataSources: t.record(GeoLevelType, DataSourceType),
    checkboxMetrics: t.array(CheckboxMetricType),
    color: t.string,
    secondaryColor: t.string,
    colors: t.array(t.string),
    showTestSites: t.union([t.boolean, t.undefined]),
    source: t.string,
    sourceURL: t.string,
    sourceDescription: t.string,
    textBlocks: t.tuple([
        t.string,
        t.string,
        t.string
    ]),
    sectionTitles: t.type({
        mapTitle: t.string,
        barTitle: t.string,
        lineTitle: t.string,
    }),
    autotext: AutotextType,
    units: t.string,
    groupedType: t.string
}, 'MetricMetadata');

const extentOptionsTypeBuilder = <T extends t.Type<any>>(type: T) => t.union([t.type({
    kind: t.literal('static'),
    extent: type
}), t.type({
    kind: t.literal('dynamic'),
    extentByGeoLevel: t.record(GeoLevelType, type)
})]);

const PercentMetricMetadataType = t.intersection([BaseMetricMetadataType, t.type({
    type: t.literal('percent'),
    extentOptions: extentOptionsTypeBuilder(t.tuple([t.number, t.number]))
})]);
const NominalMetricMetadataType = t.intersection([BaseMetricMetadataType, t.type({
    type: t.literal('nominal'),
    extentOptions: extentOptionsTypeBuilder(t.tuple([t.number, t.number]))
})]);
const CategoricalMetricMetadataType = t.intersection([BaseMetricMetadataType, t.type({
    type: t.literal('categorical'),
    extentOptions: t.type({
        kind: t.literal('static'),
        extent: t.array(t.string)
    }),
    colors: t.array(t.string),
    displayValues: t.array(t.string)
})]);

export const MetricMetadataType = t.union([
    PercentMetricMetadataType,
    NominalMetricMetadataType,
    CategoricalMetricMetadataType
]);

export const MetricGroupingType = t.type({
    groupingId: t.string,
    label: t.string,
    metrics: t.array(MetricMetadataType)
}, 'MetricGrouping');

export const MetadataType = t.type({
    defaults: t.union([t.partial({
        metricId: t.string,
        fipsCode: t.string,
        geoLevel: GeoLevelType,
        filterMetricId: t.string
    }), t.undefined]),
    queries: t.type({
        allMetrics: t.record(GeoLevelType, t.string),
        testLocations: t.record(GeoLevelType, t.string),
        extent: t.record(GeoLevelType, t.string)
    }),
    tooltipMetrics: t.array(t.string),
    metricsByGrouping: t.array(MetricGroupingType),
    textLinks: t.array(t.type({
        text: t.string,
        url: t.string
    })),
    splashScreenLinks: t.array(t.type({
        text: t.string,
        url: t.string
    })),
    loadingOverlayText: t.type({
        title: t.string,
        paragraph: t.string,
    })
}, 'Metadata');
