












import Vue from 'vue';
import { isEnabled } from '@dha/feature-toggles';
import ErrorPage from '@/views/ErrorPage/ErrorPage.vue';

export default Vue.extend({
    components: {
        ErrorPage
    },
    data() {
        return {
            comingSoon: isEnabled('comingSoon')
        };
    }
});
