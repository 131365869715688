















import Vue, { PropType } from 'vue';
import { TextLink } from '@/types';

export default Vue.extend({
    props: {
        links: {
            type: Array as PropType<TextLink[]>,
            default: []
        }
    }
});
