import Color from 'color';
import _ from 'lodash';
import { scaleQuantize } from 'd3-scale';
import { observable, ReactiveObject } from '@dha/vue-composition-decorators';

import { DataService } from '@/services/DataService';
import { GeojsonFeatureCollection } from '@/types';
import { GeographyModel } from '@/model/GeographyModel';
import { MapFilter } from '@/model/MapModel/Filter';

import { filterGeojsonByProps, mapGeojsonProps } from '../helpers';
import { ISpatialDataset } from './SpatialDataset';

type RawFeatureProps = {
    fips: string;
}

type FullFeatureProps = {
    fips: string;
    value: string | number | null;
    displayValue: string;
    color: string;
    outlineColor: string;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IBoundaryLayer {
    getGeojsonWithPropsInjected(
        filters: MapFilter[],
        dataset: ISpatialDataset
    ): GeojsonFeatureCollection<FullFeatureProps>;
    getFilteredGeojson(
        callback: (props: RawFeatureProps) => boolean
    ): GeojsonFeatureCollection<RawFeatureProps>;
}

/* eslint-disable class-methods-use-this, @typescript-eslint/no-empty-function */
export class NullBoundaryLayer implements IBoundaryLayer {
    readonly colorScale = scaleQuantize<string, string>();
    readonly hasValuesBelowExtent = false;
    readonly hasValuesAboveExtent = false;
    getGeojsonWithPropsInjected() {
        return this.geojson;
    }
    getFilteredGeojson() {
        return this.geojson;
    }

    private readonly geojson: GeojsonFeatureCollection<FullFeatureProps> = {
        type: 'FeatureCollection',
        features: []
    };
}

export class BoundaryLayer extends ReactiveObject implements IBoundaryLayer {
    @observable.ref geojson: GeojsonFeatureCollection<RawFeatureProps>;
    @observable.ref geography: GeographyModel;

    private constructor(
        geojson: GeojsonFeatureCollection<RawFeatureProps>,
        geography: GeographyModel
    ) {
        super();

        this.geojson = geojson;
        this.geography = geography;
    }

    getFilteredGeojson(callback: (props: RawFeatureProps) => boolean) {
        return filterGeojsonByProps(
            this.geojson,
            callback
        );
    }

    outlineColor(color: string): string {
        const colorObj = Color(color);
        return colorObj.isDark()
            ? colorObj.lighten(0.2).string()
            : colorObj.darken(0.1).string();
    }

    getGeojsonWithPropsInjected(
        filters: MapFilter[],
        dataset: ISpatialDataset
    ) {
        const geography = this.geography;

        const geojson = mapGeojsonProps(this.geojson, props => {
            const fips = props.fips;

            const excluded = _.some(
                filters,
                filter => !filter.includes(fips)
            );

            const value = dataset.data[fips];
            const displayValue = dataset.getDisplayValue(fips);
            const color = dataset.getColor(fips, excluded);
            const name = geography.getName(fips);
            const population = geography.getPopulation(fips);
            const outlineColor = excluded ? color : this.outlineColor(color);

            return {
                ...props,
                name,
                value,
                displayValue,
                color,
                outlineColor,
                population
            };
        });
        return geojson;
    }

    static async new(
        dataService: DataService,
        geography: GeographyModel
    ): Promise<BoundaryLayer> {
        const focus = geography.focus;
        const geoLevel = geography.geoLevel;
        const geojson = await dataService.getGeojson(
            geoLevel,
            {
                fipsCode: focus.fips,
                geoLevel: focus.geoLevel
            }
        );

        return new BoundaryLayer(
            geojson,
            geography
        );
    }
}
