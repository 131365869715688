import _ from 'lodash';
import { observable, ReactiveObject } from '@dha/vue-composition-decorators';

import { CategoricalMetric } from '@/model/Metric';
import { ParsedGeoLevel } from '@/services/DataService/parsers';
import { DataService } from '@/services/DataService';
import { GeographyModel } from '@/model/GeographyModel';
import { Focus } from '@/model/GeographyModel/Geography';

import { BaseFilter } from './BaseFilter';

export class CategoricalFilter extends ReactiveObject implements BaseFilter {
    type = 'categorical' as const;
    private metric: CategoricalMetric;
    categories: string[];
    displayValues: string[];

    @observable.ref selectedCategories: string[];
    @observable.ref data: Record<string, string | null>;

    private constructor(
        metric: CategoricalMetric,
        data: Record<string, string | null>,
        initialValues?: string[]
    ) {
        super();

        this.metric = metric;
        this.data = data;
        this.categories = metric.getDomain();
        this.selectedCategories = this.categories;
        this.displayValues = metric.metadata.displayValues;

        if (initialValues) {
            this.setValues(initialValues);
        }
    }

    get metricId() {
        return this.metric.metadata.metricId;
    }

    get values(): string[] {
        return this.selectedCategories;
    }

    isGeoLevelAvailable(geoLevel: ParsedGeoLevel): boolean {
        return this.metric.isGeoLevelAvailable(geoLevel);
    }
    includes(fipsCode: string) {
        const value = this.data[fipsCode];
        if (_.isNil(value)) {
            return this.displayValues.length === this.selectedCategories.length;
        }
        return this.selectedCategories.includes(value.toString());
    }

    setValues(values: string[]) {
        this.selectedCategories = values.slice();
    }
    async updateData(dataService: DataService, geoLevel: ParsedGeoLevel, focus: Focus) {
        this.data = await CategoricalFilter.fetchData(
            dataService,
            this.metricId,
            geoLevel,
            focus
        );
    }

    static async new(
        dataService: DataService,
        geography: GeographyModel,
        metric: CategoricalMetric,
        initialValues?: string[]
    ): Promise<CategoricalFilter> {
        const geoLevel = geography.geoLevel;
        const focus = geography.focus;
        const data = await CategoricalFilter.fetchData(
            dataService,
            metric.metadata.metricId,
            geoLevel,
            focus
        );

        return new CategoricalFilter(
            metric,
            data,
            initialValues
        );
    }

    private static async fetchData(
        dataService: DataService,
        metricId: string,
        geoLevel: ParsedGeoLevel,
        focus: Focus
    ) {
        const [
            data,
            focusData
        ] = await Promise.all([
            dataService.getLatestMetricData(
                metricId,
                {
                    geoLevel,
                    fipsCode: focus.fips,
                    filterGeoLevel: focus.geoLevel
                }
            ),
            dataService.getAllMetricsValues(
                focus.fips,
                focus.geoLevel
            )
        ]);

        return {
            ..._(data)
                .keyBy('fips')
                .mapValues('value')
                .value(),
            [focus.fips]: focusData[metricId]
        } as Record<string, string | null>; // TODO: Figure out how to type this
    }

    isFiltered(): boolean {
        return !_.isEqual(this.selectedCategories, this.categories);
    }

    clear() {
        this.selectedCategories = this.categories.slice();
    }
}
