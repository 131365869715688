





























































































import _ from 'lodash';
import Vue, { PropType } from 'vue';
import '@/assets/tailwind.css';
import { Option } from '@/types';

export default Vue.extend({
    props: {
        options: {
            type: Array as PropType<Option[]>,
            default: () => [],
        },
        value: {
            type: String as PropType<string | null>,
            default: null
        },
        darkScheme: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectedValue: this.value,
        };
    },
    computed: {
        groupedOptions() {
            return _(this.options)
                .filter(option => option.group !== undefined)
                .groupBy(o => o.group)
                .value();
        },
        defaultGroupOptions() {
            return _.filter(this.options, o => o.group === undefined);
        },
        isGrouped() {
            return _.some(this.options, o => o.group !== undefined);
        }
    },
    methods: {
        onChooseOption(event: InputEvent) {
            const target = event.target as HTMLSelectElement | null;
            const value = target?.value;
            // If the input value is undefined, this will emit '' instead
            // Coerce it back to undefined so the user of this component
            // doesn't have to think about that
            this.$emit('selected', value === '' ? undefined : value);
        }
    }
});
