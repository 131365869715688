





























import Vue, { PropType } from 'vue';
import Checkbox from '@/components/Checkbox/Checkbox.vue';

type Checkbox = {
    id: string;
    label: string;
    checked: boolean;
    disabled: boolean;
}

export default Vue.extend({
    name: 'CheckboxGroup',
    components: {
        Checkbox
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        checkboxes: {
            type: Array as PropType<Checkbox[]>,
            default: () => []
        },
        stack: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onBoxClick(value: string) {
            this.$emit('checkbox', value);
        }
    }
});
