






















import Vue from 'vue';
import ResizeObserver from 'resize-observer-polyfill';
import { axisLeft } from 'd3-axis';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import { BaseType, select, Selection } from 'd3-selection';
import 'd3-transition';
import _ from 'lodash';

import { VueWithTypedRefs, BarChartDatum, BarChartDrawInputs } from '@/types';
import { getXScale, getYScale } from './helpers';
import { getTailwindColor } from '../getTailwindStyle';

const TRANSITION_DURATION = 1000;

export default (Vue as VueWithTypedRefs<{
    svg: typeof SVGElement;
    yAxis: typeof SVGGElement;
    bar: typeof SVGGElement;
    label: typeof SVGGElement;
}>).extend({
    props: {
        data: {
            type: Array as () => BarChartDatum[],
            default: () => []
        },
        leftMargin: {
            type: Number,
            default: 100
        },
        yMargin: {
            type: Number,
            default: 40
        },
        rightMargin: {
            type: Number,
            default: 100
        },
        color: {
            type: String,
            default: '#FB9494'
        },
        labelColor: {
            type: String,
            default: '#E64E51'
        },
        formatLabel: {
            type: Function,
            default: (d) => (d.value !== null ? `${d.value}` : 'Data Unavailable')
        },
        height: {
            type: Number,
            default: 220
        }
    },
    data() {
        return {
            width: 0,
            resizeObserver: null as ResizeObserver | null,
            currentSign: 'positive'
        };
    },
    computed: {
        yScale(): ScaleBand<string> | null {
            if (!this.data) {
                return null;
            }
            return getYScale({
                data: this.data,
                height: this.height,
                yMargin: this.yMargin
            });
        },
        drawInputs(): BarChartDrawInputs {
            return {
                data: this.data,
                yScale: this.yScale,
                width: this.width,
                leftMargin: this.leftMargin,
                rightMargin: this.rightMargin
            };
        }
    },
    watch: {
        async drawInputs() {
            await this.$nextTick();
            this.draw();
        }
    },
    mounted() {
        this.resizeObserver = new ResizeObserver(() => {
            this.updateSize();
        });

        this.resizeObserver.observe(this.$el);
        this.updateSize();
    },
    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
    methods: {
        draw() {
            select(this.$refs.svg)
                .attr('width', this.width)
                .attr('height', this.height);
            this.drawYAxis();

            const max = _.maxBy(this.data, d => d.value || 0);
            const min = _.minBy(this.data, d => d.value || 0);
            if (!max) {
                throw new Error('Bar Chart cannot find max value in data');
            }
            if (!min) {
                throw new Error('Bar Chart cannot find min value in data');
            }
            const minValue = min.value || 0;
            const maxValue = max.value || 0;
            const currentSign = this.dataSign(minValue, maxValue);
            const xScale = this.$refs.yAxis
                ? getXScale({
                    width: this.width,
                    labelMargin: this.$refs.yAxis.getBBox().width,
                    leftMargin: this.leftMargin,
                    rightMargin: this.rightMargin,
                    dataMax: maxValue,
                    dataMin: minValue
                })
                : getXScale({
                    width: this.width,
                    labelMargin: 0,
                    leftMargin: this.leftMargin,
                    rightMargin: this.rightMargin,
                    dataMax: maxValue,
                    dataMin: minValue
                });
            const yScale = this.yScale;
            if (yScale && xScale) {
                this.drawBars(xScale, yScale, minValue, maxValue, currentSign);
                this.drawLabels(xScale, yScale, minValue, maxValue, currentSign);
                this.currentSign = currentSign;
            }
        },
        drawYAxis() {
            if (this.yScale) {
                const yAxis = axisLeft(this.yScale)
                    .tickSize(0);
                select(this.$refs.yAxis)
                    .call(yAxis)
                    .style('text-anchor', 'start');
            }
        },
        drawBars(
            xScale: ScaleLinear<number, number>,
            yScale: ScaleBand<string>,
            minValue: number,
            maxValue: number,
            currentSign: string
        ) {
            const axisOffset = this.leftMargin + this.$refs.yAxis.getBBox().width;
            const bars = select(this.$refs.bar)
                .selectAll('rect')
                .data(this.data)
                .join(
                    enter => enter.append('rect')
                        .attr('fill', this.color),
                    update => update
                )
                .style('transform', `translateX(${this.$refs.yAxis.getBBox().width}px)`)
                .attr('y', d => {
                    const y = yScale(d.grouping);
                    if (y === undefined) {
                        console.warn('Bar Chart Bar has undefined y scale value for', d.grouping);
                        return 0;
                    }
                    return y;
                })
                .attr('height', yScale.bandwidth());

            if (currentSign !== this.currentSign) {
                bars.attr('x', xScale(0) - axisOffset)
                    .attr('width', 0)
                    .attr('fill', this.color);
            }

            bars.transition()
                .duration(TRANSITION_DURATION)
                .attr('x', (d) => (minValue >= 0
                    ? 0
                    : Math.min(xScale(d.value || 0), xScale(0)) - axisOffset))
                .attr('width', (d) => (minValue >= 0
                    ? xScale(d.value || 0) - axisOffset
                    : Math.abs(xScale(0) - xScale(d.value || 0))))
                .attr('fill', this.color);

            select(this.$refs.svg)
                .selectAll('line.zero-line')
                .data([1])
                .join('line')
                .classed('zero-line', true)
                .attr('x1', xScale(0))
                .attr('y1', yScale.range()[0])
                .attr('x2', xScale(0))
                .attr('y2', yScale.range()[1])
                .attr('stroke-width', this.oneSided(minValue, maxValue) ? 0 : 1);
        },
        drawLabels(
            xScale: ScaleLinear<number, number>,
            yScale: ScaleBand<string>,
            minValue: number,
            maxValue: number,
            currentSign: string
        ) {
            const axisOffset = this.leftMargin + this.$refs.yAxis.getBBox().width;
            const labels = select(this.$refs.label)
                .style('transform', `translateX(${axisOffset}px)`)
                .selectAll('text')
                .data(this.data)
                .join(
                    enter => enter.append('text')
                        .attr('opacity', 0)
                        .attr('fill', d => (!_.isNil(d.value)
                            ? this.labelColor
                            : getTailwindColor('gray', 400))),
                    update => update
                )
                .attr('y', d => {
                    const y = yScale(d.grouping);
                    if (y === undefined) {
                        console.warn('BarChart yScale undefined for grouping', d.grouping);
                        return 0;
                    }
                    return y + yScale.bandwidth() * 1.35;
                })
                .attr('text-anchor', d => this.textAnchor(d.value, minValue, maxValue))
                .attr('font-weight', d => (!_.isNil(d.value)
                    ? '700'
                    : '400'))
                .text(d => (!_.isNil(d.value)
                    ? this.formatLabel(d.value)
                    : d.unavailableText ?? 'Data Unavailable'));

            const resetLabel = (label: Selection<BaseType | SVGTextElement, BarChartDatum, SVGGElement, unknown>) => {
                label.attr('x', this.textX(0, xScale, minValue, maxValue))
                    .attr('fill', d => (!_.isNil(d.value)
                        ? this.labelColor
                        : getTailwindColor('gray', 400)))
                    .attr('opacity', 0);
            };

            if (currentSign !== this.currentSign) {
                labels.call(resetLabel);
            }

            labels.filter((d, i, nodes) => _.isNil(d.value) && select(nodes[i]).attr('data-null') !== 'true')
                .call(resetLabel);

            labels
                .attr('data-null', d => (_.isNil(d.value) ? 'true' : null))
                .transition()
                .duration(TRANSITION_DURATION)
                .delay(d => (_.isNil(d.value) ? TRANSITION_DURATION : 0))
                .attr('x', d => this.textX(d.value, xScale, minValue, maxValue))
                .attr('fill', d => (!_.isNil(d.value)
                    ? this.labelColor
                    : getTailwindColor('gray', 400)))
                .attr('opacity', 1);
        },
        dataSign(min: number, max: number) {
            if (Math.sign(min) === 0) return 'positive';
            if (Math.sign(max) === 0) return 'negative';
            if (Math.sign(min) === Math.sign(max)) {
                return Math.sign(min) > 0 ? 'positive' : 'negative';
            }
            return 'both';
        },
        oneSided(min: number, max: number) {
            return Math.sign(min) === 0
                || Math.sign(max) === 0
                || Math.sign(min) === Math.sign(max);
        },
        textAnchor(value: number | null, min: number, max: number) {
            const biasEnd = Math.abs(min) > Math.abs(max);

            return biasEnd && (_.isNil(value) || value < 0) ? 'end' : 'start';
        },
        textX(value: number | null, xScale: ScaleLinear<number, number>, min: number, max: number) {
            const biasEnd = Math.abs(min) > Math.abs(max);
            const xNoOffset = xScale(value || 0) - this.$refs.yAxis.getBBox().width - this.leftMargin;

            if (!value) {
                // null or zero values stay at x=0 as long as all values are the same sign
                if (this.oneSided(min, max)) {
                    return xNoOffset;
                }
                return xNoOffset + (biasEnd ? -15 : 15);
            }
            return xNoOffset + (value < 0 ? -15 : 15);
        },
        updateSize() {
            const { width } = this.$el.getBoundingClientRect();
            if (this.width !== width) {
                this.width = width;
            }
        },
    }
});
