import _ from 'lodash';
import { extent } from 'd3-array';
import { scaleLinear, scaleUtc } from 'd3-scale';
import { BaseChartDatum } from '@/types';

let count = 0;

// uid adapted from the ObservableHQ stlib:
// https://github.com/observablehq/stdlib/blob/master/src/dom/uid.js
class Id {
    id: string;
    svgUrl: string;
    href: string;

    constructor(id: string) {
        this.id = id;
        this.svgUrl = `url(#${id})`;
        this.href = `${new URL(`#${id}`, window.location.toString())}`;
    }
}

export function uid(name: string) {
    return new Id(`O-${name == null ? '' : `${name}-`}${++count}`);
}

export const getXScale = (data: BaseChartDatum<number | null>[], width: number, leftMargin: number, rightMargin: number) => {
    if (data.length < 2) {
        return null;
    }
    const domain = extent(data.map(d => d.cdate));
    if (_.reject(domain, _.isNil).length < 2) return null;
    return scaleUtc()
        .domain(domain as [Date, Date])
        .range([leftMargin, width - rightMargin]);
};

export const getYScale = (data: BaseChartDatum<number | null>[], height: number, topMargin: number, bottomMargin: number) => {
    if (data.length < 2) {
        return null;
    }
    const domain = extent(data.map(d => d.value || 0));
    if (_.reject(domain, _.isNil).length < 2) return null;
    return scaleLinear()
        .domain(domain as [number, number])
        .range([height - bottomMargin, topMargin])
        .nice();
};
