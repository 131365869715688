


























import { ActionLink, TextLink } from '@/types';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    props: {
        links: {
            type: Array as PropType<Array<TextLink | ActionLink>>,
            default: () => []
        }
    }
});
