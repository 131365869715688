import _ from 'lodash';

/**
 * Interleave two arrays, returning a single array whose elements are
 * from the two provided arrays in alternating order
 *
 * Example: interleaveArrays([1, 2], [100, 200]) => [1, 100, 2, 200]
 *
 * @param arr1 The first array. Its first element will always be first
 * @param arr2 The second array
 */
export function interleaveArrays<T>(arr1: T[], arr2: T[]): T[] {
    let i = 0;
    const result: T[] = [];
    while (i < arr1.length || i < arr2.length) {
        if (i < arr1.length) {
            result.push(arr1[i]);
        }
        if (i < arr2.length) {
            result.push(arr2[i]);
        }
        ++i;
    }
    return result;
}

const lowercaseTokens = ['of', 'the', 'and'];

/**
 * Proper-cases a string, capitalizing all words except ones like
 * "of" or "the" (hardly a comprehensive list, currently)
 *
 * @param name A string to be proper-cased
 */
export function properCase(name: string): string {
    // Declare regexes inside the function call because
    // they are stateful, so re.test can return true or false
    // on the same string depending on previous calls
    const wordRe = /\w+/g;
    const nonWordRe = /\W+/g;
    const startsWithWordRe = /^\w+/g;

    const words = name
        .split(nonWordRe)
        .map(token => (lowercaseTokens.includes(_.toLower(token))
            ? _.toLower(token)
            : _.upperFirst(_.toLower(token))));
    const nonWordTokens = name.split(wordRe);

    // If the string is 'foo bar', words will be ['Foo', 'Bar']
    // and nonWordTokens will be ['', ' ', '']. So counterintuitively,
    // if the string starts with a word, we interleave the non-word
    // tokens first
    if (startsWithWordRe.test(name)) {
        return interleaveArrays(nonWordTokens, words).join('');
    }
    return interleaveArrays(words, nonWordTokens).join('');
}
