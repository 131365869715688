




































































































import Vue, { PropType } from 'vue';
// @ts-ignore
import vClickOutside from 'v-click-outside';
import { SocialDialogState } from '@/types';
import { SocialShareService } from '@/services/SocialShareService';
import DhaButton from '@/components/Button/Button.vue';
import DhaToast from '@/components/Toast/Toast.vue';
import { paths } from './constants';

export default Vue.extend({
    directives: {
        clickOutside: vClickOutside.directive
    },
    components: {
        DhaButton,
        DhaToast,
    },
    props: {
        socialShareService: {
            type: Object as PropType<SocialShareService>,
            required: true
        },
        socialDialogState: {
            type: String as PropType<SocialDialogState>,
            required: true,
        }
    },
    data() {
        return {
            paths,
            showClipboardToast: false,
            exiting: false,
        };
    },
    computed: {
        title(): string {
            return this.socialShareService.socialDialogTitle(this.socialDialogState);
        },
        buttonLabel(): string {
            return (this.socialDialogState === 'link') ? 'Copy' : 'Share';
        },
        shortLink(): string | null {
            return this.socialShareService.shortLink(this.socialDialogState);
        },
        deepLink(): string | null {
            return this.socialShareService.deepLink(this.socialDialogState);
        }
    },
    methods: {
        onLinkButtonClicked() {
            if (this.exiting) return;
            if (this.socialDialogState === 'link' && this.socialShareService.copyShortLink()) {
                this.showToast();
            } else {
                this.$emit('close');
            }
        },
        onDeepLinkButtonClicked() {
            if (this.exiting) return;
            if (this.socialDialogState === 'link' && this.socialShareService.copyDeepLink()) {
                this.showToast();
            } else {
                this.$emit('close');
            }
        },
        showToast() {
            this.exiting = true;
            this.showClipboardToast = true;
            setTimeout(() => this.$emit('close'), 1500);
        },
        onCloseClicked() {
            this.$emit('close');
        },
        onClickedOutside(event: MouseEvent) {
            if (event.target) {
                const element = event.target as HTMLElement;
                if (!element.classList.contains('share-button')) {
                    this.$emit('close');
                }
            } else {
                this.$emit('close');
            }
        }
    }
});
