










































import Vue, { PropType } from 'vue';
import DhaRangeSlider from '@/components/RangeSlider/RangeSlider.vue';
import DhaSelect from '@/components/Select/Select.vue';
import DhaCategoricalFilter from '@/components/CategoryFilter/CategoryFilter.vue';
import '@/assets/tailwind.css';
import { MapFilter } from '@/model/MapModel/Filter';
import { GeoLevelMeta } from '@/services/DataService';

export default Vue.extend({
    components: {
        DhaSelect,
        DhaRangeSlider,
        DhaCategoricalFilter
    },
    props: {
        metricOptions: {
            type: Array,
            default: () => [],
        },
        filter: {
            type: Object as PropType<MapFilter>,
            required: true,
        },
        selectedRegions: {
            type: Array as PropType<GeoLevelMeta[]>,
            required: true
        }
    },
    methods: {
        onMetricSelected(option: string) {
            this.$emit('selected', option);
        },
        onChange(value: [number, number] | string[]) {
            this.$emit('change', value);
        }
    }
});
