






















































































import { ThresholdLegendValue } from '@/model/Metric/Metric';
import Vue from 'vue';

export default Vue.extend({
    props: {
        title: {
            type: String,
            default: null
        },
        values: {
            type: Array as () => ThresholdLegendValue[],
            required: true
        },
        showSymbol: {
            type: Object,
            required: true,
        },
    }
});
