import _ from 'lodash';
import { ParsedMetricByFipsData, ParsedMetricByDateData, ParsedGroupedMetricData, ParsedMetricMetadata, ParsedGeoLevel, parseFilterValues } from '@/services/DataService/parsers';
import { ExtentValue, Option } from '@/types';

type MetricData = ParsedMetricByDateData | ParsedMetricByFipsData | ParsedGroupedMetricData;

type NumericalMetricData<T extends MetricData> = Omit<T, 'value'> & {value: number | null};
type StringMetricData<T extends MetricData> = Omit<T, 'value'> & {value: string};

type CategoricalMetricMetadata = ParsedMetricMetadata & { type: 'categorical' };

export function getInitialFilters(filterString: string) {
    try {
        const initialFiltersJSON = JSON.parse(filterString);
        return parseFilterValues(initialFiltersJSON);
    } catch (e) {
        console.warn(`Error getting initial filters: ${e.message}`);
        return null;
    }
}

export function numericalMetricData<T extends MetricData>(data: T[]): NumericalMetricData<T>[] {
    return data
        .map(datum => ({
            ...datum,
            value: (datum.value === null || _.isNaN(Number(datum.value)))
                ? null
                : Number(datum.value)
        }))
        .filter(datum => datum.value !== null);
}

export function categoricalMetricData<T extends MetricData>(data: T[]): StringMetricData<T>[] {
    return data.map(datum => ({
        ...datum,
        value: String(datum.value)
    }));
}

export function extentByValue(metadata: CategoricalMetricMetadata): Record<string, ExtentValue> {
    return _(metadata.extentOptions.extent)
        .map((value, i) => ({
            value,
            label: metadata.displayValues[i],
            color: metadata.colors[i]
        }))
        .keyBy('value')
        .value();
}

export function getNewGeoLevelIfDisabled(geoLevelOptions: Option<ParsedGeoLevel>[], geoLevel: ParsedGeoLevel): ParsedGeoLevel | null {
    const geoOption = geoLevelOptions.find(option => option.value === geoLevel);
    if (geoOption?.disabled) {
        return _.findLast(geoLevelOptions, option => !option.disabled)?.value ?? 'state';
    }
    return null;
}
