import * as t from 'io-ts';

export const GeoLevelMetaType = t.type({
    fips: t.string,
    stateFIPS: t.string,
    countyFIPS: t.union([t.string, t.undefined]),
    name: t.string,
    population: t.union([t.number, t.undefined])
}, 'GeoLevelMeta');

export const GeoLevelMetaArrayType = t.array(GeoLevelMetaType);
