















import { select } from 'd3-selection';
import Vue from 'vue';

export default Vue.extend({
    props: {
        value: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            zIndexTimer: null as number | null
        };
    },
    watch: {
        value(newValue: boolean) {
            if (this.zIndexTimer !== null) {
                clearTimeout(this.zIndexTimer);
            }
            this.zIndexTimer = setTimeout(() => {
                this.setZIndex(newValue);
            }, 250);
        }
    },
    mounted() {
        this.setZIndex(this.value);
    },
    methods: {
        // Safari seems to have a bug where it forgets to respect pointer-events: none
        // until an element is interacted with once. Resetting the z-index and using a
        // normal cursor should be good enough to simulate correct behaviour
        setZIndex(active: boolean) {
            if (this.$refs.overlay) {
                select(this.$refs.overlay as HTMLDivElement)
                    .style('z-index', active ? '50' : '0')
                    .style('cursor', active ? 'inherit' : 'default');
            }
        }
    }
});
