import { render, staticRenderFns } from "./MapGeographyControls.vue?vue&type=template&id=2d949896&scoped=true&"
import script from "./MapGeographyControls.vue?vue&type=script&lang=ts&"
export * from "./MapGeographyControls.vue?vue&type=script&lang=ts&"
import style0 from "./MapGeographyControls.vue?vue&type=style&index=0&id=2d949896&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d949896",
  null
  
)

export default component.exports