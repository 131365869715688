import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';

export const MetricByFipsDataType = t.type({
    fips: t.string,
    value: t.union([t.string, t.number, t.null])
}, 'MetricByFipsData');

export const MetricByDateDataType = t.type({
    cdate: DateFromISOString,
    value: t.union([t.string, t.number, t.null])
}, 'MetricByDateData');

export const GroupedMetricDataType = t.type({
    grouping: t.string,
    value: t.union([t.string, t.number, t.null]),
    hasQuintile: t.union([t.string, t.null, t.undefined])
}, 'GroupedMetricData');

export const AllMetricsDataType = t.type({
    metric: t.string,
    value: t.union([t.string, t.number, t.null])
}, 'AllMetricsData');

export const MetricByFipsDataArrayType = t.array(MetricByFipsDataType);
export const MetricByDateDataArrayType = t.array(MetricByDateDataType);
export const AllMetricsDataArrayType = t.array(AllMetricsDataType);
export const GroupedMetricDataArrayType = t.array(GroupedMetricDataType);
